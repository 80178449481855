import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// Check the localstorage before i18next can
const storedLang = localStorage.getItem('i18nextLng');

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          //**LANDING**
          //HEADER
          'home': "home",
          'news': "news",
          'services': "services",
          'location': "location",
          'contact': "contact",
          'about us': "about us",
          'career': "career",
          'contact us': "contact us",
          'know more': 'know more',
          'call us' : 'call us',
          'email us' : 'email us',
          'title' : 'title',
          'description' : 'description',
          'search here' : 'search here',
          'our location' : 'our location',
          'news release' : 'news release',
          'our groups' : 'our groups',
          'gallery' : 'gallery',
          'our latest project' : 'our latest project',
          'providing smart logistic solution across the world' : 'providing smart logistic solution across the world',
          'quick links' : 'quick links',
          //ABOUT
          'company history' : 'company history',
          'company overview' : 'company overview',
          'organization chart' : 'organization chart',
          'subscribe to our corporate newsletter' : 'subscribe to our corporate newsletter',
          'enter your email' : 'enter your email',
          'subscribe' : 'subscribe',
          'company name' : 'company name',
          'business' : 'business',
          'head office' : 'head office',
          'president' : 'president',
          'establishment' : 'establishment',
          'operating base' : 'operating base',
          'isoGdp' : 'ISO/GDP',
          'shareholders' : 'Shareholders',
          'numOfEmployee' : 'Number of Employee',
          'about.about us' : 'about.about us',
          //SERVICES
          'pt.ilc logistics indonesia services overview' : 'pt.ilc logistics indonesia services overview',
          //NEWS
          'categories' : 'categories',
          'recent news' : 'recent news',
          'enter search keywords' : 'enter search keywords',
          //CONTACT
          'contact form' : 'contact form',
          'contact details' : 'contact details',
          'address' : 'address',
          'email' : 'email',
          'phone number' : 'phone number',
          'phone' : 'phone',
          'your name' : 'your name',
          'subject' : 'subject',
          'message' : 'message',
          'send message' : 'send message',
          //CAREER
          'quality requirments' : 'quality requirments',
          'job requirements' : 'job requirements',
          'career.description-line-1' : 'PT ILC Logistics Indonesia is a multinational company engaged in warehouse services located in the Surya Cipta industrial area, Karawang which is currently growing rapidly.',
          'career.description-line-2' : 'Currently PT ILC Logistics is opening job vacancies to find talented individuals to join together to build the company to realize the mission of the company, which is to become the leading and largest in the Warehouse service sector.',
        },
      },
      id: {
        translation: {
          //**LANDING**
          //HEADER
          'home': "beranda",
          'news': "berita",
          'services': "layanan",
          'location': "lokasi",
          'contact': "kontak",
          'about us': "tentang kami",
          'career': "karir",
          'contact us': "kontak kami",
          'know more': 'lainya',
          'call us' : 'hubungi kami',
          'email us' : 'email kami',
          'title' : 'judul',
          'description' : 'deskripsi',
          'search here' : 'cari',
          'our location' : 'lokasi kami',
          'news release' : 'berita terbaru',
          'our groups' : 'grup kami',
          'gallery' : 'galeri',
          'our latest project' : 'projek kami',
          'providing smart logistic solution across the world' : 'providing smart logistic solution across the world',
          'quick links' : 'pintasan',
          //ABOUT
          'company history' : 'riwayat perusahaan',
          'company overview' : 'tentang perusahaan',
          'organization chart' : 'bagan organisasi',
          'subscribe to our corporate newsletter' : 'berlangganan buletin perusahaan kami',
          'enter your email' : 'alamat email anda',
          'subscribe' : 'berlangganan',
          'company name' : 'nama perusahaan',
          'business' : 'bisnis',
          'head office' : 'kantor utama',
          'president' : 'presiden',
          'establishment' : 'berdiri sejak',
          'operating base' : 'tempat beroperasi',
          'shareholders' : 'Pemilik Saham',
          'numOfEmployee' : 'Jumlah Karyawan',
          'isoGdp' : 'ISO/GDP',
          'about.about us' : 'tentang kami',
          //SERVICES
          'pt.ilc logistics indonesia services overview' : 'pt.ilc logistics indonesia services overview',
          //NEWS
          'categories' : 'kategori',
          'recent news' : 'berita terkini',
          'enter search keywords' : 'pencarian',
          //CONTACT
          'contact form' : 'form kontak',
          'contact details' : 'detail kontak',
          'address' : 'alamat',
          'email' : 'email',
          'phone number' : 'nomor telepon',
          'phone' : 'telepon',
          'your name' : 'nama',
          'subject' : 'subjek',
          'message' : 'pesan',
          'send message' : 'kirim pesan',
          //CAREER
          'quality requirments' : 'persyaratan kualitas',
          'job requirements' : 'persyaratan kerja',
          'career.description-line-1' : 'PT ILC Logistics Indonesia perusahaan multinasional yang bergerak di bidang warehouse service berlokasi di kawasan industri Surya Cipta, Karawang yang saat ini sedang berkembang pesat.',
          'career.description-line-2' : 'Saat ini PT ILC Logistic sedang membuka lowongan pekerjaan untuk mencari individu-individu berbakat untuk bergabung bersama membangun perusahaan untuk mewujudkan misi dari perusahaan, yaitu menjadi yang terdepan dan terbesar dibidang Warehouse service.',
        },
      },
      jp: {
        translation: {
          //**LANDING**
          //HEADER
          'home': "ホーム",
          'news': "ニュース",
          'services': "サービス",
          'location': "拠点一覧",
          'contact': "連絡",
          'about us': "会社概要",
          'career': "採用情報",
          'contact us': "お問合せ",
          'know more': 'もっと知る',
          'call us' : 'お電話',
          'email us' : 'メール',
          'title' : '題名',
          'description' : '説明',
          'search here' : '検索',
          'our location' : '拠点一覧',
          'news release' : '新着情報',
          'our groups' : 'グループ一覧',
          'gallery' : 'ギャラリー',
          'our latest project' : '最新のプロジェクト',
          'providing smart logistic solution across the world' : 'スマートな物流ソリューションを世界へ',
          'quick links' : 'サイトマップ',
          //ABOUT
          'company history' : '沿革',
          'company overview' : '会社概要',
          'organization chart' : '組織図',
          'subscribe to our corporate newsletter' : 'ニュースを購読する',
          'enter your email' : 'メールアドレスを入力',
          'subscribe' : '登録',
          'company name' : '会社名',
          'business' : '取扱事業',
          'head office' : '本社所在地',
          'president' : '社長',
          'establishment' : '設立',
          'operating base' : '活動拠点',
          'isoGdp' : 'ISO/GDP',
          'shareholders' : '株主',
          'numOfEmployee' : '従業員数',
          'about.about us' : '会社プロフィール',
          //SERVICES
          'pt.ilc logistics indonesia services overview' : 'pt.ilc logistics indonesiaのサービス概要',
          //NEWS
          'categories' : 'カテゴリー',
          'recent news' : '最新ニュース',
          'enter search keywords' : '検索ワードを入力',
          //CONTACT
          'contact form' : 'お問合せフォーム',
          'contact details' : 'お問合せ先詳細',
          'address' : '住所',
          'email' : 'メールアドレス',
          'phone number' : '電話番号',
          'phone' : '電話番号',
          'your name' : '名前',
          'subject' : '題名',
          'message' : 'お問合せ内容詳細',
          'send message' : '送信',
          //CAREER
          'quality requirments' : '採用に関して',
          'job requirements' : '募集要項',
          'career.description-line-1' : 'PT.ILC Logistics Indonesiaは、現在急成長を遂げているカラワンのスルヤチプタ工業地帯に位置する、３PLサービスを提供する多国籍企業です。',
          'career.description-line-2' : '現在、PT.ILC Logistics Indonesiaは、倉庫サービス分野におけるリーディングカンパニーとなることを使命とし、その実現に向けて会社を一緒に作り上げてくれる優秀な人材を募集しています。',
        },
      },
    },
    // lng: defaultLanguage,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    storedLang
  });
export default i18n;
