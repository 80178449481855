import { CAREER } from "../../../services";
import { CAREER_DATA_STATE } from "../../globalActionType";

export const getCareer = (params) => {
  function request() { return { type: CAREER_DATA_STATE.CAREER_DATA } }
  function success(data) { return { type: CAREER_DATA_STATE.CAREER_DATA_SUCCESS, data } }
  function failure(data) { return { type: CAREER_DATA_STATE.CAREER_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await CAREER.getCareer(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getListCareer= (params) => {
  function request() { return { type: CAREER_DATA_STATE.CAREER_DATA } }
  function success(data) { return { type: CAREER_DATA_STATE.CAREER_DATA_SUCCESS, data } }
  function failure(data) { return { type: CAREER_DATA_STATE.CAREER_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await CAREER.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getDetailCareer = (params) => {
  function request() { return { type: CAREER_DATA_STATE.CAREER_DETAIL_DATA } }
  function success(data) { return { type: CAREER_DATA_STATE.CAREER_DETAIL_DATA_SUCCESS, data } }
  function failure(data) { return { type: CAREER_DATA_STATE.CAREER_DETAIL_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await CAREER.getDetail(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const resetCareer = () => (dispatch) => {
  dispatch({
    type: CAREER_DATA_STATE.CAREER_DATA_RESET
  })
}

export const resetCareerDetail = () => (dispatch) => {
  dispatch({
    type: CAREER_DATA_STATE.CAREER_DETAIL_DATA_RESET
  })
}