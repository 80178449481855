import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import Input from '../../components/atoms/Input'
import Label from '../../components/atoms/Label'
import Loading from '../../components/atoms/Loading'
import Select from '../../components/atoms/Select'
import { baseUrl } from '../../helpers/baseUrl'
import { sendMailToken } from '../../redux/admin/register/action'
import { getRole } from '../../redux/admin/role/action'
import { createUsers, updateUsers } from '../../redux/admin/users/create/action'

const FormUsers = ({
  row,
  onClose,
  onSubmit
}) => {

  const dispatch = useDispatch()
  const role = useSelector(state => state.role)
  const submit = useSelector(state => state.usersSubmit)
  const { isLoadingSendMail } = useSelector(state => state.register);

  const [inputs, setInputs] = useState({
    email: row?.email ?? '',
    role: row?.employee?.idRole ?? null,
  })

  const params = {
    id: row?.id,
    data: inputs
  }

  const paramsEmail = {
    url: baseUrl("/validate/confirm-registration/"),
    email: inputs.email
  }

  useEffect(() => {
    dispatch(getRole(params))
  }, []);  //eslint-disable-line

  useEffect(() => {
    if (submit.isSuccess && (row?.email !== inputs?.email)) {
      dispatch(sendMailToken(paramsEmail))
    }
  }, [submit.isSuccess,row?.email,inputs?.email]); //eslint-disable-line

  let roleList = role?.data?.data ? _.map(role?.data?.data, (value, key) => {
    return { value: value?.id, label: value?.name }
  }) : [];

  const handleChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value
    })
  }

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    if (row?.id) { //edit
      dispatch(updateUsers(params))
    } else {//create
      dispatch(createUsers(inputs))
    }
    onSubmit()
  }

  return !role.isSuccess ? <Loading/> : (
    <form className="w-full max-w-sm mx-auto space-y-6">
      <div className="form-group">
        <Label className="md:w-1/3">Email</Label>
        <Input
          className="w-full md:w-2/3 form-box"
          type="email"
          name="email"
          onChange={(event) => handleChange(event)}
          value={inputs.email}
        />
      </div>
      <div className="form-group">
        <Label className="md:w-1/3">Role</Label>
        <Select
          className="w-full md:w-2/3"
          onChange={(event) => handleChange(event)}
          name="role"
          option={roleList}
          value={inputs?.role ?? 'default'}
          placeholder="Select"
        />
      </div>
      {submit.isLoading || isLoadingSendMail ? <Loading /> : null}
      <div className='text-right pt-5'>
        <Button type={"button"}
          buttonStyle={"btn-primary text-sm p-1 mr-4"}
          onClick={() => handleSubmit()}
          disabled={(submit.isLoading && isLoadingSendMail) || !inputs?.role}
        >
          Submit
        </Button>
        <Button type={"button"}
          buttonStyle={"btn-secondary text-sm p-1"}
          onClick={() => handleClose()}
          disabled={submit.isLoading && isLoadingSendMail}
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}

export default FormUsers
