import _ from 'lodash';

export const remappingFilter = (setFilter, array, value, key, operator) => {

  const filterValue = value ? `${key}~${operator}~${value}` : ""
  const index = _.findIndex(array, val => val.includes(key))
  if (index === -1) {
    // console.log(key,':',index,array)
    if (value) {
      setFilter(
        filterValue ? [...array, filterValue] : [...array]
      )
    }
  }
  else {
    if (filterValue) {
      setFilter((prevState) => {
        let temp = [...prevState];
        temp[index] = filterValue;
        return temp;
      });
    } else {
      setFilter((prevState) => {
        let temp = [...prevState]
        temp.splice(index, 1)
        return temp
      })
    }
  }
}