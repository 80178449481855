import { CATEGORY } from "../../../services";
import { CATEGORY_DATA_STATE } from "../../globalActionType";

export const getCategory = (params) => {
  function request() { return { type: CATEGORY_DATA_STATE.CATEGORY_DATA } }
  function success(data) { return { type: CATEGORY_DATA_STATE.CATEGORY_DATA_SUCCESS, data } }
  function failure(data) { return { type: CATEGORY_DATA_STATE.CATEGORY_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await CATEGORY.getCategory(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getListCategory = (params) => {
  function request() { return { type: CATEGORY_DATA_STATE.CATEGORY_DATA } }
  function success(data) { return { type: CATEGORY_DATA_STATE.CATEGORY_DATA_SUCCESS, data } }
  function failure(data) { return { type: CATEGORY_DATA_STATE.CATEGORY_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await CATEGORY.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getDetailCategory = (params) => {
  function request() { return { type: CATEGORY_DATA_STATE.CATEGORY_DETAIL_DATA } }
  function success(data) { return { type: CATEGORY_DATA_STATE.CATEGORY_DETAIL_DATA_SUCCESS, data } }
  function failure(data) { return { type: CATEGORY_DATA_STATE.CATEGORY_DETAIL_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await CATEGORY.getDetail(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const resetCategory = () => (dispatch) => {
  dispatch({
    type: CATEGORY_DATA_STATE.CATEGORY_DATA_RESET
  })
}

export const resetCategoryDetail = () => (dispatch) => {
  dispatch({
    type: CATEGORY_DATA_STATE.CATEGORY_DETAIL_DATA_RESET
  })
}