import React from 'react'

const LandingSection = ({
  children,
  className
}) => {
  return (
    <div className={`${className} text-center`}>
      {/* <label>Section</label> */}
      {children}
    </div>
  )
}

export default LandingSection