import React, { useEffect } from 'react'
import LandingSection from '../../../components/templates/landing/Section'
import Maps from '../../../components/organisms/landing/Maps';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAbout } from '../../../redux/admin/about/action';
import LoadingSr from '../../../components/atoms/LoadingSr';
import { motion } from 'framer-motion'

const About = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { data, isLoading } = useSelector(state => state.about)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang
  }

  useEffect(() => {
    dispatch(getAbout(params))
  }, [lang]);

  return (
    <LandingSection>
      <div className="flex flex-col md:flex-row min-h-[10rem] w-content bg-gray space-y-10 md:space-y-0 md:space-x-10 p-12 md:justify-center">
        <div className="space-y-8 flex flex-col md:w-1/2">
          {isLoading ?
            <>
              <div className="flex justify-center">
                <LoadingSr className={"w-32 h-8"} />
              </div>
              <div className="space-y-2 text-justify flex-1">
                <LoadingSr className={"w-full h-full"} />
              </div>
              <div className="text-center md:text-left">
                <LoadingSr className={"w-32 h-14"} />
              </div>
            </>
            :
            <>
              <motion.div
                initial={{ opacity: 0, x: -200 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1.5, }}
                className="text-3xl capitalize font-semibold"
              >{t("about us")}</motion.div>
              <motion.div
                initial={{ opacity: 0, x: -200 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1.5, }}
                className="space-y-2 text-justify flex-1"
              >
                <p className="whitespace-pre-line">
                  {data?.description}
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -200 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1.5, }}
                className="text-center md:text-left"
              >
                <NavLink to="/about-us" className="uppercase text-blue-600 border-blue-600 border-2 p-4 w-40 rounded-md md:text-[14px] leading-[50px]">{t("know more")}</NavLink>
              </motion.div>
            </>
          }
        </div>
        <div className="space-y-8 flex flex-col md:w-1/2">
          <label className="text-3xl font-semibold capitalize">{t("our location")}</label>
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 1.5, }}
            className="border-2 h-fit w-full flex-1 -z-0"
          >
            <Maps
              className={"h-[20rem]"}
              zoom={6}
            />
          </motion.div>
        </div>
      </div>
    </LandingSection>
  )
}

export default About
