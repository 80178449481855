import React from 'react';
import Toast from '../atoms/Toast';
// import { createPortal } from "react-dom";
// import { useTransition } from "react-spring";


const ToastContainer = ({ toasts }) => {

  // const transitions = useTransition(toasts, ToastProvider => ToastProvider.id, {
  //   from: { right: "-100%" },
  //   enter: { right: "0%" },
  //   leave: { right: "-100%" }
  // });
  return (
    <div className="fixed z-50 right-8 top-8">
      {toasts.map(item => (
        <Toast key={item.id}
        id={item.id}
        type={item.content.type}
        time={item.content.time}
        >
          {item.content.message}
        </Toast>)
      )}
    </div>
  );
  // return createPortal(
  //   <div>
  //     {transitions.map(({ item, key, props }) => (
  //       <ToastProvider key={key} id={item.id} style={props}>
  //         {item.content}
  //       </ToastProvider>
  //     ))}
  //   </div>,
  //   document.body
  // );
}

export default ToastContainer;

