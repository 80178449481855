import React from 'react'

const Select = ({
  name,
  onChange,
  option,
  isLoading,
  className,
  placeholder,
  defaultValue,
  useAll,
  value
}) => {

  const handleChange = (event) => {
    onChange(event)
  }
  return (
    <select
      disabled={isLoading}
      defaultValue={value ?? "default"}
      name={name}
      // value={value}
      onChange={(event) => handleChange(event)}
      className={`${className} form-box border-2 rounded`}
    >
      {/* <option value="default" disabled hidden>{placeholder ?? "Select"}</option> */}
      {
        useAll && value ?
          <option value={""}>All</option> :
          <option value="default" disabled hidden>{placeholder ?? "Select"}</option>
      }
      {
        option?.map((value, key) => (
          <option className="bg-white" key={key} value={value?.value}>{value?.label}</option>
        ))
      }
    </select>
  )
}

export default Select