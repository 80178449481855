import { useState } from "react";

const Hamburger = ({
  onClick,
  isOpen,
  className
}) => {
  // const [isOpen, setIsOpen] = useState(onOpen)
  const genericHamburgerLine = `h-1 w-8 my-1 rounded-full bg-white transition ease transform duration-300`;
  const handleCLick = () => {
    // setIsOpen(!isOpen)
    onClick()
  }

  return (
    <div className="flex items-center md:hidden">
      <button
        className="flex flex-col md h-12 justify-center items-left group"
        onClick={() => handleCLick()}
      >
        <div
          className={`${genericHamburgerLine} ${isOpen
            ? "rotate-45 translate-y-3 opacity-50 group-hover:opacity-100"
            : "opacity-50 group-hover:opacity-100"
            }`}
        />
        <div
          className={`${genericHamburgerLine} ${isOpen ? "opacity-0" : "opacity-50 group-hover:opacity-100"
            }`}
        />
        <div
          className={`${genericHamburgerLine} ${isOpen
            ? "-rotate-45 -translate-y-3 opacity-50 group-hover:opacity-100"
            : "opacity-50 group-hover:opacity-100"
            }`}
        />
      </button>
      {
        isOpen ?
          <div 
            className="transition ease transform cursor-pointer text-gray-300 hover:text-white"
            onClick={() => handleCLick()}
          >
            Close
          </div>
        : ""
      }
    </div> 
  )
}

export default Hamburger

// ReactDOM.render(<Hamburger />, document.getElementById("Hamburger"));
