import React from 'react'
import Main from '../../components/templates/Main'

const Dashboard = () => {
  return (
    <div className='bg-white p-5 shadow-md mb-5'>
      <div className='text-xl'>Dashboard</div>
    </div>
  )
}

export default Dashboard