import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '../../components/atoms/Button'
import Input from '../../components/atoms/Input'
import Label from '../../components/atoms/Label'
import Loading from '../../components/atoms/Loading'
import { confirmRegistrationToken, submitProfile } from '../../redux/admin/register/action'
import { useToast } from '../../components/organisms/ToastProvider';
import { toastRequest, toastReset } from '../../redux/global/toast/action';
import { resetSubmit } from '../../redux/admin/profile/action'

const ConfirmRegistration = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const location = useLocation()
  const { addToast } = useToast()
  const urlParams = useParams()

  const params = {
    token: urlParams?.token
  }
  const {
    dataConfirm,
    isLoadingConfirm,
    isErrorConfirm,
    isSuccessConfirm,
    messageConfirm } = useSelector(state => state.register)
  const { token } = useSelector(state => state.dataStore)
  const { message, isLoading, isError, alert } = useSelector(state => state.profileSubmit)
  const dataToast = useSelector(state => state.toast);

  const [inputs, setInputs] = useState({
    name: '',
    username: '',
    password: '',
    confirmPassword: '',
  })

  const inputParams = {
    data: {
      type: "confirmation",
      name: inputs?.name,
      username: inputs?.username,
      password: inputs?.password,
      confirmPassword: inputs?.confirmPassword,
      email: dataConfirm?.email
    },
    id: dataConfirm?.id
  }

  let handleChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = () => {
    console.log(inputParams)
    dispatch(submitProfile(inputParams))
    dispatch(toastRequest())
  }

  useEffect(() => {
    if (!token && urlParams.token) {
      dispatch(confirmRegistrationToken(params))
    }
    if (isErrorConfirm || (alert === "success")) {
      setTimeout(() => {
        history(location.state ? location.state.from.pathname : "/auth/login")
        dispatch(resetSubmit())
      }, 2000);
    }
  }, [token, urlParams.token, isErrorConfirm, alert]); //eslint-disable-line

  useEffect(() => {
    if (isError && dataToast.toast) {
      addToast({
        type: alert,
        message: message,
        // time:5000
      })
      dispatch(toastReset())//reset
    }

  }, [isError, alert]);

  return (
    <div className='min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-300'>
      <div className='max-w-md w-full  bg-white p-5 rounded-xl'>
        {isLoadingConfirm || isLoading ? <Loading className={"mb-3"} /> : ""}
        {isSuccessConfirm && alert !== "success" ?
          <div>
            <div className="mb-5 text-center text-xl font-semibold">Input Profile</div>
            <form className='w-full max-w-sm mx-auto space-y-6'>
              <div className="form-group">
                <Label className="md:w-1/3">Name</Label>
                <Input
                  className="w-full md:w-2/3 form-box"
                  type="text"
                  name="name"
                  onChange={(event) => handleChange(event)}
                  value={inputs.name}
                />
              </div>
              <div className="form-group">
                <Label className="md:w-1/3">Username</Label>
                <Input
                  className="w-full md:w-2/3 form-box"
                  type="text"
                  name="username"
                  onChange={(event) => handleChange(event)}
                  value={inputs.username}
                />
              </div>
              <div className="form-group">
                <Label className="md:w-1/3">Password</Label>
                <Input
                  className="w-full md:w-2/3 form-box"
                  type="password"
                  name="password"
                  onChange={(event) => handleChange(event)}
                  value={inputs.password}
                />
              </div>
              <div className="form-group">
                <Label className="md:w-1/3">Confirm Password</Label>
                <Input
                  className="w-full md:w-2/3 form-box"
                  type="password"
                  name="confirmPassword"
                  onChange={(event) => handleChange(event)}
                  value={inputs.confirmPassword}
                />
              </div>
              <div className='md:flex md:items-center'>
                <div className='md:w-1/3'></div>
                <div className='w-full md:w-2/3'>
                  <Button
                    type={'button'}
                    onClick={() => handleSubmit()}
                    buttonStyle={'btn-primary mr-5 m-1'}
                    disabled={!inputs?.name || !inputs?.username || !inputs?.password || !inputs?.confirmPassword}>
                    Confirm
                  </Button>
                </div>
              </div>
            </form>
          </div>
          :
          <div className='text-xl font-semibold text-gray-900 text-center'>
            {isLoadingConfirm && "Verify"}
            {isErrorConfirm && alert !== "success" ? messageConfirm:"Success"}
          </div>
        }
      </div>
    </div>
  )
}

export default ConfirmRegistration