import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/atoms/Button';
import Input from '../../components/atoms/Input';
import Modal from '../../components/atoms/Modal';
import DeleteDialog from '../../components/molecules/DeleteDialog';
import DataTableServer from '../../components/organisms/DataTableServer';
import { useToast } from '../../components/organisms/ToastProvider';
import { getUsers } from '../../redux/admin/users/action';
import { deleteUsers, resetSubmit } from '../../redux/admin/users/create/action';
import { toastRequest, toastReset } from '../../redux/global/toast/action';
import FormUsers from './form';
import _ from "lodash";
import { remappingFilter } from '../../helpers/filterMapping';
import Select from '../../components/atoms/Select';
import Popover from '../../components/molecules/Popover';
import { resetRole } from '../../redux/admin/role/action';
import { formatDate } from '../../helpers/convertDate';

const Users = () => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const [modalName, setModalName] = useState(null)
  const [fetched, setFetched] = useState(false)
  const { addToast } = useToast()

  const { data, isLoading } = useSelector(state => state.users)
  const dataToast = useSelector(state => state.toast);
  const submit = useSelector(state => state.usersSubmit)
  const others = useSelector(state => state.others)

  const [editData, setEditData] = useState(null)
  const [deleteId, setDeleteId] = useState(null)

  const [pagination, setPagination] = useState({
    page: 1, limit: 10
  })
  const [sorting, setSorting] = useState({
    sortBy: "", sortType: "" //sortby name, sorttype asc or desc
  })
  const [filter, setFilter] = useState([])

  const params = {
    pagination: pagination,
    sorting: sorting,
    filter: filter.join(",")
  }

  useEffect(() => { // get first data
    dispatch(getUsers(params))
  }, []) //eslint-disable-line

  useEffect(() => { // reload datatable
    if (fetched) {
      dispatch(getUsers(params))
      setFetched(false);
    }
  }, [fetched]); //eslint-disable-line

  useEffect(() => { // global toast 
    if (dataToast.toast && submit.alert) {
      addToast({
        type: submit.alert,
        message: submit.message,
        // time:5000
      })
      dispatch(toastReset())
      if (submit.alert === 'success') {
        setFetched(true)
        setIsOpen(false)
        dispatch(resetSubmit())
      }
    }

  }, [dataToast.toast, submit.alert]); //eslint-disable-line

  const onClose = () => {
    setIsOpen(false)
    dispatch(resetRole())
  };

  const onSubmit = () => {
    dispatch(toastRequest());
  };

  const onCreate = (event) => {
    setModalName(event.target.name)
    setIsOpen(true)
  }

  const onClick = (row) => {
    setEditData(row)
    setModalName("popover")
    setIsOpen(true)
  }

  const onEdit = async (row) => {
    setModalName("edit")
    setIsOpen(true)
  }

  const onDelete = async (id) => {
    setModalName("delete")
    setDeleteId(id)
    setIsOpen(true)
  }

  const onSearch = (event) => {
    eventSearch(event.target.value, event.target.name, filter)
  }

  const eventSearch = useMemo(
    () => _.debounce((value, name, filter) => {
      remappingFilter(setFilter, filter, value, name, "like")
      setFetched(true)
    }, 1000),
    []
  )

  const onSelect = (event) => {
    remappingFilter(setFilter, filter, event.target.value, event.target.name,"eq")
    setFetched(true);
  }

  const handleLimitChange = (limit, page) => {
    setPagination({
      ...pagination,
      limit: limit
    })
    setFetched(true)
  };

  const handlePageChange = (page) => {
    setPagination({
      ...pagination,
      page: page
    })
    setFetched(true);
  };

  const handleSort = (column, sortDirection) => {
    setSorting({
      ...sorting,
      sortBy: column?.field,
      sortType: sortDirection
    })
    setFetched(true);
  };

  let handleDelete = () => {
    const multiId = {
      id: deleteId
    }
    dispatch(deleteUsers(multiId))
    dispatch(toastRequest());
    // setFetched(true);
  }

  // let handleEdit = async (row) => {

  // }

  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
      omit: true
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      field: "email"
    },
    {
      name: 'Role',
      selector: row => row.employee?.role?.name,
      sortable: true,
      field: "role"
    },
    {
      name: 'Name',
      selector: row => row.employee?.name ?? "-",
      sortable: true,
      field: "Name"
    },
    {
      name: 'Username',
      selector: row => row.username ?? "-",
      sortable: true,
      field: "username"
    },
    {
      name: 'Created At',
      selector: row => formatDate(row.createdAt,"en"),
      sortable: true,
      // right: true,
      field: "createdAt"
    },
    {
      name: 'Updated At',
      selector: row => formatDate(row.updatedAt,"en"),
      sortable: true,
      field: "updatedAt"
    },
  ];

  return (
    <>
      <div className='bg-white p-5 shadow-md mb-5'>
          <DataTableServer
            title={"Users"}
            columns={columns}
            data={data?.data}
            total={data?.pagination?.totalItems}
            loading={isLoading}
            pageChange={handlePageChange}
            limitChange={handleLimitChange}
            sortChange={handleSort}
            actionsButton={
              <div className="flex flex-col-reverse space-y-2 space-y-reverse md:flex-row md:space-x-4 md:space-y-0">
                <Input
                  placeholder="Search"
                  type="text"
                  name="search"
                  className={"text-sm py-1 px-4"}
                  onChange={(event) => onSearch(event)}
                />
                {/* <Select
                  placeholder="Filter"
                  type="text"
                  name="filter"
                  className={"text-sm py-1 px-4"}
                  onChange={(event) => onSelect(event)}
                  option={[
                    {
                      label: "filter 1",
                      value: "1",
                    },
                    {
                      label: "filter 2",
                      value: "2",
                    }
                  ]}
                /> */}
                <Button
                  buttonStyle={"btn-primary text-sm p-1"}
                  name={"create"}
                  onClick={(event) => onCreate(event)}
                >
                  Create
                </Button>
              </div>
            }
            onRowClick={(row) => onClick(row)}
            deleted={(id) => onDelete(id)}
            toogleSelectableRows={fetched}
          />
      </div>

      <Modal
        isOpen={isOpen && modalName === "create"}
        onClose={() => onClose()}
        title={"Create Users"}
      >
        <FormUsers
          onClose={() => onClose()}
          onSubmit={() => onSubmit()}
        />
      </Modal>
      <Modal
        isOpen={isOpen && modalName === "edit"}
        onClose={() => onClose()}
        title={"Edit Users"}
      >
        <FormUsers
          row={editData}
          onClose={() => onClose()}
          onSubmit={() => onSubmit()}
        />
      </Modal>
      <Modal title={"Delete Employee"}
        isOpen={isOpen && modalName === "delete"}
        onClose={() => onClose()}
      >
        <DeleteDialog
          id={deleteId}
          isLoading={submit.isLoading}
          deleted={() => handleDelete()}
          close={() => onClose()}
        />
      </Modal>
      <Popover
        isOpen={isOpen && modalName === "popover"}
        onClose={() => onClose()}
        coord={others?.coordinate}
        onView={() => onClose()}
        onEdit={(row) => onEdit(row)}
        onSendMail={() => onClose()}
      />
    </>
  )
}

export default Users
