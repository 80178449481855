import { combineReducers } from "redux";
import dataStore from "../redux/admin/login/reducer";
import other from "../redux/global/translation/reducer";
import register from "../redux/admin/register/reducer";
import role from "../redux/admin/role/reducer";
import roleSubmit from "../redux/admin/role/create/reducer";
import baseMenu from "../redux/admin/base-menu/reducer";
import users from "../redux/admin/users/reducer";
import usersSubmit from "../redux/admin/users/create/reducer";
import profileSubmit from "../redux/admin/profile/reducer";
import privilege from "../redux/admin/privilege/reducer";
import privilegeSubmit from "../redux/admin/privilege/form/reducer";
import service from "../redux/admin/service/reducer";
import serviceSubmit from "../redux/admin/service/submit/reducer";
import news from "../redux/admin/news/reducer";
import newsSubmit from "../redux/admin/news/submit/reducer";
import hero from "../redux/admin/hero/reducer";
import heroSubmit from "../redux/admin/hero/create/reducer";
import career from "../redux/admin/career/reducer";
import careerSubmit from "../redux/admin/career/submit/reducer";
import category from "../redux/admin/category/reducer";
import categorySubmit from "../redux/admin/category/submit/reducer";
import contact from "../redux/admin/contact/reducer";
import contactSubmit from "../redux/admin/contact/submit/reducer";
import location from "../redux/admin/location/reducer";
import locationSubmit from "../redux/admin/location/submit/reducer";
import about from "../redux/admin/about/reducer";
import aboutSubmit from "../redux/admin/about/submit/reducer";
import gallery from "../redux/admin/gallery/reducer";
import gallerySubmit from "../redux/admin/gallery/submit/reducer";
import ourGroup from "../redux/admin/our-group/reducer";
import ourGroupSubmit from "../redux/admin/our-group/submit/reducer";
import globalSubmit from "../redux/global/submit/reducer";
import toast from "../redux/global/toast/reducer";
import others from "../redux/global/others/reducer";
import search from "../redux/global/search/reducer";

const rootReducer = combineReducers({
  dataStore,
  register,
  baseMenu,
  other,
  toast,
  users,
  usersSubmit,
  role,
  roleSubmit,
  profileSubmit,
  privilegeSubmit,
  privilege,
  service,
  serviceSubmit,
  career,
  careerSubmit,
  category,
  categorySubmit,
  contact,
  contactSubmit,
  location,
  locationSubmit,
  news,
  newsSubmit,
  hero,
  heroSubmit,
  about,
  aboutSubmit,
  gallery,
  gallerySubmit,
  ourGroup,
  ourGroupSubmit,
  globalSubmit,
  others,
  search
});

export default rootReducer;
