import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import Img from '../../components/atoms/Img'
import Label from '../../components/atoms/Label'
import Loading from '../../components/atoms/Loading'
import Modal from '../../components/atoms/Modal'
import DeleteDialog from '../../components/molecules/DeleteDialog'
import { useToast } from '../../components/organisms/ToastProvider'
import { apiUrl } from '../../helpers/baseUrl'
import { getListOurGroup } from '../../redux/admin/our-group/action'
import { deleteList, submit } from '../../redux/admin/our-group/submit/action'
import { toastRequest, toastReset } from '../../redux/global/toast/action'

const OurGroup = () => {

  const dispatch = useDispatch();
  let [formData, setFormData] = useState(new FormData())
  const [img, setImg] = useState([])
  const [id, setId] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [fetched, setFetched] = useState()
  const { addToast } = useToast()

  const data = useSelector(state => state.ourGroup)
  const dataToast = useSelector(state => state.toast)
  const { alert, message, isLoading } = useSelector(state => state.ourGroupSubmit)

  // console.log(data)
  const params = {
    // id: row?.uniqId,
    data: formData,
    type: 'create'
  }

  useEffect(() => {
    dispatch(getListOurGroup(params))
  }, []);

  useEffect(() => {
    if (fetched) {
      dispatch(getListOurGroup(params))
      setFetched(false)
    }
  }, [fetched]);

  useEffect(() => {
    if (data.isSuccess) {
      // setInputs(data)
      setImg([])
      setIsOpen(false)
    }
  }, [data.isSuccess]);

  useEffect(() => {
    if (!isOpen) {
      setId(null)
    }
  }, [isOpen]);

  useEffect(() => { // global toast 
    if (dataToast.toast && alert) {
      addToast({
        type: alert,
        message: message,
        // time:5000
      })
      if (alert === 'success') {
        setFetched(true)
      }
    }

  }, [dataToast.toast, alert]); //eslint-disable-line

  const onDelete = (value, type) =>{
    if (type === 'img') {
      setImg(_.filter(img, index => index.id !== value))
    } else if (type === 'list') {
      setId(value)
      setIsOpen(true)
    }
      // dispatch(deleteList(value))
    }
  const onClose = () =>{
    setIsOpen(false)
    setId(null)
  }

  const handleChange = (event) => {
    let imageArray = event.target.files
    if (event.target.files) {
      _.map(imageArray, (val, key) => {
        setImg(prevState => ([...prevState, {
          url: URL.createObjectURL(event.target.files[key]),
          file: event.target.files[key],
          id: key + 1
        }]))
      })
    }
  }
  const handleDelete = () => {
    // console.log()
      dispatch(deleteList(id))
  }

  const handleSubmit = () => {
    setFormData(new FormData())
    for (var key in img) {
      formData.append(key, img[key].file);
    }
    dispatch(submit(params))
  }

  return !data.isSuccess ? <Loading /> : (
    <>
      <div className='bg-white p-5 shadow-md mb-5'>
        <label className="text-xl">Our Group</label>
        <hr className="my-5" />
        <div className="flex flex-wrap gap-2 w-full">
          {_.map(data.data, (val, key) => (
            <div key={key} className="relative hover:shadow h-40 w-40 border-2 rounded">
              <div
                className="absolute top-0 right-3 text-red-700 font-bold text-2xl cursor-pointer"
                onClick={() => onDelete(val.id, 'list')}
              >x
              </div>
              <div className="flex justify-center w-full h-full">
                <Img key={key} src={apiUrl("public/assets/img/ourgroup/" + val.image)} className="h-full text-center object-cover" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='bg-white p-5 shadow-md mb-5'>
        <label className="text-xl">Add Image</label>
        <hr className="my-5" />
        <div className="flex flex-wrap gap-2 w-full mb-4">
          {_.map(img, (val, key) => (
            <div key={key} className="relative h-40 w-40 border-2 rounded">
              <div
                className="absolute top-0 right-3 text-red-700 font-bold text-2xl cursor-pointer"
                onClick={() => onDelete(val.id, 'img')}
              >x
              </div>
              <div className="flex justify-center w-full h-full">
                <Img key={key} src={val.url} className="h-full text-center object-cover" />
              </div>
            </div>
          ))}
        </div>
        <form className="flex flex-col px-4 max-w-full md:max-w-fit ">
          <div className="flex flex-col space-y-6 md:w-[35rem]">
            <div className="form-group">
              <input multiple onChange={(event) => handleChange(event)} name="image" type="file" />
              {/* <Label className="md:w-1/3 md:text-left">Image</Label>
              <div className="flex items-center w-full md:w-2/3 space-x-4 bg-slate-300 border-2 rounded overflow-hidden">
                <label
                  htmlFor="file-upload"
                  className="relative w-full h-40 cursor-pointer bg-transparent font-medium text-indigo-600"
                >
                  <span className="flex justify-center w-full h-40 overflow-hidden bg-gray-100 absolute">
                    <svg className="h-full w-full text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </span>
                  <input id="file-upload" multiple onChange={(event) => handleChange(event)} name="image" type="file" className="sr-only" />
                </label>
              </div> */}
            </div>
            <div className='text-left pt-5'>
              <Button type={"button"}
                buttonStyle={"btn-primary text-sm p-1"}
                onClick={() => handleSubmit()}
              // disabled={isLoading}
              >
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Modal isOpen={isLoading && !id} title={"Uploading"}>
        <p className="text-red-800">Please do not close your tab or browser during uploading</p>
        <Loading className={"mt-4"} />
      </Modal>
      <Modal title={"Delete Image"}
        isOpen={isOpen}
        onClose={() => onClose()}
      >
        <DeleteDialog
          id={id}
          isLoading={isLoading}
          deleted={() => handleDelete()}
          close={() => onClose()}
        />
      </Modal>
    </>
  )
}

export default OurGroup