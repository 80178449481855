import React from 'react'

const Input = ({
  className,
  type,
  pattern,
  name,
  placeholder,
  isError,
  onChange,
  value,
  defaultValue,
  disabled,
  onKeyDown,
  checked
}) => {
  return (
    <input className={`${disabled ? "bg-slate-200 ":"bg-gray-100"} border-2 border-gray-200 rounded focus:bg-white focus:border-purple-500 focus:outline-none ${className}`} 
      type={type ?? "text"}
      pattern={pattern}
      name={name}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      checked={checked}
    />
  )
}

export default Input