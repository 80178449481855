import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../atoms/Logo'
import Navbar from '../atoms/Navbar'
import MenuSkeleton from '../molecules/MenuSkeleton';
import { getMenu } from '../../redux/admin/base-menu/action';
import { logout } from '../../redux/admin/login/action';

const Sidebar = () => {

  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();

  const {
    isLoading,
    isError,
    isSuccess,
    data,
    message
  } = useSelector(state => state.baseMenu);

  const users = useSelector(state => state.dataStore);

  const [filters, setFilters] = useState(["name~like~admin"])
  const [orderBy, setOrderBy] = useState("sequence")

  const params = {
    idRole: users?.data?.idRole
    // filters,
    // orderBy
  }

  useEffect(() => {
    dispatch(getMenu(params))
  }, []); //eslint-disable-line

  const handleLogout = async () => {
    dispatch(logout())
    history(location.state ? location.state.from.pathname : "/admin/login");
  }

  // console.log(data)

  return (
    <div className="sidebar flex flex-col fixed overflow-y-scroll z-20 w-64 h-screen md:shadow transform -translate-x-full md:translate-x-0 transition-transform duration-150 ease-in bg-[#273469]">
      <Logo brandName={"ILC Logistic Indonesia"} />
      <div className="sidebar-content px-4 py-6">
        <ul className="flex flex-col w-full">
          <MenuSkeleton loading={isLoading} />
          {!isLoading &&
            data?.map((value, key) => (
              value?.url === "#" ?
                <div key={key}>
                  <li  className="my-px">
                    <span className="flex font-semibold text-gray-300 px-3 my-3 uppercase">{value?.name}</span>
                  </li>
                  {
                    value?.submenu.map((subValue, subKey) => (
                      <Navbar
                        key={subKey}
                        title={subValue?.name}
                        svgPath={subValue?.svgPath}
                        to={"/admin/" + subValue?.url}
                      />
                    ))
                  }
                </div>
                :
                <Navbar
                  key={key}
                  title={value?.name}
                  svgPath={value?.svgPath}
                  to={"/admin/" + value?.url}
                />
            ))
          }
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
