import React from 'react'
import Footer from '../atoms/Footer'
import Header from '../organisms/Header'
import Sidebar from '../organisms/Sidebar'

const Main = ({
  children,
  title,
}) => {
  return (
    <div className="flex flex-row min-h-screen text-gray-800 ">        
      <Sidebar/>
      <Header />
      <div className="flex flex-col pl-64 mt-16 w-full">
        <main className="main flex flex-col flex-grow mt-2 bg-gray-300 -ml-64 md:ml-0 transition-all duration-150 ease-in">
          <div className="main-content flex flex-col flex-grow overflow-x-auto">
            <div className="flex flex-col flex-grow rounded m-5">
              {/* <div className='bg-white p-5 shadow-md'> */}
              {children}
              {/* </div> */}
            </div>
          </div>
          <Footer brandName={"ILC"} year={"2022"} creator={"JPP Project"}/>
        </main>        
      </div>
    </div> 
  )
}

export default Main
