import React, { useEffect, useRef, useState } from 'react'

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  actionsButton
}) => {

  // const [isOpen , setisOpen] = useState(false)
  const ref = useRef();

  useEffect(() => {
    if(onClose){
      const checkIfClickedOutside = e => {
        // If the menu is isOpen and the clicked target is not within the menu,
        // then onClose the menu
        if (isOpen && ref.current && !ref.current.contains(e.target)) {
          // setisOpen(false)
          onClose()
        }
      }
      document.addEventListener("mousedown", checkIfClickedOutside)
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside)
      }
    }
  }, [isOpen])   //eslint-disable-line

  return isOpen && (
    <div className="fixed z-50 inset-0 overflow-y-auto bg-slate-400 bg-opacity-25 overflow-scroll" style={{marginTop:'0'}}>
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0">
        <div ref={ref} className="inline-block align-bottom z-50 bg-white rounded-lg text-left min-w-[25rem] overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:min-w-lg ">
          <div className="bg-white px-4 p-6">
            <div className="sm:mt-0">
              <div className="text-center text-xl font-bold mb-6 capitalize">
                {title ? title : "title"}
                <hr className="my-4" />
              </div>
              <div className="mx-5">
                {children ? children : "childrens"}
              </div>
              <div className="text-right">
                {actionsButton}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Modal
