import React from 'react'
import { useSelector } from 'react-redux';
import { baseUrl } from '../../helpers/baseUrl';
import UsersPanel from '../molecules/UsersPanel'
import { Link } from 'react-router-dom';

const Header = () => {

  const { data, isLoading, username } = useSelector(state => state.dataStore);

  return (
    <header className="header fixed z-20 w-full bg-white shadow">
      <div className="header-content flex items-center flex-row">
        <Link to={'/admin'} className="flex flex-row items-center space-x-4 mx-4">
          <img src={baseUrl("/assets/svg/logo.svg")} alt="ilc" className="h-8 md:h-10" />
          <div className="md:text-xl font-bold text-[#007BC4] uppercase">PT. ILC LOGISTICS INDONESIA</div>
        </Link>
        <UsersPanel name={data?.name ?? username} img={data?.image} isLoading={isLoading} alt={data?.name?.charAt(0) ?? username?.charAt(0)} privilege={"Administrator"} />
      </div>
    </header>
  )
}

export default Header
