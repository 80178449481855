import { ROLE } from "../../../services";
import { ROLE_STATE } from "../../globalActionType";

export const getRole = (params) => {
  function request() { return { type: ROLE_STATE.ROLE_DATA } }
  function success(data, total) { return { type: ROLE_STATE.ROLE_DATA_SUCCESS, data, total } }
  function failure(message) { return { type: ROLE_STATE.ROLE_DATA_FAILURE, message } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await ROLE.getRole(params);
      const data = response.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  };
};

export const getRoleList = (params) => {
  function request() { return { type: ROLE_STATE.ROLE_DATA } }
  function success(data, total) { return { type: ROLE_STATE.ROLE_DATA_SUCCESS, data, total } }
  function failure(message) { return { type: ROLE_STATE.ROLE_DATA_FAILURE, message } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await ROLE.getRoleList(params);
      const data = response.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  };
};

export const resetRole = () => (dispatch) => {
  dispatch({
    type: ROLE_STATE.ROLE_DATA_RESET
  })
}
