import React, { useState } from 'react'

const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  disabled,
  name
}) => {    
  return (
    <button 
      className={`${buttonStyle} ${disabled && "disabled:opacity-75"}`}
      type={type}
      name={name}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    {/* {!disabled && children}
    {disabled && (
        <span className="animate-ping h-5 w-5 text-white">Processing...</span>
      )}  */}
    </button>
  )
}

export default Button
