import { PRIVILEGE_SUBMIT_STATE } from "../../../globalActionType";

const initialState = {
  isLoading: false,
  isError: false,
  toast: false,
  alert: "",
  message: "",
};

const privilegeSubmit = (state = initialState, action) => {
  switch (action.type) {
    case PRIVILEGE_SUBMIT_STATE.PRIVILEGE_SUBMIT:
      return {
        state,
        isLoading: true,
      };
    case PRIVILEGE_SUBMIT_STATE.PRIVILEGE_SUBMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        toast: true,
        alert: "success",
        message: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case PRIVILEGE_SUBMIT_STATE.PRIVILEGE_SUBMIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        toast: true,
        alert: "error",
        message: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case PRIVILEGE_SUBMIT_STATE.PRIVILEGE_SUBMIT_RESET:
      return {
        state,
      };
    default:
      return state;
  }
};

export default privilegeSubmit;