import { USERS } from "../../../services";
import { USERS_DATA_STATE } from "../../globalActionType";

export const getUsers = (params) => {
  function request() { return { type: USERS_DATA_STATE.USERS_DATA } }
  function success(data) { return { type: USERS_DATA_STATE.USERS_DATA_SUCCESS, data } }
  function failure(data) { return { type: USERS_DATA_STATE.USERS_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await USERS.getUsers(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};