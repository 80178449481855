import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Img from '../../../components/atoms/Img'
import LoadingSr from '../../../components/atoms/LoadingSr'
import YoutubeEmbed from '../../../components/organisms/YoutubeEmbed'
import LandingSection from '../../../components/templates/landing/Section'
import { apiUrl, baseUrl } from '../../../helpers/baseUrl'
import { getOtherListService } from '../../../redux/admin/service/action'
import { motion } from "framer-motion"

const Services = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { dataOther, isLoadingOther } = useSelector(state => state.service)
  const { lang } = useSelector(state => state.other)
  const params = {
    lang,
    type: 'main'
  }
  console.log("data : ", dataOther)
  useEffect(() => {
    dispatch(getOtherListService(params))
  }, [lang]);
  return (
    <LandingSection className={"bg-[#F5F7F9] min-h-[20rem] min-w-full flex"}>
      <div className="flex flex-col-reverse md:flex-row justify-center w-full">
        {/* <div className="bg-red-200 w-full p-6 flex flex-row items-start text-left space-x-4"> */}
        {!isLoadingOther && dataOther.length > 0 && (
          <div className="bg-[#F5F7F9] grid grid-cols-1 md:w-[calc(10%+35rem)] md:grid-cols-2 gap-6 text-left p-8">
            {isLoadingOther ?
              _.map([0, 1, 2, 3], (val, key) => (
                <div key={key} className="flex space-x-4 items-start">
                  <LoadingSr className="w-20 h-20" />
                  <div className="space-y-4">
                    <LoadingSr className="w-32 h-5" />
                    <div className="space-y-3">
                      <LoadingSr className="w-24 h-4" />
                      <LoadingSr className="w-32 h-4" />
                    </div>
                  </div>
                </div>
              ))
              :
              _.map(dataOther, (val, key) => (
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ ease: "easeOut", duration: 0.5 }} key={key} className="flex space-x-4 items-start">
                  <Img src={apiUrl("public/assets/img/service/" + val?.image)} alt="" className="w-20 min-h-fit" />
                  <div className="space-y-2">
                    <label className="font-bold md:text-[16px] leading-[18px]">{val?.title}</label>
                    <div className="md:text-[15px] leading-[26px]">{val?.description}</div>
                  </div>
                </motion.div>
              ))
            }
          </div>
        )}
        <div className="md:w-[40%] h-64 md:h-full bg-blue-200 flex justify-center md:overflow-hidden">
          {/* <Img src={baseUrl("/assets/img/other/bg-03.jpg")} alt="" className="h-full object-cover" /> */}
          <YoutubeEmbed embedId={"aktp9_Dz6Vc"} />
        </div>
      </div>
    </LandingSection>
  )
}

export default Services