import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Maps from "../../../components/organisms/landing/Maps";
import LandingSection from "../../../components/templates/landing/Section";
import { getlocation } from "../../../redux/admin/location/action";
import HeroContent from "../section/HeroContent";
import { motion } from "framer-motion"


const LandingLocation = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [id, SetId] = useState(null)

  const { data, isLoading } = useSelector(state => state.location)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang,
  }

  useEffect(() => {
    dispatch(getlocation(params))
  }, [lang]);

  const handleClick = (value) => {
    // console.log(value)
    if (id === value) {
      setIsOpen(!isOpen)
    } else {
      setIsOpen(true)
      SetId(value)
    }
  }

  return (
    <div className="bg-white">
      <HeroContent
        title={"location"}
        to={"location"}
      />
      <LandingSection>
        <div className="w-content p-12 space-y-8">
          <div className="flex items-center border-2 h-fit w-full flex-1">
            <Maps
              className={"flex justify-center bg-red-300 w-full h-[25rem] z-0"}
              zoom={6}
            />
          </div>
          <div className="flex flex-col space-y-4">
            {data.length &&
              _.map(data, (val, key) => (
                <motion.div
                  initial={{ opacity: 0, }}
                  whileInView={{ opacity: 1 }}
                  transition={{ ease: "easeOut", duration: 1, delay: (1 / 2 * key) }}
                  viewport={{ once: true }}
                  className="shadow-md"
                  key={key}
                >
                  <div onClick={() => handleClick(val.id)} className="w-full bg-slate-50 border-2 rounded-sm text-left py-2 px-5 cursor-pointer">
                    <label className="capitalize text-xl font-semibold">{val.title}</label>
                  </div>
                  {isOpen && id === val.id && <div className="whitespace-pre-line px-5 py-2 text-left border-2 border-t-0">{val.description} detail description</div>}
                </motion.div>
              ))
            }
          </div>
        </div>
      </LandingSection>
    </div>
  );
};

export default LandingLocation;
