import NotFound from "../components/templates/NotFound";
import About from "../pages/about";
import Career from "../pages/career";
import Category from "../pages/category";
import Contact from "../pages/contact";
import Dashboard from "../pages/dashboard";
import Gallery from "../pages/gallery";
import HeroAdmin from "../pages/hero";
import Location from "../pages/location";
import News from "../pages/news";
import OurGroup from "../pages/our-group";
import Profile from "../pages/profile";
import Role from "../pages/role";
import Service from "../pages/service";
import Users from "../pages/users";

const Routers = [
  {
    path: "*",
    component: NotFound,
  },
  {
    path: "",
    component: Dashboard,
  },
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "users",
    component: Users,
  },
  {
    path: "news",
    component: News,
  },
  {
    path: "service",
    component: Service,
  },
  {
    path: "hero",
    component: HeroAdmin,
  },
  {
    path: "role",
    component: Role,
  },
  {
    path: "profile",
    component: Profile,
  },
  {
    path: "company-profiles",
    component: About,
  },
  {
    path: "contact",
    component: Contact,
  },
  {
    path: "our-group",
    component: OurGroup,
  },
  {
    path: "career",
    component: Career,
  },
  {
    path: "location",
    component: Location,
  },
  {
    path: "gallery",
    component: Gallery,
  },
  {
    path: "category",
    component: Category,
  },
  {
    path: "logout",
    component: "",
  },
];

export default Routers;
