import { PROFILE } from "../../../services";
import { PROFILE_SUBMIT_STATE, LOGIN_STATE } from "../../globalActionType";

export const submit = (params) => {
  function request() { return { type: PROFILE_SUBMIT_STATE.PROFILE_SUBMIT } }
  function success(data) { return { type: PROFILE_SUBMIT_STATE.PROFILE_SUBMIT_SUCCESS, data } }
  function updateUsers(data) { return { type: LOGIN_STATE.PROFILE_UPDATE_SUCCESS, data } }
  function failure(data) { return { type: PROFILE_SUBMIT_STATE.PROFILE_SUBMIT_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {      
      const response = await PROFILE.update(params)
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(updateUsers(data))
        setTimeout(() => {
          dispatch(success(data))          
        }, 1000);
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
  }
};

export const resetSubmit = () => (dispatch) => {
  dispatch({
    type: PROFILE_SUBMIT_STATE.PROFILE_SUBMIT_RESET
  })
}