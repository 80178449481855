import api from "../config/api";

export const getRole = (params) => {
  return api.get("/role");
};

export const getRoleList = (params) => {
  return api.get(
    `/role?page=${params?.pagination?.page ?? "1"}&size=${
      params?.pagination?.limit ?? "10"
    }${
      params?.sorting
        ? "&sortBy=" +
          params?.sorting?.sortBy +
          "&sortType=" +
          params?.sorting?.sortType
        : ""
    }${params?.filter ? "&filters=" + params?.filter : ""}`
  );
};

export const createRole = (data) => {
  return api.post(`/role`, data);
};

export const editRole = (id, data) => {
  return api.put(`/role/${id}`, data);
};

export const deleteRole = (id) => {
  return api.put(`/role`, id);
};
