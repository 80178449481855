export const paginationNews = (totalPage, currentPage, onClick) => {

  // let totalPage = data?.pagination?.totalPages
  // let currentPage = data?.pagination?.currentPage
  let showPage = (totalPage - currentPage) >= 3 ? currentPage : (totalPage - 3) // 3 is total show array page except first and last page number
  let startPage = showPage > 2 ? showPage : 1

  const handleClick = (value) =>{
    // console.log("test",value)
    onClick(value)
  }

  return (
    <div className="flex flex-wrap gap-2">
      <label>Page :</label>
      {startPage > 1 ? <button
        className={currentPage === (startPage - 1) ?
          "bg-blue-800 py-1 px-2 text-white"
          : "bg-blue-400 py-1 px-2 text-white"
        }
        disabled={currentPage === (startPage - 1) ? true : false}
        onClick={() => handleClick(startPage - 1)}
      >
        {startPage - 1}
      </button> : null
      }
      {Array.from(Array(totalPage), (val, key) => (
        key < 3 ?
          <button
            className={currentPage === key + startPage ?
              "bg-blue-800 py-1 px-2 text-white"
              : "bg-blue-400 py-1 px-2 text-white"
            }
            onClick={() => handleClick(key + startPage)}
            disabled={currentPage === key + startPage ? true : false}
          >{key + startPage}
          </button> : null
      ))}
      {totalPage > 3 &&
        <>
          {totalPage - currentPage < 4 ? null : <label> ... </label>}
          <button
            className={currentPage === totalPage ?
              "bg-blue-800 py-1 px-2 text-white"
              : "bg-blue-400 py-1 px-2 text-white"
            }
            onClick={() => handleClick(totalPage)}
            disabled={currentPage === totalPage ? true : false}
          >
            {totalPage}
          </button>
        </>
      }
    </div>
  )
}