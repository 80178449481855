import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button';
import Checkbox from '../../components/atoms/Checkbox';
import Loading from '../../components/atoms/Loading';
import { getPrivilege } from '../../redux/admin/privilege/action';
import { submit } from '../../redux/admin/privilege/form/action';

const PrivilegeRole = ({
  row,
  onClose,
  onSubmit
}) => {
  const dispatch = useDispatch()

  const { data, index, isLoading, isSuccess } = useSelector(state => state.privilege)
  const submits = useSelector(state => state.privilegeSubmit)

  let [checkboxValue, setCheckboxValue] = useState([])

  const params = {
    id: row?.id,
    inputs: checkboxValue
  }

  useEffect(() => {
    dispatch(getPrivilege(params))
  }, []);

  useEffect(() => {
    if(isSuccess){
      setCheckboxValue(index)
    }
  }, [isSuccess]);

  const onChange = (event) => {
    if (event.target.checked) {
      setCheckboxValue([
        ...checkboxValue,
        event.target.value]
      )
    }
    else {
      // _.remove(checkboxValue, function(n) { return n % event.target.value === 0;});
      _.remove(checkboxValue, function (n) { return n === event.target.value })
    }
  }

  const handleSubmit = () => {
    // console.log(checkboxValue)
    dispatch(submit(params))
    onSubmit()
  }
  const handleClose = () => {
    onClose()
  }

  return !isSuccess ? <Loading /> : (
    <div>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th>Menu</th>
            <th>Action</th>
          </ tr>
        </thead>
        <tbody>
          {_.map(data, (val, key) => (
            <React.Fragment key={key}>
              <tr>
                <td className={val?.url === "#" ? "font-semibold" : ""}>{val?.name}</td>
                <td className="md:space-x-2">
                  {_.map(val.action, (valAction, keyAction) => (
                    // val?.url !== "#" &&
                    <Checkbox
                      key={keyAction}
                      defaultChecked={valAction.allow}
                      onChange={(event) => onChange(event)}
                      value={valAction.id}
                      name="actionmenu"
                    >
                      {valAction?.name}
                    </Checkbox>
                  ))}
                </td>
              </tr>
              {_.map(val?.submenu, (valSubmenu, keySubmenu) => (
                <tr key={keySubmenu}>
                  <td>{valSubmenu?.name}</td>
                  <td className="md:space-x-2">
                    {_.map(valSubmenu.action, (valAction, keyAction) => (
                      // valSubmenu?.url !== "#" &&
                      <Checkbox
                        key={keyAction}
                        defaultChecked={valAction.allow}
                        onChange={(event) => onChange(event)}
                        value={valAction.id}
                        name="actionmenu"
                      >
                        {valAction?.name}
                      </Checkbox>
                    ))}
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      {submits.isLoading ? <Loading className={"mt-5"} /> : null}
      <div className='text-right pt-5'>
        <Button type={"button"}
          buttonStyle={"btn-primary text-sm p-1 mr-4"}
          onClick={() => handleSubmit()}
          disabled={submits.isLoading}
        >
          Submit
        </Button>
        <Button type={"button"}
          buttonStyle={"btn-secondary text-sm p-1"}
          onClick={() => handleClose()}
          disabled={submits.isLoading}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default PrivilegeRole