import api from '../config/api';
import apiLanding from '../config/apiLanding';

export const getService = (params) => {
  return api
  .get(`/service?lang=${params?.lang ?? ''}&page=${params?.pagination?.page ?? "1"}&size=${params?.pagination?.limit ?? "10"}${params?.sorting ? "&sortBy="+params?.sorting?.sortBy+"&sortType="+params?.sorting?.sortType:""}${params?.filter ? "&filters="+params?.filter:""}`)
}

export const getList = (params) => {
  return apiLanding
  .get(`/service?lang=${params?.lang ?? ''}&type=${params?.type ?? ''}`)
}

export const getDetail = (params) => {
  return api
  .get(`/service/${params?.id ?? ''}`)
}

export const create = (params) => {
  return api
  .post(`/service`,params?.data)
}

export const update = (params) => {
  return api
  .put(`/service/update`,params?.data)
}

export const deleteService = (id) => {
  return api
  .put(`/service`,id)
}