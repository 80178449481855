import api from '../config/api';
import apiLanding from '../config/apiLanding';

export const getCategory = (params) => {
  return apiLanding
  .get(`/category?lang=${params?.lang ?? ''}`)
}

export const getList = (params) => {
  return api
  .get(`/category?lang=${params?.lang ?? 'en'}&page=${params?.pagination?.page ?? "1"}&size=${params?.pagination?.limit ?? "10"}${params?.sorting ? "&sortBy="+params?.sorting?.sortBy+"&sortType="+params?.sorting?.sortType:""}${params?.filter ? "&filters="+params?.filter:""}`)
}

export const getDetail = (params) => {
  return api
  .get(`/category/${params?.id ?? ''}`)
}

export const create = (params) => {
  return api
  .post(`/category`,params?.data)
}

export const update = (params) => {
  return api
  .put(`/category/update`,params?.data)
}

export const deleteList = (id) => {
  return api
  .put(`/category`,id)
}