import React from 'react'

const Label = ({
  className,
  children
}) => {
  return (
    <label className={`form-label capitalize ${className}`}>
      {children}
    </label>
  )
}

export default Label