import React from 'react'
import {NavLink} from 'react-router-dom'

const Navbar = ({
  title,
  svgPath,
  svgColor,
  to,
  className,
  onClick,
  children
}) => {
  return (    
    <li className={`my-px text-gray-400 ${className} hover:text-white`}>
      <NavLink className={({ isActive }) => (isActive ? 'active-menu' : 'menu')}      
        // style={({ isActive }) => ({ //raw style
        //   color: isActive ? '#fff' : '#545e6f',
        //   background: isActive ? '#7600dc' : '#f0f0f0',
        // })}
       to={to}
       onClick={onClick}
      >
        {/* <div className="flex flex-row items-center h-10 px-3 rounded-lg bg-pink-300"> */}
          <span className={`flex items-center justify-center text-lg ${svgColor}`}>
            <svg
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                d={svgPath}
              />
            </svg>
          </span>
          <span className="ml-3 uppercase ">{title}</span>
        {/* </div> */}
        {children}
          {/* <span
            className="flex items-center justify-center text-xs text-red-500 font-semibold bg-red-100 h-6 px-2 rounded-full ml-auto"
          >1k</span> */}
      </NavLink>
    </li>
  )
}

export default Navbar
