import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
// import "leaflet/dist/leaflet.css";
import style from '../../Map.css';
import L from 'leaflet';
import Loading from '../../components/atoms/Loading';
import LoadingSr from '../../components/atoms/LoadingSr';

const MapsPreview = ({
  data,
  className,
  zoom,
  isLoading
}) => {

  // const [defaultPosition, setDefaultPosition] = useState([-6.935445, 110.0680607])
  let lng = _.minBy(data?.data, 'lng')
  let lat = _.maxBy(data?.data, 'lat')

  const defaultPosition = [-6.935445, 110.0680607]
  // const defaultPosition = lng?.lng && lat.lat ? [lng?.lng, lat.lat] : [-6.935445, 110.0680607]
  
  // useEffect(() => {
  //   if(data?.data?.length){
  //     setDefaultPosition([lng.lng,lat.lat])
  //   }
  // }, [isLoading]);
  // console.log(defaultPosition)


  // let icon = L.icon({
  //     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  //     iconUrl: require('leaflet/dist/images/marker-icon.png'),
  //     shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  // })

  // return isLoading ? <Loading /> :  (
  return isLoading ? <LoadingSr className={"h-40 w-full"} /> :  (
    <div className={`overflow-hidden ${className}`}>
      <MapContainer style={{zIndex:1}} center={defaultPosition} zoom={zoom} scrollWheelZoom={false} className={className} >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {
          _.map(data.data, (val, key) => (
            // <Marker key={key} position={val?.position} icon={icon}>
            <Marker key={key} position={[val?.lng,val?.lat]}>
              <Popup>
                {val?.title}
              </Popup>
            </Marker>
          ))
        }
      </MapContainer>
    </div>
  )
}

export default MapsPreview