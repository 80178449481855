import React, { useMemo } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./styles.css";
import { useState } from "react";
import { useEffect } from "react";

export const Editor = ({
  onChange,
  value,
  placeholder
}) => {
  const [render, setRender] = useState(false)
  let [event, setEvent] = useState({
    target: {
      value: '',
      name: 'description'
    }
  });
  const handleChange = (html) => {
    event.target.value = html
    onChange(event)
  }

  return (
    <div className="w-full md:w-2/3">
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        onChange={(html) => handleChange(html)}
        placeholder={"Write something..."}
        modules={modules}
        formats={formats}
        value={value}
      />
    </div>
  );
};

export default Editor;
