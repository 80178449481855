import { USERS_SUBMIT_STATE } from "../../../globalActionType";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  toast: false,
  alert: "",
  // formData:[] //use when using wizard/spa
  trigger: "",
  update_success: false,
  message: "",
};

const submit = (state = initialState, action) => {
  switch (action.type) {
    case USERS_SUBMIT_STATE.USERS_SUBMIT_DATA:
      return {
        state,
        // trigger: type,
        isLoading: true,
      };
    case USERS_SUBMIT_STATE.USERS_SUBMIT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        toast: true,
        alert: "success",
        message: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case USERS_SUBMIT_STATE.USERS_SUBMIT_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        toast: true,
        alert: "error",
        message: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case USERS_SUBMIT_STATE.USERS_SUBMIT_DATA_RESET:
      return {
        state,
      };
    default:
      return state;
  }
};

export default submit;
