import { CONTACT } from "../../../services";
import { CONTACT_DATA_STATE } from "../../globalActionType";

export const getContact = (params) => {
  function request() { return { type: CONTACT_DATA_STATE.CONTACT_DATA } }
  function success(data) { return { type: CONTACT_DATA_STATE.CONTACT_DATA_SUCCESS, data } }
  function failure(data) { return { type: CONTACT_DATA_STATE.CONTACT_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await CONTACT.getContact(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getListContact = (params) => {
  function request() { return { type: CONTACT_DATA_STATE.CONTACT_DATA } }
  function success(data) { return { type: CONTACT_DATA_STATE.CONTACT_DATA_SUCCESS, data } }
  function failure(data) { return { type: CONTACT_DATA_STATE.CONTACT_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await CONTACT.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getDetailContact = (params) => {
  function request() { return { type: CONTACT_DATA_STATE.CONTACT_DETAIL_DATA } }
  function success(data) { return { type: CONTACT_DATA_STATE.CONTACT_DETAIL_DATA_SUCCESS, data } }
  function failure(data) { return { type: CONTACT_DATA_STATE.CONTACT_DETAIL_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await CONTACT.getDetail(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const resetContact = () => (dispatch) => {
  dispatch({
    type: CONTACT_DATA_STATE.CONTACT_DATA_RESET
  })
}

export const resetContactDetail = () => (dispatch) => {
  dispatch({
    type: CONTACT_DATA_STATE.CONTACT_DETAIL_DATA_RESET
  })
}