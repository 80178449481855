import React, { useState } from 'react'
import DataTable from 'react-data-table-component';
import Loading from '../atoms/Loading';
import { createTheme } from 'react-data-table-component';
import Button from '../atoms/Button';
import memoizeOne from 'memoize-one';
import ActionRows from './ActionRows';

const deleteActions = memoizeOne(deleteHandler => (
  <Button buttonStyle={"mr-2 text-red-600"} onClick={deleteHandler}>
    Delete
  </Button>
));

//  Internally, customStyles will deep merges your customStyles with the default styling.
const customStyles = {
  rows: {
    style: {
      minHeight: '26px', // override the row height
    },
  },
  header: {
    style: {
      minHeight: '26px',
      marginBottom: '1rem',
      padding: '0',
    },
  },
  headRow: {
    style: {
      minHeight: '35px',
    },
  },
  headCells: {
    style: {
      // backgroundColor:'#f1f1f1',
      backgroundColor: '#DCDFFF',
      fontWeight: '650'
    },
  },
  cells: {
    // style: {
    //     paddingLeft: '8px', // override the cell padding for data cells
    //     paddingRight: '8px',
    // },
  }
};

createTheme('solarized', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#002b36',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

const DataTableServer = ({
  title,
  columns,
  data,
  loading,
  total,
  pageChange,
  limitChange,
  onRowClick,
  deleted,
  uniqId,
  componentCreate,
  actionsButton,
  toogleSelectableRows,
  sortChange
}) => {

  const [row, selectRow] = useState([]);
  // const [toogleSelectableRows, setToogleSelectableRows] = useState(false);

  const handlePageChange = (page) => {
    pageChange(page)
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    limitChange(newPerPage, page)
  };

  const handleSort = async (column, sortDirection) => {
    sortChange(column, sortDirection)
  };

  let handleChange = (state) => {
    selectRow(state.selectedRows)
  };

  let deleteItem = () => {
    const rows = uniqId ? row.map(r => r.uniqId) : row.map(r => r.id)
    deleted(rows)
  };

  const handleRowClicked = (row) => {
    onRowClick(row)
  }

  return (

    <>
      <ActionRows>
        <DataTable
          title={title ? title : ""}
          columns={columns}
          data={data}
          progressPending={loading}
          progressComponent={<Loading />}
          selectableRows
          selectableRowsHighlight
          // selectableRowsSingle
          // selectableRowsNoSelectAll
          // selectableRowsComponent={Checkbox}
          // selectableRowsComponentProps={selectProps}
          clearSelectedRows={toogleSelectableRows}
          pagination
          paginationServer
          paginationTotalRows={total}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          onSort={handleSort}
          sortServer
          persistTableHead
          highlightOnHover
          customStyles={customStyles}
          actions={actionsButton}
          // theme="solarized"
          onSelectedRowsChange={handleChange}
          onRowClicked={onRowClick && handleRowClicked}
          contextActions={deleted && deleteActions(deleteItem)}
          // actions={addActions(openDialog)}
        />
      </ActionRows>
    </>
  )
}

export default DataTableServer
