import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getContact } from '../../../redux/admin/contact/action'
import LandingFooter from '../../organisms/landing/Footer'
import LandingHeader from '../../organisms/landing/Header'

const LandingMain = ({
  children,
  title,
}) => {
  const dispatch = useDispatch()
  // const { t } = useTranslation()

  const { data, isLoading } = useSelector(state => state.contact)
  // const { lang } = useSelector(state => state.other)

  // const params = {
  //   lang
  // }

  useEffect(() => {
    dispatch(getContact())
  }, []);
  return (
    <div className="font-roboto">
      <LandingHeader data={data} isLoading={isLoading} />
      {/* <div>Landing Main</div> */}
      {children}
      <LandingFooter data={data} isLoading={isLoading} />
    </div>
  )
}

export default LandingMain