import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Input from "../../../components/atoms/Input";
import { formatDate } from "../../../helpers/convertDate";
import { getCategory } from "../../../redux/admin/category/action";
import { getNews, getRecentNews } from "../../../redux/admin/news/action";
import { setCategory, setSearch } from "../../../redux/global/search/action";

const LandingNewsSidebar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const baseParams = useParams()
  const navigate = useNavigate()

  const { dataRecent, isLoadingRecent } = useSelector(state => state.news)
  const category = useSelector(state => state.category)
  const { lang } = useSelector(state => state.other)

  const url = baseParams['*'].split("/").shift();

  const params = {
    lang,
    pagination: {
      limit:5
    }
  }

  // console.log(url)

  useEffect(() => {
    dispatch(getRecentNews(params))
    dispatch(getCategory(params))
  }, [lang]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      dispatch(setSearch(event.target.value))
      if(url !== 'news'){
        navigate('/news', {replace: true})
      }
    }
  }
  
  const handleClick = (value,name) => {
    let params = {
      id:value,
      name
    }
    dispatch(setCategory(params))
    if(url !== 'news'){
      navigate('/news', {replace: true})
    }
  }  

  // const onSearch = (event) => {
  //   eventSearch(event.target.value)
  // }
  // const eventSearch = useMemo(
  //   () => _.debounce((value) => {
  //     dispatch(setSearch(value))
  //   }, 1000),
  //   []
  // )

  return (
    <div className="flex flex-col space-y-8 justify-center w-full md:w-1/3">
      <Input
        className={"form-box"}
        placeholder={t("enter search keywords")}
        // onChange={(event) => onSearch(event)}
        onKeyDown={(event)=> handleKeyDown(event)}
      />
      <div className="space-y-2">
        <label className="text-xl font-semibold uppercase">{t("categories")}</label>
        <div>
          {category.data.length ?
            _.map(category?.data, (val, key) => (
              <p onClick={() => handleClick(val?.uniqId,val?.title)} key={key} className="border-b-2  pt-2 pb-1 px-2 hover:bg-slate-100 cursor-pointer">{val?.title}</p>
            )) : null
          }
        </div>
      </div>
      <div className="space-y-2">
        <label className="text-xl font-semibold uppercase">{t("recent news")}</label>
        <div>
          {dataRecent?.data?.length ?
            _.map(dataRecent?.data, (val, key) => (
              <NavLink key={key} to={"/news/" + moment(val?.createdAt).format("YYYY-MM-DD/") + val?.slug} >
                <div className="border-b-2 pt-2 pb-1 px-2 space-y-1 hover:bg-slate-100 cursor-pointer">
                  <p className="text-lg">{val?.title}</p>
                  <p className="text-slate-500 text-sm">{formatDate(val?.createdAt, lang)}</p>
                </div>
              </NavLink>
            )) : null
          }
        </div>
      </div>
    </div>
  )
}

export default LandingNewsSidebar