import api from '../config/api'; //use for admin page
import apiLanding from '../config/apiLanding';

export const getList = (params) => {
  return apiLanding
  .get(`/gallery?lang=${params?.lang}`)
}

export const getGallery = (params) => {
  return api
  .get(`/gallery?page=${params?.pagination?.page ?? "1"}&size=${params?.pagination?.limit ?? "10"}${params?.sorting ? "&sortBy="+params?.sorting?.sortBy+"&sortType="+params?.sorting?.sortType:""}${params?.filter ? "&filters="+params?.filter:""}`)
}

export const getDetail = (params) => {
  return api
  .get(`/gallery/${params?.id ?? ''}`)
}

export const create = (params) => {
  return api
  .post(`/gallery`,params?.data)
}

export const update = (params) => {
  return api
  .put(`/gallery/update`,params?.data)
}

export const deleteList = (id) => {
  return api
  // .put(`/gallery`,id)
  .put(`/gallery/`+id)
}