import { NEWS } from "../../../services";
import { NEWS_DATA_STATE } from "../../globalActionType";

export const getNews = (params) => {
  function request() { return { type: NEWS_DATA_STATE.NEWS_DATA } }
  function success(data) { return { type: NEWS_DATA_STATE.NEWS_DATA_SUCCESS, data } }
  function failure(data) { return { type: NEWS_DATA_STATE.NEWS_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await NEWS.getNews(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getRecentNews = (params) => {
  function request() { return { type: NEWS_DATA_STATE.RECENT_NEWS_DATA } }
  function success(data) { return { type: NEWS_DATA_STATE.RECENT_NEWS_DATA_SUCCESS, data } }
  function failure(data) { return { type: NEWS_DATA_STATE.RECENT_NEWS_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await NEWS.getNews(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getDetailNewsCms = (params) => {
  function request() { return { type: NEWS_DATA_STATE.NEWS_DETAIL_DATA } }
  function success(data) { return { type: NEWS_DATA_STATE.NEWS_DETAIL_DATA_SUCCESS, data } }
  function failure(data) { return { type: NEWS_DATA_STATE.NEWS_DETAIL_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await NEWS.getDetailNewsCms(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getListNews = (params) => {
  function request() { return { type: NEWS_DATA_STATE.NEWS_DATA } }
  function success(data) { return { type: NEWS_DATA_STATE.NEWS_DATA_SUCCESS, data } }
  function failure(data) { return { type: NEWS_DATA_STATE.NEWS_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await NEWS.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getDetailNews = (params) => {
  function request() { return { type: NEWS_DATA_STATE.NEWS_DETAIL_DATA } }
  function success(data) { return { type: NEWS_DATA_STATE.NEWS_DETAIL_DATA_SUCCESS, data } }
  function failure(data) { return { type: NEWS_DATA_STATE.NEWS_DETAIL_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await NEWS.getDetail(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const resetNews = () => (dispatch) => {
  dispatch({
    type: NEWS_DATA_STATE.NEWS_DATA_RESET
  })
}

export const resetNewsDetail = () => (dispatch) => {
  // console.log("hapus")
  dispatch({
    type: NEWS_DATA_STATE.NEWS_DETAIL_DATA_RESET
  })
}