import React from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiUrl } from '../../helpers/baseUrl';
import { logout } from '../../redux/admin/login/action';
import LoadingSr from '../atoms/LoadingSr';
import Navbar from '../atoms/Navbar';

const UsersPanel = ({
  name,
  privilege,
  alt,
  img,
  isLoading
}) => {

  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useNavigate();
  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [open])

  const handleLogout = () => {
    dispatch(logout())
    history(location.state ? location.state.from.pathname : "/login");
  }

  const handleClick = () => {
    setOpen(false)
  }

  return (
    <div className={`flex ml-auto mr-5 right-0 hover:bg-gray-100 ${open ? 'bg-gray-100' : ''}`} ref={ref}>
      <div className="flex px-5 py-4 cursor-pointer" onClick={() => setOpen(!open)}>
        <span className="flex flex-row items-center">
          {isLoading ? <LoadingSr className="h-10 w-10 rounded-full"/> :
          img ? <img src={apiUrl("public/assets/img/upload/"+img)} alt={""} className="h-10 w-10 bg-gray-200 border rounded-full text-center text-3xl text-gray-400 uppercase"/>
          : 
          <div alt={alt} className="h-10 w-10 bg-gray-200 border rounded-full text-center text-3xl text-gray-400 uppercase">{alt}</div>
          }
          <span className="flex flex-col ml-2">
            <span className="truncate w-20 font-semibold tracking-wide capitalize text-black">{name}</span>
            <span className="truncate w-20 text-gray-500 text-xs capitalize">{privilege}</span>
          </span>
        </span>
      </div>
      {open && (
          <div className="fixed shadow-lg right-0 mt-2 mr-5 top-16 bg-white w-44 rounded">
            <ul>
              {/* <li className='user-menu'>
                Menu I
              </li>
              <li className='user-menu'>
                Menu II
              </li> */}
              <Navbar
                title={"Profile"}
                className={"user-menu"}
                svgPath={"M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"}
                to="/admin/profile"
                onClick={() => handleClick()}
              /> 
              <Navbar
                title={"Logout"}
                className={"user-menu"}
                svgPath={"M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"}
                svgColor="text-red-400"
                to="/admin/logout"
                onClick={handleLogout}
              /> 
            </ul>
          </div>
        )      
      }
    </div>
  )
}

export default UsersPanel
