import moment from 'moment'

export const formatDate = (date,format) => {
  if(format === "en") {
    format = "MMM Do YYYY"
  }
  else if(format === "jp") {
    format = "YYYY年 MM月 DD日"
  }
  else if(format === "id") {
    format = "DD MMM YYYY"
  }
  else{
    format = "MMM Do YYYY"
  }

  return moment(date).format(format)
}
