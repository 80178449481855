import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getLang } from '../../../redux/global/translation/action'
import Select from '../../atoms/Select'

const langOption = [
  { label: "English", value: "en" },
  { label: "Indonesia", value: "id" },
  { label: "日本語", value: "jp" }
]

const Language = ({
  value,
  onChange,
  isLoading
}) => {

  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  const { lang } = useSelector(state => state.other)

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [i18n, lang]);

  const handleChange = (event) => {
    dispatch(getLang(event.target.value))
    onChange()
  }

  return isLoading ? null : (
    <Select
      placeholder="Lang"
      type="text"
      name="lang"
      className={"text-sm py-1 px-4"}
      onChange={(value) => handleChange(value)}
      option={langOption}
      value={lang}
    />
  )
}

export default Language