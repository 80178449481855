import React from 'react'

const Footer = ({
  brandName,
  year,
  creator
}) => {
  return (
    <footer className="footer px-4 py-6">
      <div className="footer-content">
        <p className="text-sm text-gray-600 text-center">© {brandName} {year}. All rights reserved. by {creator}</p>
      </div>
    </footer>
  )
}

export default Footer
