import React from 'react'

const LoadingSr = ({
  className
}) => {
  return (
    <div className={`animate-pulse bg-slate-300 ${className}`} />
  )
}

export default LoadingSr