import React, { useEffect } from 'react'
import Img from '../../../components/atoms/Img'
import LandingSection from '../../../components/templates/landing/Section'
import { apiUrl, baseUrl } from '../../../helpers/baseUrl'
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getListService } from '../../../redux/admin/service/action';
import LoadingSr from '../../../components/atoms/LoadingSr';
import Slider from 'react-slick';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
// import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

const HeadServices = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { data, isLoading } = useSelector(state => state.service)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang,
    type: 'head'
  }

  useEffect(() => {
    dispatch(getListService(params))
  }, [lang]);

  return (
    <LandingSection className={"p-8 bg-white min-w-full flex justify-center"}>
      {isLoading ?
        <div className="flex flex-col space-y-6 w-full md:w-[70rem] justify-center md:flex-row md:space-y-0 md:space-x-6 mt-[-10rem] z-10">
          {_.map([0, 1, 2], (val, key) => (
            <div key={key} className="flex flex-col bg-white min-h-[5rem] w-full border-2 shadow-lg p-5 space-y-2 rounded-md">
              <LoadingSr className={"h-20"} />
              <LoadingSr className={"h-5"} />
              <LoadingSr className={"h-5"} />
            </div>
          ))}
        </div> :
        <Swiper
          spaceBetween={50}
          slidesPerView={3}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: false,
          }}
          // navigation={true}
          // onSlideChange={() => console.log('slide change')}
          // onSwiper={(swiper) => console.log(swiper)}
          breakpoints={{
            120: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="flex flex-col md:w-[70rem] md:flex-row md:space-y-0 mt-[-10rem] z-10"
          style={{paddingBottom:"2.5rem"}}
        >
          {_.map(data, (val, key) => (
            <SwiperSlide key={key} className="flex flex-col bg-white min-h-[5rem] w-full h-full border-2 shadow-lg p-5 space-y-2 rounded-md overflow-hidden">
              <Img src={apiUrl(`public/assets/img/service/${val?.image}`)} alt="" className="h-20" />
              <div className="text-blue-800 text-xl font-roboto-condenced md:text-[24px] leading-[26px] font-bold uppercase flex-1">{val?.title}</div>
              <div className="text-sm md:text-[15px] leading-[26px] h-[6.6rem] overflow-hidden">{val?.description}</div>
            </SwiperSlide>
          ))
          }
        </Swiper>
      }
    </LandingSection>
  )
}

export default HeadServices