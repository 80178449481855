import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import Input from '../../components/atoms/Input'
import Modal from '../../components/atoms/Modal'
import DeleteDialog from '../../components/molecules/DeleteDialog'
import Popover from '../../components/molecules/Popover'
import DataTableServer from '../../components/organisms/DataTableServer'
import Language from '../../components/organisms/options/Language'
import TypeService from '../../components/organisms/options/TypeService'
import { useToast } from '../../components/organisms/ToastProvider'
import { formatDate } from '../../helpers/convertDate'
import { remappingFilter } from '../../helpers/filterMapping'
import { getListLocation, resetLocationtDetail } from '../../redux/admin/location/action'
import { deleteList } from '../../redux/admin/location/submit/action'
import { toastRequest, toastReset } from '../../redux/global/toast/action'
import FormLocation from './form'
import MapsPreview from './preview'

const Location = () => {
  const dispatch = useDispatch()

  const { data, isLoading } = useSelector(state => state.location)
  const dataToast = useSelector(state => state.toast)
  const submit = useSelector(state => state.locationSubmit)
  const others = useSelector(state => state.others)
  const { lang } = useSelector(state => state.other)

  const [fetched, setFetched] = useState(false)
  const { addToast } = useToast()
  const [isOpen, setIsOpen] = useState(false)
  const [modalName, setModalName] = useState(null)
  const [editData, setEditData] = useState(null)
  const [deleteId, setDeleteId] = useState(null)

  const [pagination, setPagination] = useState({
    page: 1, limit: 10
  })
  const [sorting, setSorting] = useState({
    sortBy: "", sortType: "" //sortby name, sorttype asc or desc
  })
  const [filter, setFilter] = useState([])

  const params = {
    lang: lang,
    pagination: pagination,
    sorting: sorting,
    filter: filter.join(",")
  }

  useEffect(() => {
    dispatch(getListLocation(params))
  }, [])

  useEffect(() => {
    if (fetched) {
      dispatch(getListLocation(params))
      setFetched(false)
    }
  }, [fetched])

  useEffect(() => {
    if (!isOpen) {
      dispatch(resetLocationtDetail())
      setEditData(null)
    }
  }, [isOpen]);

  useEffect(() => { // global toast 
    if (dataToast.toast && submit.alert) {
      addToast({
        type: submit.alert,
        message: submit.message,
        // time:5000
      })
      dispatch(toastReset())
      if (submit.alert === 'success') {
        setFetched(true)
        setIsOpen(false)
      }
    }

  }, [dataToast.toast, submit.alert]); //eslint-disable-line

  const onClose = () => {
    setIsOpen(false)
    // dispatch(resetRole())
  };

  const onSubmit = () => {
    dispatch(toastRequest());
  };

  const onCreate = (event) => {
    setModalName(event.target.name)
    setIsOpen(true)
  }

  const onClick = (row) => {
    setEditData(row)
    setModalName("popover")
    setIsOpen(true)
  }

  const onEdit = async (row) => {
    setModalName("edit")
    setIsOpen(true)
  }

  const onDelete = async (id) => {
    setModalName("delete")
    setDeleteId(id)
    setIsOpen(true)
  }

  const onSearch = (event) => {
    // const filterName = event.target.value ? `${event.target.name}~like~${event.target.value}` : ""
    eventSearch(event.target.value, event.target.name, filter)
  }

  const handleLimitChange = (limit, page) => {
    setPagination({
      ...pagination,
      limit: limit
    })
    setFetched(true)
  };

  const handlePageChange = (page) => {
    setPagination({
      ...pagination,
      // page: page + 1
      page: page
    })
    setFetched(true);
  };

  const handleSort = (column, sortDirection) => {
    setSorting({
      ...sorting,
      sortBy: column?.field,
      sortType: sortDirection
    })
    setFetched(true);
  };

  let handleDelete = () => {
    const multiId = {
      id: deleteId
    }
    dispatch(deleteList(multiId))
    dispatch(toastRequest());
  }

  const eventSearch = useMemo(
    () => _.debounce((value, name, filter) => {
      remappingFilter(setFilter, filter, value, name, "like")
      setFetched(true)
    }, 1000),
    []
  )

  const onSelect = (event) => {
    // const filterSelect = event.target.value ? `${event.target.name}~eq~${event.target.value}` : ""
    remappingFilter(setFilter, filter, event.target.value, event.target.name, "eq")
    setFetched(true);
  }

  const handleLangChange = () => {
    setFetched(true)
  }

  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
      omit: true
    },
    {
      name: 'Sequence',
      selector: row => row.sequence,
      sortable: true,
      // width: '100px',
      field: "sequence"
    },
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
      field: "title"
    },
    {
      name: 'Lng',
      selector: row => row.lng,
      sortable: true,
      field: "lng",
      // width: "80px"
    },
    {
      name: 'Lat',
      selector: row => row.lat,
      sortable: true,
      field: "lat",
      // width: "80px"
    },
    {
      name: 'Created At',
      selector: row => formatDate(row.createdAt, "en"),
      sortable: true,
      // right: true,
      field: "createdAt"
    },
    {
      name: 'Updated At',
      selector: row => formatDate(row.updatedAt, "en"),
      sortable: true,
      field: "updatedAt"
    },
  ];

  return (
    <>
      <div className='bg-white p-5 shadow-md mb-5'>
        <MapsPreview
          data={data}
          isLoading={isLoading}
          className={"h-[20rem]"}
          zoom={7}
        />
      </div>
      <div className='bg-white p-5 shadow-md mb-5'>
        <DataTableServer
          title={"Service"}
          columns={columns}
          data={data?.data}
          total={data?.pagination?.totalItems}
          loading={isLoading}
          pageChange={handlePageChange}
          limitChange={handleLimitChange}
          sortChange={handleSort}
          actionsButton={
            <div className="flex flex-col-reverse space-y-2 space-y-reverse md:flex-row md:space-x-4 md:space-y-0">
              <Input
                placeholder="Search"
                type="text"
                name="title"
                className={"text-sm py-1 px-4"}
                onChange={(event) => onSearch(event)}
              />
              <Language isLoading={submit?.isLoading} value={lang} onChange={() => handleLangChange()} />
              <Button
                buttonStyle={"btn-primary text-sm p-1"}
                name={"create"}
                onClick={(event) => onCreate(event)}
              >
                Create
              </Button>
            </div>
          }
          uniqId={true}
          onRowClick={(row) => onClick(row)}
          deleted={(id) => onDelete(id)}
          toogleSelectableRows={fetched}
        />
      </div>
      <Modal
        isOpen={isOpen && (modalName === "create" || modalName === "edit")}
        onClose={() => onClose()}
        title={isOpen ? `${modalName} Location` : 'Title'}
      >
        <FormLocation
          row={editData}
          onClose={() => onClose()}
          onSubmit={() => onSubmit()}
        />
      </Modal>
      <Modal title={"Delete Service"}
        isOpen={isOpen && modalName === "delete"}
        onClose={() => onClose()}
      >
        <DeleteDialog
          id={deleteId}
          isLoading={submit.isLoading}
          deleted={() => handleDelete()}
          close={() => onClose()}
        />
      </Modal>
      <Popover
        isOpen={isOpen && modalName === "popover"}
        onClose={() => onClose()}
        coord={others?.coordinate}
        onView={() => onClose()}
        onEdit={(row) => onEdit(row)}
      />
    </>
  )
}

export default Location