import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Img from "../../../components/atoms/Img";
import LoadingSr from "../../../components/atoms/LoadingSr";
import LandingSection from "../../../components/templates/landing/Section";
import { getAbout } from "../../../redux/admin/about/action";
import HeroContent from "../section/HeroContent";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/pagination';
import { apiUrl, baseUrl } from "../../../helpers/baseUrl";
import Input from "../../../components/atoms/Input";
import Button from "../../../components/atoms/Button";
import Partners from "../section/Partners";
import { motion } from "framer-motion";
import { globalResetSubmit, globalSubmit } from "../../../redux/global/submit/action";
import { SUBSCRIBE } from "../../../services";

const LandingAbout = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { data, isLoading } = useSelector(state => state.about)
  const { lang } = useSelector(state => state.other)
  const submit = useSelector(state => state.globalSubmit)

  const [inputs, setInputs] = useState({
    email: null,
  })

  const params = {
    lang
  }

  useEffect(() => {
    dispatch(getAbout(params))
    dispatch(globalResetSubmit())
  }, [lang]);

  const onChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value
    })
  }

  const onSubmit = () => {
    dispatch(globalSubmit({
      service: SUBSCRIBE, 
      data: inputs 
    }))
  };

  return (
    <div className="bg-white">
      <HeroContent
        title={"about us"}
        to={"about-us"}
      />
      <LandingSection>
        <div className="w-content flex flex-col space-x-0 space-y-4 md:space-y-0 md:flex-row-reverse md:space-x-4 md:space-x-reverse py-10 items-center text-left min-h-[15rem]">
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ type: "spring", duration: 1 }}
            viewport={{ once: true }}
            className="bg-blue-300 flex justify-center w-full md:w-3/4"
          >
            <Img src={apiUrl(`public/assets/img/gallery/${data?.image}`)} alt={data?.id} className="border-2 w-full" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ type: "spring", duration: 1 }}
            viewport={{ once: true }}
            className="space-y-4 w-full"
          >
            <p className="title-header">{t("about us")}</p>
            <p className="whitespace-pre-line description-text text-justify">{data?.description}</p>
          </motion.div>
        </div>
      </LandingSection>
      <LandingSection className={"bg-[#f5f7f9]"}>
        <div className="w-content flex flex-col space-x-0 space-y-4 md:space-y-0 md:flex-row md:space-x-4 py-10 items-start text-left min-h-[15rem]">
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ type: "spring", duration: 1 }}
            viewport={{ once: true }}
            className="space-y-4"
          >
            <p className="title-header">{t("company history")}</p>
            <p className="whitespace-pre-line description-text">{data?.history}</p>
          </motion.div>
          {/* <div className="space-y-4">
            <p className="capitalize font-semibold text-2xl">{t("We Are Growing Faster")}</p>
            <p>{data?.description}</p>
          </div> */}
        </div>
      </LandingSection>
      <LandingSection>
        <div className="w-content py-10 flex flex-col space-y-4 items-start text-left min-h-[15rem]">
          <motion.p
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 2 }}
            viewport={{ once: true }}
            className="title-header w-full text-center"
          >{t("company overview")}</motion.p>
          <table className="custom-table w-full">
            <motion.tbody
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", duration: 1 }}
              viewport={{ once: true }}
            >
              <tr>
                <td className="w-1/5 bg-slate-100 capitalize">{t("company name")}</td>
                <td>{data?.companyname}</td>
              </tr>
              <tr>
                <td className="w-1/5 bg-slate-100 capitalize">{t("business")}</td>
                <td>{data?.business}</td>
              </tr>
              <tr>
                <td className="w-1/5 bg-slate-100 capitalize">{t("head office")}</td>
                <td>{data?.headoffice}</td>
              </tr>
              <tr>
                <td className="w-1/5 bg-slate-100 capitalize">{t("president")}</td>
                <td>{data?.president}</td>
              </tr>
              <tr>
                <td className="w-1/5 bg-slate-100 capitalize">{t("establishment")}</td>
                <td>{data?.establisment}</td>
              </tr>
              <tr>
                <td className="w-1/5 bg-slate-100 capitalize">{t("numOfEmployee")}</td>
                <td>{data?.numOfEmployee ?? ' - '}</td>
              </tr>
              <tr>
                <td className="w-1/5 bg-slate-100 capitalize">{t("shareholders")}</td>
                <td>{data?.shareholders}</td>
              </tr>
              <tr>
                <td className="w-1/5 bg-slate-100 capitalize">{t("operating base")}</td>
                <td>{data?.operatingbase}</td>
              </tr>
              <tr>
                <td className="w-1/5 bg-slate-100 capitalize">{t("isoGdp")}</td>
                <td>{data?.isoGdp}</td>
              </tr>
            </motion.tbody>
          </table>
        </div>
      </LandingSection>
      <LandingSection className={"bg-[#f5f7f9]"}>
        <div className="w-content py-10 flex flex-col space-y-4 items-start text-left min-h-[15rem]">
          {/* <p className="capitalize w-full font-semibold text-2xl text-center">{t("PT.ILC LOGISTICS INDONESIA Organaization Chart")}</p> */}
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 2 }}
            className="uppercase w-full text-center"
          >
            <label>PT.ILC LOGISTICS INDONESIA</label>
            <div className="title-header capitalize">{t("organization chart")}</div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, }}
            whileInView={{ opacity: 1, }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 1 }}
            className="flex w-full justify-center"
          >
            <Img src={apiUrl(`public/assets/img/gallery/${data?.imageChart}`)} alt={data?.id} className="border-0" />
          </motion.div>
        </div>
      </LandingSection>
      {/* <LandingSection>
        <div className="flex flex-col md:flex-row items-start text-left">
          <div className="w-full md:w-1/3 flex justify-center bg-red-300">
            <Img src={""} className="" />
          </div>
          <div className="p-4 space-y-4 md:w-[calc(15%+40rem)]">
            <label className="font-semibold text-2xl">TECHNOLOGY AND ENGINEERING</label>
            <p className="font-semibold">Credibly orchestrate virtual resources and dynamic expertise. Interactively maintain leveraged users.</p>
            <p>Competently deliver e-business users via economically sound supply chains. Assertively reconceptualize error-free e-markets whereas focused schemas. Phosfluorescently embrace process-centric niche markets before orthogonal platforms. </p>
            <p>Lorem Ipsum is simply text of the. Lorem Ipsum is simply text of the stry stext of the stry simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply text of the.</p>
          </div>
        </div>
      </LandingSection>
      <LandingSection className={"p-8 space-y-4"}>
        <label className="capitalize font-semibold text-2xl">{t("our team")}</label>
        <div className="bg-white min-w-full flex justify-center">
          {isLoading ?
            <div className="flex flex-col space-y-6 w-full md:w-[70rem] justify-center md:flex-row md:space-y-0 md:space-x-6 z-10">
              {_.map([0, 1, 2], (val, key) => (
                <div key={key} className="flex flex-col bg-white min-h-[5rem] w-full border-2 shadow-lg p-5 space-y-2 rounded-md">
                  <LoadingSr className={"h-52"} />
                  <LoadingSr className={"h-5"} />
                  <LoadingSr className={"h-5"} />
                </div>
              ))}
            </div> :
            <Swiper
              spaceBetween={50}
              slidesPerView={3}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: false,
              }}
              // navigation={true}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
              breakpoints={{
                120: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="flex flex-col md:w-[70rem] md:flex-row md:space-y-0 z-10"
              style={{paddingBottom:"2.5rem"}}
            >
              {_.map([0, 1, 2, 3], (val, key) => (
                <SwiperSlide key={key} className="flex flex-col bg-white min-h-[5rem] w-full h-full border-2 shadow-lg p-5 space-y-2 rounded-md overflow-hidden">
                  <Img src={baseUrl(`/assets/svg/${val?.image}`)} alt="" className=" h-52" />
                  <div className="text-blue-800 text-xl uppercase flex-1">{val?.name ?? "Name"}</div>
                  <div className="text-sm">{val?.job ?? "Job"}</div>
                </SwiperSlide>
              ))
              }
            </Swiper>
          }
        </div>
      </LandingSection> */}
      {/* <Partners hiddenTitle={true} /> */}
      <LandingSection className={"bg-blue-700"}>
        <div className="w-content flex flex-col space-x-0 space-y-4 md:space-y-0 md:flex-row md:space-x-4 py-10 items-center justify-center md:justify-start">
          <label className="text-2xl w-full font-semibold text-white">
            {t("subscribe to our corporate newsletter")}
          </label>
          <div className="w-full">
            <div className="flex space-x-4 w-full justify-center">
              <Input
                className="form-box h-12 w-1/2"
                placeholder={t("enter your email") + "..."}
                name="email"
                onChange={(event) => onChange(event)}
              />
              <Button
                buttonStyle="uppercase bg-white py-3 px-5 rounded"
                onClick={() => onSubmit()}
                disabled={isLoading}
              >
                <div className="flex">
                  {submit?.isLoading &&
                    <svg className="animate-spin h-5 w-5 mr-3 text-blue-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  }
                  <div> {t("subscribe")}</div>
                </div>
              </Button>
            </div>
            {
              submit.isSuccess || submit.isError ?
                <p className="p-2 text-white">{submit.message}</p>
                : null
            }
          </div>
        </div>
      </LandingSection>
    </div>
  );
};

export default LandingAbout;
