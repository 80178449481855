import { TOAST_STATE } from "../../globalActionType";

const initialState = {
  toast: false,
};

const toast = (state = initialState, action) => {
  switch (action.type) {
    case TOAST_STATE.TOAST_OPEN:
      return {
        ...state,
        toast: true,
      };
    case TOAST_STATE.TOAST_CLOSE:
      return {
        ...state,
        toast: false,
      };
    default:
      return state;
  }
};

export default toast
