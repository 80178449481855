import { ROLE_STATE } from "../../globalActionType";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: [],
  trigger: '',
  message: '',
}

const role = (state = initialState, action) => {
  switch (action.type) {
    case ROLE_STATE.ROLE_DATA:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case ROLE_STATE.ROLE_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case ROLE_STATE.ROLE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        data: action.data.data,
      };
    case ROLE_STATE.ROLE_DATA_RESET:
      return {
        state
      };
    default:
      return state;
  }
}

export default role;