import { DATA_SUBMIT_STATE } from "../../globalActionType";

export const globalSubmit = (params) => {
  function request() { return { type: DATA_SUBMIT_STATE.DATA_SUBMIT } }
  function success(data) { return { type: DATA_SUBMIT_STATE.DATA_SUBMIT_SUCCESS, data } }
  function failure(data) { return { type: DATA_SUBMIT_STATE.DATA_SUBMIT_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {      
      // const response = await params?.service.update(params)
      const response = await params?.service.submit(params)
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data))
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
  }
};

export const globalResetSubmit = () => (dispatch) => {
  dispatch({
    type: DATA_SUBMIT_STATE.DATA_SUBMIT_RESET
  })
}