import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import Input from '../../components/atoms/Input'
import Label from '../../components/atoms/Label'
import Loading from '../../components/atoms/Loading'
import Textarea from '../../components/atoms/Textarea'
import { useToast } from '../../components/organisms/ToastProvider'
import { getDetailContact } from '../../redux/admin/contact/action'
import { submit } from '../../redux/admin/contact/submit/action'
import { toastRequest, toastReset } from '../../redux/global/toast/action'

const Contact = () => {

  const dispatch = useDispatch()
  // const [fetched, setFetched] = useState(false)
  const { addToast } = useToast()
  const dataToast = useSelector(state => state.toast)
  const [fetched, setFetched] = useState()

  const { dataDetail, isLoadingDetail, isSuccessDetail } = useSelector(state => state.contact)
  const { alert, message, isLoading } = useSelector(state => state.contactSubmit)

  const [inputs, setInputs] = useState({
    id: dataDetail?.id ?? '',
    email: dataDetail?.email ?? '',
    phone: dataDetail?.phone ?? '',
    fax: dataDetail?.fax ?? '',
    address: dataDetail?.address ?? '',
  })

  const params = {
    // id: dataDetail?.id,
    data: inputs,
    type: dataDetail?.id ? 'edit' : 'create'
  }

  useEffect(() => {
    dispatch(getDetailContact(params))
  }, []);

  useEffect(() => {
    if (fetched) {
      dispatch(getDetailContact(params))
      setFetched(false)
    }
  }, [fetched]);

  useEffect(() => {
    if (isSuccessDetail) {
      setInputs(dataDetail)
    }
  }, [isSuccessDetail]);

  useEffect(() => { // global toast 
    if (dataToast.toast && alert) {
      addToast({
        type: alert,
        message: message,
        // time:5000
      })
      dispatch(toastReset())
      if (alert === 'success') {
        setFetched(true)
      }
    }

  }, [dataToast.toast, alert]); //eslint-disable-line

  const handleChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = () => {
    dispatch(submit(params))
    dispatch(toastRequest());
  }

  return !isSuccessDetail && dataDetail.id ? <Loading /> : (
    <div className='bg-white p-5 shadow-md mb-5 w-fit'>
      <label className="text-xl">Company Profile</label>
      <hr className="my-5" />
      <form className="flex flex-col px-4">
        <div className="flex flex-col space-y-6 md:w-[35rem]">
          <div className="form-group">
            <Label className="md:w-1/3 md:text-left">Address</Label>
            <Textarea
              name={"address"}
              className="w-full md:w-2/3 form-box"
              placeholder={"Address"}
              onChange={(event) => handleChange(event)}
              rows={3}
              defaultValue={inputs.address}
            >
            </Textarea>
          </div>
          <div className="form-group">
            <Label className="md:w-1/3 md:text-left">Email</Label>
            <Input
              className="w-full md:w-2/3 form-box"
              name={"email"}
              placeholder={"Email"}
              onChange={(event) => handleChange(event)}
              value={inputs.email}
            />
          </div>
          <div className="form-group">
            <Label className="md:w-1/3 md:text-left">Phone Number</Label>
            <Input
              className="w-full md:w-2/3 form-box"
              name={"phone"}
              placeholder={"Phone Number"}
              onChange={(event) => handleChange(event)}
              value={inputs.phone}
            />
          </div>
          <div className="form-group">
            <Label className="md:w-1/3 md:text-left">Fax</Label>
            <Input
              className="w-full md:w-2/3 form-box"
              name={"fax"}
              placeholder={"Fax"}
              onChange={(event) => handleChange(event)}
              value={inputs.fax}
            />
          </div>
          {isLoading && <Loading className={"mt-4"} />}
          <div className='text-right pt-5'>
            <Button type={"button"}
              buttonStyle={"btn-primary text-sm p-1"}
              onClick={() => handleSubmit()}
              disabled={isLoading}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Contact