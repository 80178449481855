import api from '../config/api';
import apiLanding from '../config/apiLanding';

export const getNews = (params) => {
  return apiLanding
  .get(`/news?lang=${params?.lang ?? ''}&page=${params?.pagination?.page ?? "1"}&size=${params?.pagination?.limit ?? "10"}${params?.sorting ? "&sortBy="+params?.sorting?.sortBy+"&sortType="+params?.sorting?.sortType:""}${params?.filter ? "&filters="+params?.filter:""}`)
}

export const getDetailNewsCms = (params) => {
  return apiLanding
  .get(`/detail-news?lang=${params?.lang ?? ''}&slug=${params?.slug ?? ''}`)
}

export const getList = (params) => {
  return api
  .get(`/news?lang=${params?.lang ?? ''}&page=${params?.pagination?.page ?? "1"}&size=${params?.pagination?.limit ?? "10"}${params?.sorting ? "&sortBy="+params?.sorting?.sortBy+"&sortType="+params?.sorting?.sortType:""}${params?.filter ? "&filters="+params?.filter:""}`)
}

export const getDetail = (params) => {
  return api
  .get(`/news/${params?.id ?? ''}`)
}

export const create = (params) => {
  return api
  .post(`/news`,params?.data)
}

export const update = (params) => {
  return api
  .put(`/news/update`,params?.data)
}

export const deleteList = (id) => {
  return api
  .put(`/news`,id)
}