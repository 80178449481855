import './App.css';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Routers from './shared/routers';
import LandingRouters from './shared/landing/routers';
import LandingNewsRouters from './shared/landing/news/routers';
import Login from './pages/login';
import Main from './components/templates/Main';
import ToastProvider from './components/organisms/ToastProvider';
import Register from './pages/register';
import VerifyEmail from './pages/verify-email';
import NotFound from './components/templates/NotFound';
import ConfirmRegistration from './pages/confirm-registration';
import LandingMain from './components/templates/landing/Main';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingNews from './pages/landing/news';
import LandingNewsDetail from './pages/landing/news/detail';
import LandingNewsList from './pages/landing/news/list';
import ScrollToTop from './components/organisms/ScrollToTop';

function App() {
  const { token, email, isActive, isVerifyEmail } = useSelector(state => state.dataStore);

  function PrivateOutlet() {
    return token && isActive ? <Outlet /> : <Navigate to="/auth/login" />;
  }
  function AuthOutlet() {
    return token && isActive ? <Navigate to="/admin/" /> : <Outlet />
  }
  function AuthVerifyOutlet() {
    return email && !isVerifyEmail ? <Outlet /> : <Navigate to="/auth/login" />
  }
  function AuthValidateOutlet() {
    return token ? <Navigate to="/admin/" /> : <Outlet />
  }
  function LandingOutlet() {
    return <Outlet />
  }

  return (
    <ScrollToTop>
      <Routes >
        {/* <Route path='admin/*' element={<Main><NotFound className="h-full"/></Main>}/> */}
        <Route path='*' element={<NotFound />} />
        <Route path='auth' element={<AuthOutlet />} >
          <Route path='login' element={<ToastProvider><Login /></ToastProvider>} />
          <Route path='register' element={<ToastProvider><Register /></ToastProvider>} />
        </Route>
        <Route path='verify' element={<AuthVerifyOutlet />}>
          <Route path='email/:email' element={<ToastProvider><VerifyEmail /></ToastProvider>} />
        </Route>
        <Route path='validate' element={<AuthValidateOutlet />}>
          <Route path='confirm-registration/:token' element={<ToastProvider><ConfirmRegistration /></ToastProvider>} />
        </Route>
        <Route path='admin' element={<PrivateOutlet />} >
          {
            Routers.map((route, key) => (
              <Route
                key={key}
                // exact
                path={route.path}
                element={
                  <ToastProvider>
                    <Main>
                      <route.component className={route.component === NotFound ? "h-full" : ""} />
                    </Main>
                  </ToastProvider>
                }
              />
            ))
          }
        </Route>
        <Route path='/' element={<LandingOutlet />} >
          {
            LandingRouters.map((route, key) => (
              route.component !== LandingNews ?
                <Route
                  key={key}
                  path={route.path}
                  element={
                    // <ToastProvider>
                    <LandingMain>
                      <route.component>
                        {/* { 
                        route.component === LandingNews && 
                        LandingNewsRouters.map((routeNews, keyNews) => (
                          <routeNews.component key={keyNews} />
                        ))
                        } */}
                      </route.component>
                    </LandingMain>
                    // </ToastProvider>
                  }
                />
                :
                LandingNewsRouters.map((routeNews, keyNews) => (
                  <Route
                    key={keyNews}
                    path={routeNews.path}
                    element={
                      // <ToastProvider>
                      <LandingMain>
                        <LandingNews>
                          <routeNews.component key={keyNews} />
                        </LandingNews>
                      </LandingMain>
                      // </ToastProvider>
                    }
                  />
                ))
            ))
          }
        </Route>
      </Routes>
    </ScrollToTop>
  );
}

export default App;
