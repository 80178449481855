import api from '../config/api'; //use for admin page
import apiLanding from '../config/apiLanding';

export const getList = (params) => {
  return apiLanding
  .get(`/our-group?lang=${params?.lang ?? ''}`)
}

export const getOurGroup = (params) => {
  return api
  .get(`/our-group?page=${params?.pagination?.page ?? "1"}&size=${params?.pagination?.limit ?? "10"}${params?.sorting ? "&sortBy="+params?.sorting?.sortBy+"&sortType="+params?.sorting?.sortType:""}${params?.filter ? "&filters="+params?.filter:""}`)
}

export const getDetail = (params) => {
  return api
  .get(`/our-group/${params?.id ?? ''}`)
}

export const create = (params) => {
  return api
  .post(`/our-group`,params?.data)
}

export const update = (params) => {
  return api
  .put(`/our-group/update`,params?.data)
}

export const deleteList = (id) => {
  return api
  // .put(`/our-group`,id)
  .put(`/our-group/`+id)
}