import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/atoms/Loading';
import { reset } from '../../redux/admin/login/action';
import { sendMailToken } from '../../redux/admin/register/action';

const VerifyEmail = () => {
  const dispatch = useDispatch()

  const {
    token,
    email
  } = useSelector(state => state.dataStore)

  const {
    isLoadingSendMail
  } = useSelector(state => state.register)

  const params = {
    url: window.location.origin+"/validate/confirm-registration/",
    email:email
  }

  useEffect(() => {   
    if(token) {
      dispatch(reset())   
    }
  }, [token]); //eslint-disable-line

  const handleSendMail = () =>{  
    dispatch(sendMailToken(params))
  }
  return (
    <div className='min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-300'>
      <div className='max-w-md w-full  bg-white p-5 rounded-xl'>
        {isLoadingSendMail ? <Loading/> : null}
        {email ? 
        <>
          <div className='text-xl font-semibold text-gray-900 text-center'>Welcome, please verify your email address before accessing our site</div>
          <div className='text-md mt-2 text-gray-900 text-center'>didnt receive link? 
            <span onClick={() => handleSendMail()} className="hover:text-blue-700 text-blue-400 cursor-pointer">resend</span>
          </div>
        </> :
          <div className='text-xl font-semibold text-gray-900 text-center'>Not Found</div>
        }
      </div>
    </div>
  )
}

export default VerifyEmail