import API from '../config/api';

export const login = (params) => {
  return API
  .post("/auth/login",params)
}
export const register = (params) => {
  return API
  .post("/auth/register",params)
}
export const sendMail = (params) => {
  return API
  .post("/mail/register",params)
}
export const confirmRegistration = (params) => {
  return API
  .get("/auth/confirm-registration/"+params?.token)
}