import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import Input from '../../components/atoms/Input'
import Label from '../../components/atoms/Label'
import Loading from '../../components/atoms/Loading'
import Textarea from '../../components/atoms/Textarea'
import { submit } from '../../redux/admin/location/submit/action'
import { getDetailLocation } from '../../redux/admin/location/action'
import { getLang } from '../../redux/global/translation/action'

const FormLocation = ({
  row,
  onClose,
  onSubmit
}) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const { dataDetail, isLoadingDetail, isSuccessDetail } = useSelector(state => state.location)
  const { alert, message, isLoading } = useSelector(state => state.locationSubmit)

  const [activeTab, setActiveTab] = useState('en')

  const [inputs, setInputs] = useState({
    // name: row?.name ?? '',
    lng: row?.lng ?? '',
    lat: row?.lat ?? '',
    sequence: row?.sequence ?? '',
    en: {
      title: row?.title ?? '',
      description: row?.description ?? ''
    },
    jp: {
      title: row?.title ?? '',
      description: row?.description ?? ''
    },
    id: {
      title: row?.title ?? '',
      description: row?.description ?? ''
    }
  })

  const params = {
    id: row?.uniqId,
    data: inputs,
    type: row ? 'edit' : 'create'
  }

  useEffect(() => {
    i18n.changeLanguage(activeTab)
  }, [i18n, activeTab]);

  useEffect(() => {
    if (row?.id) {
      dispatch(getDetailLocation(params))
    }
  }, [row?.id]);

  useEffect(() => {
    if (isSuccessDetail) {
      setInputs(dataDetail)
    }
  }, [isSuccessDetail]);

  const onChangeTab = (lang) => {
    setActiveTab(lang)
    dispatch(getLang(lang))
  }

  const handleChange = (event) => {
    if (event.target.name !== "lng" && event.target.name !== "lat" && event.target.name !== "sequence") {
      setInputs({
        ...inputs,
        [activeTab]: {
          ...inputs[activeTab],
          [event.target.name]: event.target.value
        }
      })
    } else {
      setInputs({
        ...inputs,
        [event.target.name]: event.target.value
      })
    }
  }

  const handleSubmit = () => {
    // console.log(params)
    dispatch(submit(params))
    onSubmit()
  }

  const handleClose = () => {
    // i18n.changeLanguage('en')
    onClose()
  }

  return !isSuccessDetail && row ? <Loading /> : (
    <form className="flex flex-col px-8">
      <div className="flex flex-col space-y-6 md:w-[35rem]">
        {/* <div className="form-group">
          <Label className="md:w-1/3 md:text-left">Location Name</Label>
          <Input
            className="w-full md:w-2/3 form-box"
            type="text"
            name={"title"}
            placeholder={"title"}
            onChange={(event) => handleChange(event)}
            value={inputs.title}
          />
        </div> */}
        <div className="form-group">
          <Label className="md:w-1/3 md:text-left">Longitude</Label>
          <Input
            className="w-full md:w-2/3 form-box"
            type="text"
            // pattern="[0-9]*"
            name={"lng"}
            placeholder={"ex :  -7.0000"}
            onChange={(event) => handleChange(event)}
            value={inputs.lng}
          />
        </div>
        <div className="form-group">
          <Label className="md:w-1/3 md:text-left">Latitude</Label>
          <Input
            className="w-full md:w-2/3 form-box"
            type="text"
            name={"lat"}
            placeholder={"ex : 110.000"}
            onChange={(event) => handleChange(event)}
            value={inputs.lat}
          />
        </div>
        <div className="form-group flex">
          <Label className="md:w-1/3 md:text-left">Sequence</Label>
          <div className='flex flex-col w-full md:w-2/3'>
            <Input
              className="form-box"
              type="text"
              name={"sequence"}
              placeholder={"ex : 1"}
              onChange={(event) => handleChange(event)}
              value={inputs.sequence}
            />
            <small className='text-[10px] mt-2 text-indigo-500'>Start from 1 until ...</small>
          </div>
        </div>
        <div className="form-group border-b-2 pl-4">
          <div className="flex space-x-4 text-slate-400 font-semibold -mb-[0.12rem]">
            <div onClick={() => onChangeTab('en')} className={activeTab === "en" ? "active-tab" : "tab"}>English</div>
            <div onClick={() => onChangeTab('jp')} className={activeTab === "jp" ? "active-tab" : "tab"}>Japan</div>
            <div onClick={() => onChangeTab('id')} className={activeTab === "id" ? "active-tab" : "tab"}>Indonesia</div>
          </div>
        </div>
        <div className="form-group">
          <Label className="md:w-1/3 md:text-left">Location Name</Label>
          <Input
            className="w-full md:w-2/3 form-box"
            type="text"
            name={"title"}
            placeholder={t("location name")}
            onChange={(event) => handleChange(event)}
            value={inputs[activeTab].title}
          />
        </div>
        <div className="form-group">
          <Label className="md:w-1/3 md:text-left">description</Label>
          <Textarea
            name={"description"}
            className="w-full md:w-2/3 form-box"
            placeholder={t("description")}
            onChange={(event) => handleChange(event)}
            rows={5}
            value={inputs[activeTab].description}
          >
          </Textarea>
        </div>
      </div>
      {isLoading && <Loading className={"mt-4"} />}
      <div className='text-right pt-5'>
        <Button type={"button"}
          buttonStyle={"btn-primary text-sm p-1 mr-4"}
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          Submit
        </Button>
        <Button type={"button"}
          buttonStyle={"btn-secondary text-sm p-1"}
          onClick={() => handleClose()}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}

export default FormLocation