import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Img from '../../../components/atoms/Img'
import LoadingSr from '../../../components/atoms/LoadingSr'
import LandingSection from '../../../components/templates/landing/Section'
import { apiUrl, baseUrl } from '../../../helpers/baseUrl'
import { getOurGroup } from '../../../redux/admin/our-group/action'
import { motion } from "framer-motion"

const OurGroups = ({
  hiddenTitle
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { data, isLoading } = useSelector(state => state.ourGroup)

  useEffect(() => {
    dispatch(getOurGroup())
  }, []);

  return (
    <LandingSection className={"w-full"}>
      <div className="p-10 space-y-10">
        {!hiddenTitle ? <label className="text-2xl font-semibold uppercase">{t("our groups")}</label> : null}
        <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4 items-center md:justify-center">
          {isLoading ?
            _.map([0, 1, 2, 3], (val, key) => (
              <div key={key} className="min-h-[4rem] w-full md:w-1/6 flex justify-center h-20">
                <LoadingSr className="h-full w-full" />
              </div>
            ))
            :
            _.map(data, (val, key) => (
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 0.5, delay: (1/8 * key) }}
                key={key}
                className="min-h-[4rem] w-full md:w-1/6 border-0 flex justify-center h-20"
              >
                <Img src={apiUrl(`public/assets/img/ourgroup/${val?.image}`)} alt="" className="h-full object-cover" />
              </motion.div>
            ))
          }
        </div>
      </div>
    </LandingSection>
  )
}

export default OurGroups