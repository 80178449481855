import { PRIVILEGE } from "../../../services";
import { PRIVILEGE_DATA_STATE } from "../../globalActionType";

export const getPrivilege = (params) => {
  function request() { return { type: PRIVILEGE_DATA_STATE.PRIVILEGE_DATA } }
  function success(data) { return { type: PRIVILEGE_DATA_STATE.PRIVILEGE_DATA_SUCCESS, data } }
  function failure(data) { return { type: PRIVILEGE_DATA_STATE.PRIVILEGE_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await PRIVILEGE.getPrivilege(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const resetPrivilege = () => (dispatch) => {
  dispatch({
    type: PRIVILEGE_DATA_STATE.PRIVILEGE_DATA_RESET
  })
}