import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/admin/login/action';
import Button from '../../components/atoms/Button';
import Loading from '../../components/atoms/Loading';
import { useToast } from '../../components/organisms/ToastProvider';
import { toastRequest, toastReset } from '../../redux/global/toast/action';
import Label from '../../components/atoms/Label';
import Input from '../../components/atoms/Input';
import { baseUrl } from '../../helpers/baseUrl';

const Login = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const {
    token,
    form,
    email,
    isActive,
    isVerifyEmail,
    isLoading,
    isError,
    alert,
    isSuccess,
    message
  } = useSelector(state => state.dataStore);
  const dataToast = useSelector(state => state.toast);

  const [inputs, setInputs] = useState({
    username: form?.username ?? '',
    password: form?.password ?? '',
  });

  const { addToast } = useToast()

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  let handleSubmit = () => {
    if (inputs) {
      const req = {
        username: inputs?.username,
        password: inputs?.password
      };
      dispatch(login(req));
      dispatch(toastRequest());
    }
  }
  useEffect(() => {
    if (isSuccess && token) {
      isActive && isVerifyEmail && history(location.state ? location.state.from.pathname : "/admin/");
      !isVerifyEmail && history(location.state ? location.state.from.pathname : "/verify/email/" + email);
    }
  }, [isSuccess, token]); //eslint-disable-line

  useEffect(() => {
    if ((isError || isSuccess) && dataToast.toast) {
      addToast({
        type: alert,
        message: message,
        // time:5000
      })
      dispatch(toastReset())//reset
    }
  }, [alert]); //eslint-disable-line

  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-300">
      <div className="max-w-md w-full  bg-white p-2 rounded-lg">
        {isLoading && <Loading />}
        <div className="p-8">
          <div className="text-center mb-10">
            <Link to={'/'} className="flex flex-row space-x-4 items-center justify-center">
              <img src={baseUrl("/assets/svg/logo.svg")} alt="ilc" className="md:h-16" />
              <div className="text-xl md:text-2xl font-bold text-[#007BC4] text-left w-[10rem] md:w-[12rem]">PT. ILC LOGISTICS INDONESIA</div>
            </Link>
            {/* <h2 className='text-xl font-bold text-gray-900 '>Sign in to your account</h2> */}
            {/* <p>didnt have account? <NavLink to={"/auth/register"} className={'navlink'}>sign up</NavLink></p> */}
          </div>
          <form className="w-full max-w-sm mx-auto space-y-6">
            <div className="form-group">
              <Label className="md:w-1/3">Username</Label>
              <Input
                className="w-full md:w-2/3 form-box"
                type="text"
                name="username"
                onChange={(event) => handleChange(event)}
                value={inputs.username}
              />
            </div>
            <div className="form-group">
              <Label className="md:w-1/3">Password</Label>
              <Input
                className="w-full md:w-2/3 form-box"
                type="password"
                name="password"
                onChange={(event) => handleChange(event)}
                value={inputs.password}
              />
            </div>
            <div className="md:flex md:items-center">
              <div className="md:w-1/3"></div>
              <div className="md:w-2/3">
                <Button type={"submit"} onClick={handleSubmit} buttonStyle={"btn-primary mr-5 m-1"} disabled={isLoading || !inputs?.username || !inputs?.password}>Login</Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login