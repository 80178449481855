import { USERS } from "../../../../services";
import { USERS_SUBMIT_STATE } from "../../../globalActionType";

export const createUsers = (params) => {
  function request() { return { type: USERS_SUBMIT_STATE.USERS_SUBMIT_DATA } }
  function success(data) { return { type: USERS_SUBMIT_STATE.USERS_SUBMIT_DATA_SUCCESS, data } }
  function failure(data) { return { type: USERS_SUBMIT_STATE.USERS_SUBMIT_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await USERS.createUsers(params)
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data))
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
  }
};

export const updateUsers = (params) => {
  function request() { return { type: USERS_SUBMIT_STATE.USERS_SUBMIT_DATA } }
  function success(data) { return { type: USERS_SUBMIT_STATE.USERS_SUBMIT_DATA_SUCCESS, data } }
  function failure(data) { return { type: USERS_SUBMIT_STATE.USERS_SUBMIT_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await USERS.updateUsers(params)
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data))
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
  }
};

export const deleteUsers = (params) => {
  function request() { return { type: USERS_SUBMIT_STATE.USERS_SUBMIT_DATA } }
  function success(data) { return { type: USERS_SUBMIT_STATE.USERS_SUBMIT_DATA_SUCCESS, data } }
  function failure(data) { return { type: USERS_SUBMIT_STATE.USERS_SUBMIT_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await USERS.deleteUsers(params)
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data))
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
  }
};

export const resetSubmit = () => (dispatch) => {
  dispatch({
    type: USERS_SUBMIT_STATE.USERS_SUBMIT_DATA_RESET
  })
}