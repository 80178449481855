import { SERVICE_DATA_STATE } from "../../globalActionType";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: [],
  message: '',

  isLoadingOther: false,
  isErrorOther: false,
  isSuccessOther: false,
  dataOther: [],
  messageOther: '',

  isLoadingAssets: false,
  isErrorAssets: false,
  isSuccessAssets: false,
  dataAssets: [],
  messageAssets: '',

  isLoadingFooter: false,
  isErrorFooter: false,
  isSuccessFooter: false,
  dataFooter: [],
  messageFooter: '',

  isLoadingDetail: false,
  isErrorDetail: false,
  isSuccessDetail: false,
  dataDetail: [],
  messageDetail: '',
}

export const service = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_DATA_STATE.SERVICE_DATA:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case SERVICE_DATA_STATE.SERVICE_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case SERVICE_DATA_STATE.SERVICE_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: action.data.data
      };
    case SERVICE_DATA_STATE.SERVICE_DATA_RESET:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: [],
        message: '',
      };
    case SERVICE_DATA_STATE.OTHER_SERVICE_DATA:
      return {
        ...state,
        isLoadingOther: true,
        isSuccessOther: false,
        isErrorOther: false,
      };
    case SERVICE_DATA_STATE.OTHER_SERVICE_DATA_FAILURE:
      return {
        ...state,
        isLoadingOther: false,
        isSuccessOther: false,
        isErrorOther: true,
        messageOther: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case SERVICE_DATA_STATE.OTHER_SERVICE_DATA_SUCCESS:
      return {
        ...state,
        isLoadingOther: false,
        isSuccessOther: true,
        isErrorOther: false,
        dataOther: action.data.data
      };
    case SERVICE_DATA_STATE.OTHER_SERVICE_DATA_RESET:
      return {
        ...state,
        isLoadingOther: false,
        isErrorOther: false,
        isSuccessOther: false,
        dataOther: [],
        messageOther: '',
      };
    case SERVICE_DATA_STATE.ASSETS_SERVICE_DATA:
      return {
        ...state,
        isLoadingAssets: true,
        isSuccessAssets: false,
        isErrorAssets: false,
      };
    case SERVICE_DATA_STATE.ASSETS_SERVICE_DATA_FAILURE:
      return {
        ...state,
        isLoadingAssets: false,
        isSuccessAssets: false,
        isErrorAssets: true,
        messageAssets: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case SERVICE_DATA_STATE.ASSETS_SERVICE_DATA_SUCCESS:
      return {
        ...state,
        isLoadingAssets: false,
        isSuccessAssets: true,
        isErrorAssets: false,
        dataAssets: action.data.data
      };
    case SERVICE_DATA_STATE.ASSETS_SERVICE_DATA_RESET:
      return {
        ...state,
        isLoadingAssets: false,
        isErrorAssets: false,
        isSuccessAssets: false,
        dataAssets: [],
        messageAssets: '',
      };
    case SERVICE_DATA_STATE.FOOTER_SERVICE_DATA:
      return {
        ...state,
        isLoadingFooter: true,
        isSuccessFooter: false,
        isErrorFooter: false,
      };
    case SERVICE_DATA_STATE.FOOTER_SERVICE_DATA_FAILURE:
      return {
        ...state,
        isLoadingFooter: false,
        isSuccessFooter: false,
        isErrorFooter: true,
        messageFooter: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case SERVICE_DATA_STATE.FOOTER_SERVICE_DATA_SUCCESS:
      return {
        ...state,
        isLoadingFooter: false,
        isSuccessFooter: true,
        isErrorFooter: false,
        dataFooter: action.data.data
      };
    case SERVICE_DATA_STATE.FOOTER_SERVICE_DATA_RESET:
      return {
        ...state,
        isLoadingFooter: false,
        isErrorFooter: false,
        isSuccessFooter: false,
        dataFooter: [],
        messageFooter: '',
      };
    case SERVICE_DATA_STATE.SERVICE_DETAIL_DATA:
      return {
        ...state,
        isLoadingDetail: true,
        isSuccessDetail: false,
        isErrorDetail: false,
      };
    case SERVICE_DATA_STATE.SERVICE_DETAIL_DATA_FAILURE:
      return {
        ...state,
        isLoadingDetail: false,
        isSuccessDetail: false,
        isErrorDetail: true,
        messageDetail: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case SERVICE_DATA_STATE.SERVICE_DETAIL_DATA_SUCCESS:
      return {
        ...state,
        isLoadingDetail: false,
        isSuccessDetail: true,
        isErrorDetail: false,
        dataDetail: action.data.data
      };
    case SERVICE_DATA_STATE.SERVICE_DETAIL_DATA_RESET:
      return {
        ...state,
        isSuccessDetail: false,
      };
    default:
      return state;
  }
}
export default service