import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/atoms/Button";
import Input from "../../components/atoms/Input";
import Label from "../../components/atoms/Label";
import Loading from "../../components/atoms/Loading";
// import Select from "../../components/atoms/Select";
import { createRole } from "../../redux/admin/role/create/action";

const CreateRole = ({ row, onClose, onSubmit }) => {
  const dispatch = useDispatch();
  const submit = useSelector((state) => state.roleSubmit);
  const [inputs, setInputs] = useState({
    name: row?.name ?? "",
  });

  const handleChange = (event) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createRole(inputs));
    onSubmit();
  };

  return (
    <form className="w-full max-w-sm mx-auto space-y-6" onSubmit={handleSubmit}>
      <div className="form-group">
        <Label className="md:w-1/3">Name</Label>
        <Input
          className="md:w-2/3 form-box"
          type="text"
          name="name"
          onChange={(event) => handleChange(event)}
          value={inputs.name}
        />
      </div>
      {submit.isLoading && <Loading />}
      <div className="text-right pt-5">
        <Button
          type={"submit"}
          buttonStyle={"btn-primary text-sm p-1 mr-4"}
          disabled={submit.isLoading}
        >
          Submit
        </Button>
        <Button
          type={"button"}
          buttonStyle={"btn-secondary text-sm p-1"}
          onClick={handleClose}
          disabled={submit.isLoading}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default CreateRole;
