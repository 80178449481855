import _ from 'lodash';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useDispatch, useSelector } from 'react-redux';
import { getlocation } from '../../../redux/admin/location/action';
import LoadingSr from '../../atoms/LoadingSr';

const Maps = ({
  className,
  zoom
}) => {

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { data, isLoading } = useSelector(state => state.location)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang
  }
  
  useEffect(() => {
    dispatch(getlocation(params))
  }, [lang]);

  const defaultPosition = [-6.935445, 110.0680607]

  // let icon = L.icon({
  //     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  //     iconUrl: require('leaflet/dist/images/marker-icon.png'),
  //     shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  // })

  return isLoading ? <LoadingSr className={"h-40 w-full"} /> :  (
    <div className={`overflow-hidden ${className}`}>
      <MapContainer style={{zIndex:1}} center={defaultPosition} zoom={zoom} scrollWheelZoom={false} className={className} >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {data.length ?
          _.map(data, (val, key) => (
            <Marker key={key} position={[val?.lng,val?.lat]}>
              <Popup>
                {val?.title}
              </Popup>
            </Marker>
          )) : null
        }
      </MapContainer>
    </div>
  )
}

export default Maps