import { SERVICE } from "../../../services";
import { SERVICE_DATA_STATE } from "../../globalActionType";

export const getService = (params) => {
  function request() { return { type: SERVICE_DATA_STATE.SERVICE_DATA } }
  function success(data) { return { type: SERVICE_DATA_STATE.SERVICE_DATA_SUCCESS, data } }
  function failure(data) { return { type: SERVICE_DATA_STATE.SERVICE_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await SERVICE.getService(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getListService = (params) => {
  function request() { return { type: SERVICE_DATA_STATE.SERVICE_DATA } }
  function success(data) { return { type: SERVICE_DATA_STATE.SERVICE_DATA_SUCCESS, data } }
  function failure(data) { return { type: SERVICE_DATA_STATE.SERVICE_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await SERVICE.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getOtherListService = (params) => {
  function request() { return { type: SERVICE_DATA_STATE.OTHER_SERVICE_DATA } }
  function success(data) { return { type: SERVICE_DATA_STATE.OTHER_SERVICE_DATA_SUCCESS, data } }
  function failure(data) { return { type: SERVICE_DATA_STATE.OTHER_SERVICE_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await SERVICE.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getAssetsListService = (params) => {
  function request() { return { type: SERVICE_DATA_STATE.ASSETS_SERVICE_DATA } }
  function success(data) { return { type: SERVICE_DATA_STATE.ASSETS_SERVICE_DATA_SUCCESS, data } }
  function failure(data) { return { type: SERVICE_DATA_STATE.ASSETS_SERVICE_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await SERVICE.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getFooterListService = (params) => {
  function request() { return { type: SERVICE_DATA_STATE.FOOTER_SERVICE_DATA } }
  function success(data) { return { type: SERVICE_DATA_STATE.FOOTER_SERVICE_DATA_SUCCESS, data } }
  function failure(data) { return { type: SERVICE_DATA_STATE.FOOTER_SERVICE_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await SERVICE.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getDetailService = (params) => {
  function request() { return { type: SERVICE_DATA_STATE.SERVICE_DETAIL_DATA } }
  function success(data) { return { type: SERVICE_DATA_STATE.SERVICE_DETAIL_DATA_SUCCESS, data } }
  function failure(data) { return { type: SERVICE_DATA_STATE.SERVICE_DETAIL_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await SERVICE.getDetail(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const resetService = () => (dispatch) => {
  dispatch({
    type: SERVICE_DATA_STATE.SERVICE_DATA_RESET
  })
}
export const resetSerivceOther = () => (dispatch) => {
  dispatch({
    type: SERVICE_DATA_STATE.OTHER_SERVICE_DATA_RESET
  })
}
export const resetSerivceAssets = () => (dispatch) => {
  dispatch({
    type: SERVICE_DATA_STATE.ASSETS_SERVICE_DATA_RESET
  })
}
export const resetSerivceFooter = () => (dispatch) => {
  dispatch({
    type: SERVICE_DATA_STATE.FOOTER_SERVICE_DATA_RESET
  })
}
export const resetServiceDetail = () => (dispatch) => {
  dispatch({
    type: SERVICE_DATA_STATE.SERVICE_DETAIL_DATA_RESET
  })
}