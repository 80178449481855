import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import Input from '../../components/atoms/Input'
import Modal from '../../components/atoms/Modal'
import Select from '../../components/atoms/Select'
import DeleteDialog from '../../components/molecules/DeleteDialog'
import Popover from '../../components/molecules/Popover'
import DataTableServer from '../../components/organisms/DataTableServer'
import { useToast } from '../../components/organisms/ToastProvider'
import { formatDate } from '../../helpers/convertDate'
import { remappingFilter } from '../../helpers/filterMapping'
import { toastRequest, toastReset } from '../../redux/global/toast/action'
import _ from 'lodash'
import { getCategory, getListCategory, resetCategoryDetail } from '../../redux/admin/category/action'
import FormCategory from './form'
import { deleteList } from '../../redux/admin/category/submit/action'
import { NavLink } from 'react-router-dom'
import Language from '../../components/organisms/options/Language'

const Category = () => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const [modalName, setModalName] = useState(null)
  const [fetched, setFetched] = useState(false)
  const { addToast } = useToast()

  const { data, isLoading } = useSelector(state => state.category)
  const dataToast = useSelector(state => state.toast)
  const submit = useSelector(state => state.categorySubmit)
  const others = useSelector(state => state.others)
  const { lang } = useSelector(state => state.other)

  const [editData, setEditData] = useState(null)
  const [deleteId, setDeleteId] = useState(null)

  const [pagination, setPagination] = useState({
    page: 1, limit: 10
  })
  const [sorting, setSorting] = useState({
    sortBy: "", sortType: "" //sortby name, sorttype asc or desc
  })
  const [filter, setFilter] = useState([])

  const params = {
    lang: lang,
    pagination: pagination,
    sorting: sorting,
    filter: filter.join(",")
  }

  useEffect(() => { // get first data
    dispatch(getListCategory(params))
  }, []) //eslint-disable-line

  useEffect(() => {
    if (!isOpen) {
      dispatch(resetCategoryDetail())
    }
  }, [isOpen]);

  useEffect(() => { // reload datatable
    if (fetched) {
      dispatch(getListCategory(params))
      setFetched(false);
    }
  }, [fetched]); //eslint-disable-line

  useEffect(() => { // global toast 
    if (dataToast.toast && submit.alert) {
      addToast({
        type: submit.alert,
        message: submit.message,
        // time:5000
      })
      dispatch(toastReset())
      if (submit.alert === 'success') {
        setFetched(true)
        setIsOpen(false)
        // dispatch(resetSubmit())
      }
    }

  }, [dataToast.toast, submit.alert]); //eslint-disable-line

  const onClose = () => {
    setIsOpen(false)
    // dispatch(resetRole())
  };

  const onSubmit = () => {
    dispatch(toastRequest());
  };

  const onCreate = (event) => {
    setModalName(event.target.name)
    setIsOpen(true)
  }

  const onClick = (row) => {
    setEditData(row)
    setModalName("popover")
    setIsOpen(true)
  }

  const onEdit = async (row) => {
    setModalName("edit")
    setIsOpen(true)
  }

  const onDelete = async (id) => {
    setModalName("delete")
    setDeleteId(id)
    setIsOpen(true)
  }

  const onSearch = (event) => {
    eventSearch(event.target.value, event.target.name, filter)
  }

  const eventSearch = useMemo(
    () => _.debounce((value, name, filter) => {
      remappingFilter(setFilter, filter, value, name, "like")
      setFetched(true)
    }, 1000),
    []
  )

  const onSelect = (event) => {
    remappingFilter(setFilter, filter, event.target.value, event.target.name, "eq")
    setFetched(true);
  }

  const handleLimitChange = (limit, page) => {
    setPagination({
      ...pagination,
      limit: limit
    })
    setFetched(true)
  };

  const handlePageChange = (page) => {
    setPagination({
      ...pagination,
      page: page
    })
    setFetched(true);
  };

  const handleSort = (column, sortDirection) => {
    setSorting({
      ...sorting,
      sortBy: column?.field,
      sortType: sortDirection
    })
    setFetched(true);
  };

  let handleDelete = () => {
    const multiId = {
      id: deleteId
    }
    dispatch(deleteList(multiId))
    dispatch(toastRequest());
    // setFetched(true);
  }

  const handleLangChange = () => {
    setFetched(true)
  }

  const columns = [
    {
      name: 'Id',
      selector: row => row.id,
      omit: true
    },
    {
      name: 'Title',
      selector: row => row.title,
      sortable: true,
      field: "title"
    },
    {
      name: 'Created At',
      selector: row => formatDate(row.createdAt, "english"),
      sortable: true,
      // right: true,
      field: "createdAt"
    },
    {
      name: 'Updated At',
      selector: row => formatDate(row.updatedAt, "english"),
      sortable: true,
      field: "updatedAt"
    },
  ];
  return (
    <>
      <div className='bg-white p-5 shadow-md mb-5'>
        <DataTableServer
          title={"Category"}
          columns={columns}
          data={data?.data}
          total={data?.pagination?.totalItems}
          loading={isLoading}
          pageChange={handlePageChange}
          limitChange={handleLimitChange}
          sortChange={handleSort}
          actionsButton={
            <div className="flex flex-col-reverse space-y-2 space-y-reverse md:flex-row md:space-x-4 md:space-y-0">
              <NavLink
                className={"btn-secondary text-sm p-1"}
                to={"/admin/news"}
              > Back to News</NavLink>
              <Input
                placeholder="Search"
                type="text"
                name="title"
                className={"text-sm py-1 px-4"}
                onChange={(event) => onSearch(event)}
              />
              <Language isLoading={submit?.isLoading} value={lang} onChange={() => handleLangChange()} />
              <Button
                buttonStyle={"btn-primary text-sm p-1"}
                name={"create"}
                onClick={(event) => onCreate(event)}
              >
                Create
              </Button>
            </div>
          }
          uniqId={true}
          onRowClick={(row) => onClick(row)}
          deleted={(id) => onDelete(id)}
          toogleSelectableRows={fetched}
        />
      </div>

      <Modal
        isOpen={isOpen && (modalName === "create" || modalName === "edit")}
        onClose={() => onClose()}
        title={isOpen ? `${modalName} Category` : 'Title'}
      >
        <FormCategory
          row={modalName === "edit" ? editData : null}
          onClose={() => onClose()}
          onSubmit={() => onSubmit()}
        />
      </Modal>
      <Modal title={"Delete category"}
        isOpen={isOpen && modalName === "delete"}
        onClose={() => onClose()}
      >
        <DeleteDialog
          id={deleteId}
          isLoading={submit.isLoading}
          deleted={() => handleDelete()}
          close={() => onClose()}
        />
      </Modal>
      <Popover
        isOpen={isOpen && modalName === "popover"}
        onClose={() => onClose()}
        coord={others?.coordinate}
        onView={() => onClose()}
        onEdit={(row) => onEdit(row)}
        // onSendMail={() => onClose()}
      />
    </>
  )
}

export default Category