import { MENU } from "../../../services";
import { BASEMENU_STATE } from "../../globalActionType";

export const getMenu = (params) => {
  function request() { return { type: BASEMENU_STATE.BASEMENU_DATA } }
  function success(data, total) { return { type: BASEMENU_STATE.BASEMENU_DATA_SUCCESS, data, total } }
  function failure(data) { return { type: BASEMENU_STATE.BASEMENU_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await MENU.getMenu(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};