import { SEARCH_DATA_STATE } from "../../globalActionType";

const initialState = {
  text: '',
  category: '',
  fetched: false
}

const search = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_DATA_STATE.SEARCH_DATA:
      return {
        ...state,
        text: action.data,
        // fetched: true
      };
    case SEARCH_DATA_STATE.SEARCH_CATEGORY_DATA:
      return {
        ...state,
        category: action.data,
        // fetched: true
      };
    case SEARCH_DATA_STATE.SET_FECHED:
      return {
        ...state,
        fetched: action.data
      };
    default:
      return state;
  }
}

export default search;