import React, { useEffect, useRef } from 'react'
import LandingSection from '../../../components/templates/landing/Section'
import { apiUrl, baseUrl } from '../../../helpers/baseUrl'
import Slider from "react-slick";
import _ from 'lodash';
import Img from '../../../components/atoms/Img';
import { useDispatch, useSelector } from 'react-redux';
import { getHero } from '../../../redux/admin/hero/action';
import LoadingSr from '../../../components/atoms/LoadingSr';
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
// import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
import { NavLink, useNavigate } from 'react-router-dom';


const settings = {
  dots: true,
  infinite: true,
  fade: true,
  autoplay: true,
  autoplaySpeed: 2000,
  // speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

// const image = [
//   { id: "1", title: "Reliable and flexible logistics", caption: "Personalized cargo services in worldwide commerce", url: baseUrl(`/assets/img/slider/01.jpg`) },
//   { id: "2", title: "Reliable and flexible logistics 2", caption: "Personalized cargo services in worldwide commerce 2", url: baseUrl(`/assets/img/slider/02.jpg`) },
//   { id: "3", title: "Reliable and flexible logistics 3", caption: "Personalized cargo services in worldwide commerce 3", url: baseUrl(`/assets/img/slider/03.jpg`) },
// ]


const Hero = () => {
  const slider = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const { data, isLoading } = useSelector(state => state.hero)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang
  }

  // useEffect(() => {
  //   dispatch(getHero(params))
  // }, []);

  const handleKnowMore = (path) =>{
    navigate(path)
  }

  useEffect(() => {
    dispatch(getHero(params))
  }, [lang]);

  return (
    // <LandingSection className={"text-white h-[47.5rem] min-w-full flex items-center"}>
    <LandingSection className={"text-white h-[47.5rem] min-w-full flex items-center"}>
      {isLoading ?
        <div className=" bg-slate-600 animate-pulse absolute  h-[47.5rem] w-full left-0 z-0 overflow-hidden">
          <div className="h-[47.5rem]">
            <div className="flex flex-col text-left justify-center bg-transparant z-20 left-0 h-[47.5rem]">
              <div className="w-content w-full space-y-6">
                <LoadingSr className="md:w-20 w-20 h-5" />
                <LoadingSr className="md:w-52 w-52 h-5" />
                <LoadingSr className="md:w-96 w-64 h-7" />
              </div>
            </div>
          </div>
        </div> :
        // <Swiper
        //   onSwiper={(swiper) => {
        //     // Delay execution for the refs to be defined
        //     setTimeout(() => {
        //       // Override prevEl & nextEl now that refs are defined
        //       swiper.params.navigation.prevEl = prevRef.current
        //       swiper.params.navigation.nextEl = nextRef.current

        //       // Re-init navigation
        //       swiper.navigation.destroy()
        //       swiper.navigation.init()
        //       swiper.navigation.update()
        //     })
        //   }}
        //   navigation={true}
        //   effect={"fade"}
        //   autoplay={{
        //     delay: 2500,
        //     disableOnInteraction: false,
        //   }}
        //   modules={[Navigation, Autoplay, EffectFade]}
        //   className="h-full w-full"
        //   // style={{paddingBottom:"0"}}
        // >
        //   {_.map(data, (val, key) => (
        //     <SwiperSlide key={key} className="bg-gray-900">
        //       {/* <div className="fixed flex flex-col w-screen text-left justify-center bg-transparant z-20 left-0 h-[47.5rem]"> */}
        //       <div className="fixed flex w-full h-full items-center text-left z-20">
        //         <div className="w-content w-full space-y-6">
        //           <div className="flex space-x-2  font-mono">
        //             <button ref={prevRef} className="bg-white py-1 px-2 text-xl font-bold text-gray-500">&lt;</button>
        //             <button ref={nextRef} className="bg-white py-1 px-2 text-xl font-bold text-gray-500">&gt;</button>
        //           </div>
        //           <div className="font-roboto-condenced text-xl md:text-[34px] leading-[44px] font-light">{val?.title}</div>
        //           <div className="font-roboto-condenced text-3xl md:text-[50px] leading-[60px] font-bold tracking-tight uppercase">{val?.description}</div>
        //           <button className="text-left bg-[#2ecc71] w-fit py-4 px-8 rounded-md uppercase">{t("know more")}</button>
        //         </div>
        //       </div>
        //       <Img src={baseUrl(`/assets/img/slider/${val?.image}`)} alt={val?.image} className="h-full opacity-30 object-cover md:w-full" />
        //     </SwiperSlide>
        //   ))}
        // </Swiper>

        <Slider {...settings} ref={slider} style={{ position: "absolute" }} className="bg-gray-900 h-[47.5rem] w-full left-0 z-0 overflow-hidden">
          {
            _.map(data, (val, key) => (
              <div key={key} className="h-[47.5rem]">
                <div className="fixed flex flex-col w-screen text-left justify-center bg-transparant z-20 left-0 h-[47.5rem]">
                  <div className="w-content w-full space-y-6">
                    <div className="flex space-x-2 opacity-60 font-mono">
                      <button onClick={() => slider?.current?.slickPrev()} className="bg-white py-1 px-2 text-xl font-bold text-gray-500">&lt;</button>
                      <button onClick={() => slider?.current?.slickNext()} className="bg-white py-1 px-2 text-xl font-bold text-gray-500">&gt;</button>
                    </div>
                    <motion.div
                      initial={{ opacity: 0, y: 100 }}
                      // animate={{ opacity: 1, x: 0 }}
                      animate={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ ease: "easeOut", duration: 0.5 }}
                      className="font-roboto-condenced text-xl md:text-[34px] leading-[24px] md:leading-[44px] font-light"
                    >{val?.title}
                    </motion.div>
                    <motion.div
                      initial={{ opacity: 0, y: 100 }}
                      // animate={{ opacity: 1, x: 0 }}
                      animate={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ ease: "easeOut", duration: 1 }}
                      className="font-roboto-condenced text-3xl md:text-[50px] leading-[30px] md:leading-[60px] font-bold tracking-tight uppercase"
                    >{val?.description}
                    </motion.div>
                    <motion.button
                      onClick={() => handleKnowMore("/about-us")}
                      initial={{ opacity: 0, y: 100 }}
                      // animate={{ opacity: 1, x: 0 }}
                      animate={{ opacity: 1, y: 0 }}
                      viewport={{ once: true }}
                      transition={{ ease: "easeOut", duration: 1.5 }}
                      className="text-left bg-[#2ecc71] w-fit py-4 px-8 rounded-md uppercase"
                    >{t("know more")}
                    </motion.button>
                  </div>
                </div>
                <Img src={apiUrl(`public/assets/img/hero/${val?.image}`)} alt={val?.image} className="opacity-40 h-full object-cover md:w-full" />
              </div>
            ))
          }
        </Slider>
      }
    </LandingSection>
  )
}

export default Hero