import { DATA_SUBMIT_STATE } from "../../globalActionType";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  toast: false,
  alert: "",
  // formData:[] //use when using wizard/spa
  message: "",
};

const globalSubmit = (state = initialState, action) => {
  switch (action.type) {
    case DATA_SUBMIT_STATE.DATA_SUBMIT:
      return {
        state,
        isLoading: true,
        isSuccess: false,
      };
    case DATA_SUBMIT_STATE.DATA_SUBMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        toast: true,
        alert: "success",
        message: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case DATA_SUBMIT_STATE.DATA_SUBMIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        toast: true,
        alert: "error",
        message: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case DATA_SUBMIT_STATE.DATA_SUBMIT_RESET:
      return {
        state,
      };
    default:
      return state;
  }
};
export default globalSubmit 