import React from 'react';
import Button from '../atoms/Button';
import Loading from '../atoms/Loading';

const DeleteDialog = ({
  close, 
  id, 
  deleted,
  isLoading
}) => {

  const handleClose = () => {
    close();
  };

  const deletedItem = () => {
    deleted();
  }
  return (
    <>
      <div className='text-center mb-5'>
        Are You Sure you want to delete selected item?
        <p className='text-red-600'>If you deleted this item, you can't restore anymore</p>
      </div>
      {isLoading && <Loading />}
      <div className='mt-5 text-right'>
        <Button 
          buttonStyle={"btn-primary text-sm p-1 mr-4"}
          type={"button"} onClick={() => deletedItem()}
          disabled={isLoading}
          >
          Confirm
        </Button>
        <Button type={"button"}
          buttonStyle={"btn-secondary text-sm p-1"}
          onClick={() => handleClose()}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default DeleteDialog;
