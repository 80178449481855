import React, { useEffect, useRef } from 'react'

const Popover = ({
  isOpen,
  onClose,
  coord,
  onView,
  onEdit,
  onSendMail,
  onOther,
  onPrivilege
}) => {
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        onClose()
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isOpen])   //eslint-disable-line

  const handleView = () => {
    onView()
  }
  const handleEdit = () => {
    onEdit()
  }
  const handleSendMail = () => {
    onSendMail()
  }
  const handlePrivilege = () => {
    onPrivilege()
  }
  const handleOther = () => {
    onOther()
  }

  const style = {
    left: `${coord.x}px`,
    top: `${coord.y}px`,
  }
  return isOpen && (
    <div ref={ref} style={style} className={`fixed z-20 bg-white shadow-md border-2`}>
      <div className="p-1 flex flex-col items-start space-y-1 rounded text-sm">
        {onView &&
          <div onClick={() => handleView()} className="popover-link">
            <svg className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
            </svg>
            <div>View</div>
          </div>
        }
        {onEdit &&
          <div onClick={() => handleEdit()} className="popover-link">
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            <div>Edit</div>
          </div>
        }
        {onSendMail &&
          <div onClick={() => handleSendMail()} className="popover-link">
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            <div>Sent Mail</div>
          </div>
        }
        {onPrivilege &&
          <div onClick={() => handlePrivilege()} className="popover-link">
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" />
            </svg>
            <div>Privilege</div>
          </div>
        }
        {onOther &&
          <div onClick={() => handleOther()} className="popover-link">
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div>Other</div>
          </div>
        }
      </div>
    </div>
  )
}
export default Popover