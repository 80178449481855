import { LOGIN_STATE } from "../../globalActionType";

const initialState = {
  isLoading: false,
  form: '',
  alert: '',
  isError: false,
  isSuccess: false,
  logout: false,
  loginMethod: '',
  data: [],
  isVerifyEmail:false,
  isActive:false,
  token: '',
  message: '',
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_STATE.LOGIN:
      return {
        state,
        isLoading: true,
        form: action.data
        // isSuccess: false,
        // isError: false,
        // data:[],
        // email: undefined,
        // token: undefined,
        // isVerifyEmail:false,
        // isActive:false,
      };
    case LOGIN_STATE.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        alert: 'success',
        form: '',
        token: action.data.token,
        username: action.data.data.username,
        email: action.data.data.email,
        data: action.data.data.employee,
        isActive: action.data.data.isActive,
        isVerifyEmail: action.data.data.isVerifyEmail,
        refreshToken: action.data.refreshToken,
        message: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case LOGIN_STATE.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isSuccess: false,
        form: '',
        alert: "error",
        message: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case LOGIN_STATE.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.data.data
      };
    case LOGIN_STATE.LOGOUT_SUCCESS:
      return {
        state
      };
    case LOGIN_STATE.REFRESH_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case LOGIN_STATE.RESET:
      return {
        ...state,
        token: undefined,
        isSuccess: false,
        isError: false
      };
    default:
      return state;
  }
}

export default login