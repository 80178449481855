import { OUR_GROUP_DATA_STATE } from "../../globalActionType";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: [],
  message: '',
}

export const ourGroup = (state = initialState, action) => {
	switch (action.type) {
    case OUR_GROUP_DATA_STATE.OUR_GROUP_DATA:
      return {
          ...state,
          isLoading: true,
          isSuccess: false,
          isError: false,
      };
    case OUR_GROUP_DATA_STATE.OUR_GROUP_DATA_FAILURE:
      return {
          ...state,
          isLoading: false,
          isSuccess: false,
          isError: true,
          message: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case OUR_GROUP_DATA_STATE.OUR_GROUP_DATA_SUCCESS:
      return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: action.data.data
      };
    case OUR_GROUP_DATA_STATE.OUR_GROUP_DATA_RESET:
      return {
          state,
      };
    default:
      return state;
	}
}
export default ourGroup 