import { NEWS } from "../../../../services";
import { NEWS_SUBMIT_STATE } from "../../../globalActionType";

export const submit = (params) => {
  function request() { return { type: NEWS_SUBMIT_STATE.NEWS_SUBMIT } }
  function success(data) { return { type: NEWS_SUBMIT_STATE.NEWS_SUBMIT_SUCCESS, data } }
  function failure(data) { return { type: NEWS_SUBMIT_STATE.NEWS_SUBMIT_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {  
      // console.log(params?.type)    
      const response = params?.type === 'edit' ?
       await NEWS.update(params) : await NEWS.create(params)
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data)) 
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
  }
};

export const deleteList = (params) => {
  function request() { return { type: NEWS_SUBMIT_STATE.NEWS_SUBMIT } }
  function success(data) { return { type: NEWS_SUBMIT_STATE.NEWS_SUBMIT_SUCCESS, data } }
  function failure(data) { return { type: NEWS_SUBMIT_STATE.NEWS_SUBMIT_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await NEWS.deleteList(params)
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data))
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
  }
};

export const resetSubmit = () => (dispatch) => {
  dispatch({
    type: NEWS_SUBMIT_STATE.NEWS_SUBMIT_RESET
  })
}