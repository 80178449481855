import { LOCATION } from "../../../services";
import { LOCATION_DATA_STATE } from "../../globalActionType";

export const getlocation = (params) => {
  function request() { return { type: LOCATION_DATA_STATE.LOCATION_DATA } }
  function success(data) { return { type: LOCATION_DATA_STATE.LOCATION_DATA_SUCCESS, data } }
  function failure(data) { return { type: LOCATION_DATA_STATE.LOCATION_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await LOCATION.getLocation(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getListLocation = (params) => {
  function request() { return { type: LOCATION_DATA_STATE.LOCATION_DATA } }
  function success(data) { return { type: LOCATION_DATA_STATE.LOCATION_DATA_SUCCESS, data } }
  function failure(data) { return { type: LOCATION_DATA_STATE.LOCATION_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await LOCATION.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getDetailLocation = (params) => {
  function request() { return { type: LOCATION_DATA_STATE.LOCATION_DETAIL_DATA } }
  function success(data) { return { type: LOCATION_DATA_STATE.LOCATION_DETAIL_DATA_SUCCESS, data } }
  function failure(data) { return { type: LOCATION_DATA_STATE.LOCATION_DETAIL_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await LOCATION.getDetail(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const resetLocation = () => (dispatch) => {
  dispatch({
    type: LOCATION_DATA_STATE.LOCATION_DATA_RESET
  })
}

export const resetLocationtDetail = () => (dispatch) => {
  dispatch({
    type: LOCATION_DATA_STATE.LOCATION_DETAIL_DATA_RESET
  })
}
