// import React from 'react'

import HOME from "../../pages/landing/home"
import ABOUT from "../../pages/landing/about"
import SERVICES from "../../pages/landing/services"
import LOCATION from "../../pages/landing/location"
import NEWS from "../../pages/landing/news"
import CAREER from "../../pages/landing/career"
import CONTACTUS from "../../pages/landing/contact-us"

const Routers = [
  {
    path: "",
    component: HOME
  },
  {
    path: "about-us",
    component: ABOUT
  },
  {
    path: "services",
    component: SERVICES
  },
  {
    path: "location",
    component: LOCATION
  },
  {
    path: "news/*",
    component: NEWS
  },
  {
    path: "career",
    component: CAREER
  },
  {
    path: "contact-us",
    component: CONTACTUS
  },
]

export default Routers
