import LandingNewsDetail from "../../../pages/landing/news/detail"
import LandingNewsList from "../../../pages/landing/news/list"

const Routers = [
  {
    path: "news",
    component: LandingNewsList
  },
  {
    path: "news/*",
    component: LandingNewsDetail
  },
]

export default Routers