import React from 'react'

const YoutubeEmbed = ({embedId}) => {
  return (
    <div className="relative video-responsive w-full">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
        ></iframe>
    </div>
  )
}

export default YoutubeEmbed