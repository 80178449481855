import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Img from '../../../components/atoms/Img'
import LoadingSr from '../../../components/atoms/LoadingSr'
import YoutubeEmbed from '../../../components/organisms/YoutubeEmbed'
import LandingSection from '../../../components/templates/landing/Section'
import { apiUrl, baseUrl } from '../../../helpers/baseUrl'
import { getOtherListService } from '../../../redux/admin/service/action'

const Services = ({
  data,
  isLoading
}) => {

  return (
    <LandingSection className={"bg-[#F5F7F9] min-h-[10rem] min-w-full flex"}>
      <div className="flex flex-col-reverse md:flex-row justify-end w-full">
        {/* <div className="bg-red-200 w-full p-6 flex flex-row items-start text-left space-x-4"> */}
        <div className="bg-[#F5F7F9] grid grid-cols-1 md:w-full md:grid-cols-2 gap-6 text-left p-8">
          {isLoading ?
            _.map([0, 1, 2, 3], (val, key) => (
              <div key={key} className="flex space-x-4 items-start">
                <LoadingSr className="w-20 h-20" />
                <div className="space-y-4">
                  <LoadingSr className="w-32 h-5" />
                  <div className="space-y-3">
                    <LoadingSr className="w-24 h-4" />
                    <LoadingSr className="w-32 h-4" />
                  </div>
                </div>
              </div>
            ))
            :
            _.map(data, (val, key) => (
              <div key={key} className="flex space-x-4 items-start">
                <Img src={apiUrl("public/assets/img/service/" + val?.image)} alt="" className="w-14 min-h-fit" />
                <div className="space-y-1">
                  <label className="font-bold md:text-[12px] leading-[14px]">{val?.title}</label>
                  <div className="md:text-[12px] leading-[16px]">{val?.description}</div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </LandingSection>
  )
}

export default Services