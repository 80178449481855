import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getListCategory } from '../../../redux/admin/category/action';
import Select from '../../atoms/Select'

const CategoryNews = ({
  onSelect,
  className,
  name,
  value,
  useAll
}) => {
  const dispatch = useDispatch()

  const {data, isLoading} = useSelector(state => state.category)
  const [selected, setSelected] = useState(null)

  const params = {
    page:1,
    size:100
  }

  useEffect(() => {
    dispatch(getListCategory(params))
  }, []);

  const optionData = _.map(data?.data, (val) => {
    return {
      label: val.title,
      value: val.uniqId
    } 
  })

  const onChange = (event) => {
    setSelected(event.target.value)
    onSelect(event)
  }
  // console.log(value)
  return (
    <Select
      placeholder="Select Category"
      type="text"
      name={name ?? "category"}
      useAll={useAll ?? false}
      className={className}
      // onChange={(event) => onSelect(event)}
      onChange={(event) => onChange(event)}
      value={value ?? selected}
      option={optionData}
    />
  )
}

export default CategoryNews