import api from '../config/api'; //use for admin page
import apiLanding from '../config/apiLanding';


export const getList = (params) => {
  return apiLanding
  .get(`/hero?lang=${params?.lang ?? ''}`)
}

export const getDetail = (params) => {
  return api
  .get(`/hero/${params?.id ?? ''}`)
}

export const getHero = (params) => {
  return api
  .get(`/hero?lang=${params?.lang ?? ''}&page=${params?.pagination?.page ?? "1"}&size=${params?.pagination?.limit ?? "10"}${params?.sorting ? "&sortBy="+params?.sorting?.sortBy+"&sortType="+params?.sorting?.sortType:""}${params?.filter ? "&filters="+params?.filter:""}`)
}

export const create = (params) => {
  return api
  .post(`/hero`,params?.data)
}

export const update = (params) => {
  return api
  .put(`/hero/update`,params?.data)
}

export const deleteHero = (id) => {
  return api
  .put(`/hero`,id)
}