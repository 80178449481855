import _ from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Img from "../../../components/atoms/Img";
import LoadingSr from "../../../components/atoms/LoadingSr";
import LandingSection from "../../../components/templates/landing/Section";
import { apiUrl, baseUrl } from "../../../helpers/baseUrl";
import { getOtherListService } from "../../../redux/admin/service/action";
import HeroContent from "../section/HeroContent";
import LatestProject from "../section/LatestProject";
import { motion } from "framer-motion"


const LandingService = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { dataOther, isLoadingOther } = useSelector(state => state.service)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang,
    type: 'content'
  }

  useEffect(() => {
    dispatch(getOtherListService(params))
  }, [lang]);

  return (
    <div className="bg-white">
      <HeroContent
        title={"services"}
        to={"services"}
      />
      <LandingSection>
        <div className="w-content flex flex-col space-x-0 space-y-4 md:space-y-0 md:flex-row md:space-x-4 py-10 items-center text-left min-h-[15rem]">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ type: "spring", duration: 1 }}
            viewport={{ once: true }}
            className="space-y-4 w-full"
          >
            <p className="uppercase font-semibold text-2xl">{t("pt.ilc logistics indonesia services overview")}</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ type: "spring", duration: 1 }}
            viewport={{ once: true }}
            className="bg-blue-300 flex justify-center w-full"
          >
            <Img src={baseUrl(`/assets/img/bg/service-bg.jpg`)} className="w-full" />
          </motion.div>
        </div>
      </LandingSection>
      {dataOther?.length &&
        _.map(dataOther, (val, key) => (
          <LandingSection className={(key + 1) % 2 !== 0 ? "bg-[#f5f7f9]" : ""} key={key}>
            {val?.imageOnly ?
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ ease: "easeOut", duration: 1 }}
                viewport={{ once: true }}
                className="w-content flex justify-center py-6"
              >
                <Img src={apiUrl(`public/assets/img/service/${val?.image}`)} className="w-fit max-w-full" />
              </motion.div>
              :
              <div className={(key + 1) % 2 !== 0 ?
                "w-content flex flex-col space-x-0 space-y-4 md:space-y-0 md:flex-row md:space-x-16 py-10 items-center text-left min-h-[15rem]"
                :
                "w-content flex flex-col space-x-0 space-y-4 md:space-y-0 md:flex-row-reverse md:space-x-16 md:space-x-reverse py-10 items-center text-left min-h-[15rem]"
              }
              >
                <motion.div
                  initial={{ opacity: 0, x: (key + 1) % 2 !== 0 ? -100 : 100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ ease: "easeOut", duration: 1 }}
                  viewport={{ once: true }}
                  className="flex justify-center w-1/4"
                >
                  <Img src={apiUrl(`public/assets/img/service/${val?.image}`)} className="w-full" />
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: (key + 1) % 2 !== 0 ? 100 : -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ ease: "easeOut", duration: 1 }}
                  viewport={{ once: true }}
                  className="space-y-4 w-full"
                >
                  <p className="capitalize font-semibold text-2xl text-center md:text-left">{val?.title}</p>
                  <p className="whitespace-pre-line description-text text-justify">{val?.description}</p>
                </motion.div>
              </div>}
          </LandingSection>
        ))
      }
      {/* <LandingSection>
        <div className="w-content flex flex-col space-x-0 space-y-4 md:space-y-0 md:flex-row-reverse md:space-x-16 md:space-x-reverse py-10 items-center text-left min-h-[15rem]">
          <div className="flex justify-center w-1/4">
            <Img src={baseUrl(`/assets/svg/delivery-truck.svg`)} className="w-full" />
          </div>
          <div className="space-y-4 w-full">
            <p className="capitalize font-semibold text-2xl">Transportation</p>
            <p className="whitespace-pre-line">
              PT. ILC  LOGISTICS INDONESIA proposes and offers various transportation services according to customer`s needs. While we associate with our partners, we transport and deliver customer`s cargos with safety and accuracy.
            </p>
          </div>
        </div>
      </LandingSection>
      <LandingSection className={"bg-[#f5f7f9]"}>
        <div className="w-content flex flex-col space-x-0 space-y-4 md:space-y-0 md:flex-row md:space-x-16 py-10 items-center text-left min-h-[15rem]">
          <div className="flex justify-center w-1/4">
            <Img src={baseUrl(`/assets/svg/overland.svg`)} className="w-full" />
          </div>
          <div className="space-y-4 w-full">
            <p className="capitalize font-semibold text-2xl">Export Sevice</p>
            <p className="whitespace-pre-line">
              PT. ILC  LOGISTICS INDONESIA has experiences in warehousing services which is related to exportation, such as, stocking, changing　package, packing for export, and container vanning. We cooperate with PT. ITOCHU LOGISTICS INDONESIA (our sister company) and provide consistent services which cover all processes in exportation.
            </p>
          </div>
        </div>
      </LandingSection>
      <LandingSection>
        <div className="w-content flex flex-col space-x-0 space-y-4 md:space-y-0 md:flex-row-reverse md:space-x-16 md:space-x-reverse py-10 items-center text-left min-h-[15rem]">
          <div className="flex justify-center w-1/4">
            <Img src={baseUrl(`/assets/svg/distribution.svg`)} className="w-full" />
          </div>
          <div className="space-y-4 w-full">
            <p className="capitalize font-semibold text-2xl">Container Depo</p>
            <p className="whitespace-pre-line">
              PT. ILC  LOGISTICS INDONESIA has Container Depo in Karawang International Industrial City (KIIC) to provide container storage and delivery services.
              From long-term storage to delivery after temporary-storage. We have high flexibility to meet our customer's need.
            </p>
          </div>
        </div>
      </LandingSection> */}
      {/* <LandingSection className={"bg-[#f5f7f9]"}>
        <div className="w-content flex flex-col space-x-0 space-y-4 md:space-y-0 md:flex-row md:space-x-4 py-10 items-start text-left min-h-[15rem]"> */}
      <LatestProject disabledHeader={true} className={"py-8"} />
      {/* </div>
      </LandingSection> */}
      {/* <LandingSection className={"bg-[#F5F7F9] min-h-[10rem] min-w-full flex"}>
        <div className="flex flex-col-reverse md:flex-row justify-end w-content p-16">
          <div className="bg-[#F5F7F9] grid grid-cols-1 md:grid-cols-2 gap-6 text-left">
            {isLoadingOther ?
              _.map([0, 1, 2, 3], (val, key) => (
                <div key={key} className="flex space-x-4 items-start">
                  <LoadingSr className="w-20 h-20" />
                  <div className="space-y-4">
                    <LoadingSr className="w-32 h-5" />
                    <div className="space-y-3">
                      <LoadingSr className="w-24 h-4" />
                      <LoadingSr className="w-32 h-4" />
                    </div>
                  </div>
                </div>
              ))
              :
              _.map(dataOther, (val, key) => (
                <div key={key} className="flex space-x-4 items-start">
                  <Img src={apiUrl("public/assets/img/service/" + val?.image)} alt="" className="w-20 min-h-fit" />
                  <div className="space-y-2">
                    <label className="font-semibold">{val?.title}</label>
                    <div>{val?.description}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </LandingSection>      
      <LandingSection>
        <div className="w-content flex flex-col space-x-0 space-y-4 md:space-y-0 md:flex-row md:space-x-4 py-10 items-start text-left min-h-[15rem]">
          <div className="bg-blue-300 flex justify-center w-full">
            <Img src={""} className="" />
          </div>
          <div className="space-y-4 w-full">
            <p className="uppercase font-semibold text-2xl">{t("Transport Management System")}</p>
            <p className="text-slate-500">1. Equipped with GPS for most of our ordered trucks, it’s possible to check the operation status in real time. 2. It’s possible to check the unit position information from the Web, and can manage the delivery management status of each trucks, instantaneous arrangements of empty trucks, and recover when trouble occurs. 3. Since the daily driving history is also preserved, we are also using it as a material to consider in order to realize operation optimization, such as checking the running history and the scheduled operating condition such as milk run. </p>
          </div>
        </div>
      </LandingSection> */}
    </div>
  )
}

export default LandingService;
