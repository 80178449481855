import { getRefreshToken, logout } from "../redux/admin/login/action";
import axiosInstance from "./api";

const Setup = (store) => {  
  axiosInstance.interceptors.request.use(
    (config) => {
      const {
        dataStore: { token }
      } = store.getState();

      if (token) {
        // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/login" && originalConfig.url !== "/register" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;    
          // console.log("catch error before respon api route", err)          
          const {
            // dataStore: { refreshToken } //local refresh token
            dataStore: { data } //find user on backend to refresh token
          } = store.getState();   
          try {
            // console.log(data)
            const rs = await axiosInstance.post("/refreshtoken", {
              // refreshToken: refreshToken, //local refresh token
              data: data //find user on backend to refresh token
            });

            const { token } = rs.data;
            // console.log("mendapat token baru ",token)
            dispatch(getRefreshToken(token));

            return axiosInstance(originalConfig);
          } catch (_error) {            
            // console.log("logout")
            dispatch(logout())              
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default Setup;
