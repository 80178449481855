import { ROLE_SUBMIT_STATE } from "../../../globalActionType";

const initialState = {
  isLoading: false,
  error: false,
  toast: false,
  alert: "",
  trigger: "",
  update_success: false,
  message: "",
};

const roleSubmit = (state = initialState, action) => {
  switch (action.type) {
    case ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA:
      return {
        state,
        isLoading: true
      };
    case ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        toast: true,
        alert: "success",
        message:
          action.data?.meta?.message ??
          action.data?.message ??
          action.error?.message ??
          "Success.",
      };

    case ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: false,
        toast: true,
        alert: "error",
        message:
          action.data?.meta?.message ??
          action.data?.message ??
          action.error?.message ??
          "Something went wrong.",
      };

    default:
      return state;
  }
};

export default roleSubmit;
