import api from '../config/api';

export const getPrivilege = (params) => {
  return api
  .get(`/privilege/${params?.id}`)
}

export const update = (params) => {
  return api
  .put(`/privilege`,params)
}