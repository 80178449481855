import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { applyMiddleware, compose, createStore } from 'redux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './stores/rootReducer';
import thunk from 'redux-thunk';
// import { createDevTools } from '@redux-devtools/core';
import { composeWithDevTools } from '@redux-devtools/extension';
import setupInterceptors from './config/setupInterceptors';
import './helpers/translation';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['dataStore', 'other']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const enhancer = compose(applyMiddleware(thunk))

let store;
if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
  store = createStore(
    persistedReducer,
    // createDevTools(applyMiddleware(thunk))
    composeWithDevTools(applyMiddleware(thunk))
  );
} else {
  store = createStore(persistedReducer, enhancer);
}
let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<App />}/>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

setupInterceptors(store);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
