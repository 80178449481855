import _ from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/atoms/Button";
import Img from "../../../components/atoms/Img";
import Input from "../../../components/atoms/Input";
import Textarea from "../../../components/atoms/Textarea";
import LandingSection from "../../../components/templates/landing/Section";
import { baseUrl } from "../../../helpers/baseUrl";
import { getContact } from "../../../redux/admin/contact/action";
import HeroContent from "../section/HeroContent";
import { motion } from "framer-motion"
// import { useToast } from "../../../components/organisms/ToastProvider";
// import { toastRequest, toastReset } from "../../../redux/global/toast/action";
import { CONTACT } from "../../../services";
import { useState } from "react";
import { globalResetSubmit, globalSubmit } from "../../../redux/global/submit/action";


const LandingContactUs = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // const { addToast } = useToast()

  const { data, isLoading } = useSelector(state => state.contact)
  const submit = useSelector(state => state.globalSubmit)
  const dataToast = useSelector(state => state.toast)

  const [inputs, setInputs] = useState({
    email: null,
    name: null,
    subject: null,
    description: null,
  })

  const params = {
    service: CONTACT,
    data: inputs
  }

  useEffect(() => {
    dispatch(getContact())
    dispatch(globalResetSubmit())
  }, [])

  const onChange = (event) =>{
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value
    })
  }

  const onSubmit = () => {
    dispatch(globalSubmit(params))
  };

  return (
    <div className="bg-white">
      <HeroContent
        title={"contact us"}
        to={"contact-us"}
      />
      <LandingSection>
        <div className="w-content flex flex-col space-x-0 space-y-8 md:space-y-0 md:flex-row md:space-x-4 py-10 items-start text-left min-h-[15rem]">
          <motion.div initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 1 }}
            className="space-y-4 w-full"
          >
            <p className="uppercase font-semibold text-2xl">{t("contact form")}</p>
            <form className="flex flex-col space-y-4">
              <div className="flex flex-col  md:flex-row gap-4">
                <Input
                  className={"form-box w-full"}
                  placeholder={t("your name")}
                  name="name"
                  onChange={(event) => onChange(event)}
                />
                <Input
                  className={"form-box w-full"}
                  placeholder={t("email")}
                  name="email"
                  onChange={(event) => onChange(event)}
                />
              </div>
              <Input
                className={"form-box"}
                placeholder={t("subject")}
                name="subject"
                onChange={(event) => onChange(event)}
              />
              <Textarea
                className={"form-box"}
                placeholder={t("message")}
                name="description"
                onChange={(event) => onChange(event)}
                rows={"3"}
              ></Textarea>
            </form>
            <div className={submit.isSuccess ? "text-green-700" : "text-red-700"}>
              {submit?.message}
            </div>
            <Button
              onClick={() => onSubmit()}
              disabled={submit?.isLoading}
              buttonStyle="py-2 px-5 text-sm border-2 border-blue-600 bg-blue-600 text-white uppercase rounded"
            >
              <div className="flex">
                {submit?.isLoading &&
                  <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
                    {/* <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle> */}
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                }
                <div> {t("send message")}</div>

              </div>
            </Button>
          </motion.div>
          <motion.div initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 1 }}
            className="space-y-4 w-full"
          >
            <p className="uppercase font-semibold text-2xl">{t("contact details")}</p>
            <div className="bg-blue-600 space-y-4 p-6 rounded-sm">
              <div className="flex space-x-4 items-center">
                <svg className="w-1/6 min-h-fit text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                <div className="text-white w-full">
                  <p className="capitalize">{t("address")}</p>
                  <p>{data?.address}</p>
                </div>
              </div>
              <div className="flex space-x-4 items-center">
                <svg className="w-1/6 min-h-fit text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg>
                <div className="text-white w-full">
                  <p className="capitalize">{t("email")}</p>
                  <p>{data?.email}</p>
                </div>
              </div>
              <div className="flex space-x-4 items-center">
                {/* <Img src={baseUrl("/assets/svg/")} alt="" className="w-1/6 min-h-fit text-white" /> */}
                <svg className="w-1/6 min-h-fit text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" /></svg>
                <div className="text-white w-full">
                  <p className="capitalize">{t("phone number")}</p>
                  <p>{data?.phone}</p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </LandingSection>
    </div>
  );
};

export default LandingContactUs;
