import api from '../config/api'; //use for admin page
import apiLanding from '../config/apiLanding';

export const getList = (params) => {
  return apiLanding
  .get(`/about?lang=${params?.lang ?? 'en'}`)
}

// export const getList = (params) => {
//   return api
//   .get(`/about?page=${params?.pagination?.page ?? "1"}&size=${params?.pagination?.limit ?? "10"}${params?.sorting ? "&sortBy="+params?.sorting?.sortBy+"&sortType="+params?.sorting?.sortType:""}${params?.filter ? "&filters="+params?.filter:""}`)
// }

export const getDetail = (params) => {
  return api
  // .get(`/about/${params?.id ?? ''}`)
  .get(`/about`)
}

export const create = (params) => {
  return api
  .post(`/about`,params?.data)
}

export const update = (params) => {
  return api
  .put(`/about/update`,params?.data)
}

export const deleteList = (id) => {
  return api
  .put(`/about`,id)
}