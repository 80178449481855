import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Img from '../../../components/atoms/Img'
import LoadingSr from '../../../components/atoms/LoadingSr'
import LandingSection from '../../../components/templates/landing/Section'
import { apiUrl, baseUrl } from '../../../helpers/baseUrl'
import { getAssetsListService } from '../../../redux/admin/service/action'
import { motion } from 'framer-motion'

const Assets = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { dataAssets, isLoadingAssets } = useSelector(state => state.service)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang,
    type: 'assets'
  }

  useEffect(() => {
    dispatch(getAssetsListService(params))
  }, [lang]);

  return (
    <LandingSection className={"bg-gray-900 relative"}>
      <div className="p-5 space-x-6 flex flex-row w-content justify-center text-white">
        {isLoadingAssets ?
          _.map([0, 1, 2], (val, key) => (
            <div key={key} className="w-1/4 min-h-[5rem] flex flex-col md:justify-center space-y-2">
              <LoadingSr className="w-[5rem] h-[5rem]" />
              <LoadingSr className="w-32 h-4" />
              <LoadingSr className="w-28 h-4" />
            </div>
          ))
          :
          _.map(dataAssets, (val, key) => (
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ ease: "easeOut", duration: 0.5, delay: (1 / 8 * key) }}
              key={key}
              className="w-1/4 min-h-[5rem] flex flex-col md:justify-center"
            >
              <Img src={apiUrl("public/assets/img/service/" + val?.image)} alt="" className="h-[5rem]" />
              <div className="text-md md:text-2xl">{val?.title}</div>
              <div>{val?.description}</div>
            </motion.div>
          ))
        }
      </div>
      <div className="flex px-6 md:px-32 bg-blue-700 min-h-[5rem] items-center justify-center text-white">
        <div className="text-xl">{t("providing smart logistic solution across the world")}</div>
        {/* <button className="border-2 border-white px-8 py-1 uppercase text-sm">Get a quote</button> */}
      </div>
      <Img src={baseUrl("/assets/img/other/bg-01.jpg")} alt={""} className="absolute opacity-10 top-0 h-full object-cover md:w-full" />
    </LandingSection>
  )
}

export default Assets