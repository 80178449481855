import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { baseUrl } from '../../../helpers/baseUrl'
import { getFooterListService } from '../../../redux/admin/service/action'

const LandingFooter = ({
  data,
  isLoading
}) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const menu = [
    { url: "home", name: "home" },
    { url: "about-us", name: "about us" },
    { url: "services", name: "services" },
    { url: "location", name: "location" },
    { url: "news", name: "news" },
    { url: "career", name: "career" },
    { url: "contact-us", name: "contact us" },
  ]

  const { dataFooter, isLoadingFooter } = useSelector(state => state.service)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang,
    type: 'footer'
  }

  useEffect(() => {
    dispatch(getFooterListService(params))
  }, [lang]);

  return (
    <div className=" text-white px-2 py-6 space-y-6" style={{ backgroundImage: `url(${baseUrl('/assets/img/bg/bg-footer.png')})` }}>
      <div className="flex flex-col md:flex-row w-content">
        <div className="md:w-full m-6 space-y-4">
          <img src={baseUrl("/assets/svg/logo.svg")} alt="ilc" className="h-16" />
          <p>Logistics business under the supervision by ICT, General Product & Reality Company under ITOCHU Corporation</p>
        </div>
        <div className="md:w-full m-6">
          <p className="font-semibold text-xl mb-6 capitalize">{t("quick links")}</p>
          <div className="flex flex-col space-y-2">
            {/* <p>INVESTER RELATIONS</p>
            <p>PRESS & MEDIA</p>
            <p>CAREER OPENINGS</p>
            <p>OUR PARTNERS</p>
            <p>LEGAL NOTICE</p>
            <p>COOKIE POLICY</p>
            <p>TERMS & CONDITIONS</p> */}
            {
              _.map(menu, (val, key) => (
                // <div className="flex hover:bg-blue-900 px-3  md:items-center  h-full">({ isActive }) => (isActive ? 'active-menu' : 'menu')
                <NavLink key={key} to={`/${val.url === "home" ? "" : val?.url}`} className={({ isActive }) => (isActive ? 'active-header' : 'header')}>{t(val?.name)}</NavLink>
                // </div>
              ))
            }
          </div>
        </div>
        <div className="md:w-full m-6">
          <p className="font-semibold text-xl mb-6 capitalize">{t("services")}</p>
          <div className="space-y-2">
            {
              _.map(dataFooter, (val, key) => (
                <p key={key}>{val?.title}</p>
              ))
            }
            {/* <p>Standard Air Freight Services</p>
            <p>Sea Freight Services</p>
            <p>Full loads and part loads</p>
            <p>Specialized Transport</p>
            <p>Logistics Solutions</p>
            <p>Tailored Project Transport</p> */}
          </div>
        </div>
        <div className="md:w-full m-6">
          <p className="font-semibold text-xl mb-6 capitalize">{t("contact")}</p>
          <div className="space-y-2">
            <div>
              {data?.address}
            </div>
            <div>
              <p><label className="capitalize">{t("phone")}</label>: {data?.phone}</p>
              <p>Fax: {data?.fax}</p>
            </div>
            <p><label className="capitalize">{t("email")}</label>: {data?.email}</p>
          </div>
        </div>
      </div>
      <div className="text-center">Copyright &copy; ILC 2022. All rights reserved.</div>
    </div>
  )
}

export default LandingFooter