import React, { Children } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LandingSection from "../../../components/templates/landing/Section";
import HeroContent from "../section/HeroContent";
import LandingNewsList from "./list";
import LandingNewsSidebar from "./sidebar";


const LandingNews = ({
  children
}) => {
  const { t } = useTranslation();
  const { lang } = useSelector(state => state.other)

  return (
    <div className="bg-white">
      <HeroContent
        title={"news"}
        to={"news"}
      />
      <LandingSection className={"bg-white"}>
        <div className="w-content flex flex-col space-x-0 space-y-8 md:space-y-0 md:flex-row md:space-x-8 py-10 items-start text-left min-h-[15rem]">
          {/* <LandingNewsList/> */}
          {children}
          <LandingNewsSidebar />          
        </div>
      </LandingSection>
    </div>
  );
};

export default LandingNews;
