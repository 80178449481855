import { USERS_DATA_STATE } from "../../globalActionType";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: [],
  trigger: '',
  message: '',
}

const users = (state = initialState, action) => {
	switch (action.type) {
    case USERS_DATA_STATE.USERS_DATA:
      return {
          ...state,
          // trigger: action.type,
          isLoading: true,
          isSuccess: false,
          isError: false,
      };
    case USERS_DATA_STATE.USERS_DATA_FAILURE:
      return {
          ...state,
          isLoading: false,
          isError: true,
          message: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case USERS_DATA_STATE.USERS_DATA_SUCCESS:
      return {
          ...state,
          isLoading: false,
          isError: false,
          data: action.data.data,
      };
    default:
      return state;
	}
}

export default users;