import _ from 'lodash'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Img from '../../../components/atoms/Img'
import LoadingSr from '../../../components/atoms/LoadingSr'
import Preview from '../../../components/atoms/Preview'
import LandingSection from '../../../components/templates/landing/Section'
import { apiUrl, baseUrl } from '../../../helpers/baseUrl'
import { getGallery } from '../../../redux/admin/gallery/action'
import { motion } from 'framer-motion'

const LatestProject = ({
  disabledHeader,
  className
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [src, setSrc] = useState("/")

  const { data, isLoading } = useSelector(state => state.gallery)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang
  }

  useEffect(() => {
    dispatch(getGallery(params))
  }, [lang]);

  const handleClick = (value) => {
    // console.log("click")
    setIsOpen(true)
    setSrc(value)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <LandingSection className={`${className}`}>
      <div className="p-10 space-y-10">
        {
          !disabledHeader &&
          <div className="uppercase">
            <label>{t("gallery")}</label>
            <div className="text-2xl font-semibold">{t("our latest project")}</div>
          </div>
        }
        <div className="flex flex-wrap justify-center gap-2">
          {isLoading ?
            _.map([0, 1, 2, 3, 4], (val, key) => (
              <LoadingSr key={key} className="w-[11rem] h-[11rem]" />
            ))
            :
            _.map(data, (val, key) => (
              <motion.div
                initial={{ opacity: 0, y: -100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1.5, delay: (1/8 * key) }}
                key={key} className="w-[11rem] h-[11rem]">
                <Img onClick={() => handleClick(apiUrl(`public/assets/img/gallery/${val?.image}`))} src={apiUrl(`public/assets/img/gallery/${val?.image}`)} alt={val?.id} className="border-0 h-full object-cover" />
              </motion.div>
            ))
          }
        </div>
      </div>
      <Preview isOpen={isOpen} onClose={handleClose} title={"Preview"} src={src}>

      </Preview>
    </LandingSection>
  )
}

export default LatestProject