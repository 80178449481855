import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/atoms/Button";
import Img from "../../../components/atoms/Img";
import LandingSection from "../../../components/templates/landing/Section";
import { getCareer } from "../../../redux/admin/career/action";
import HeroContent from "../section/HeroContent";
import { Link, NavLink } from 'react-router-dom'
import { apiUrl, baseUrl } from "../../../helpers/baseUrl";
import { motion } from "framer-motion"


const LandingCareer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [id, SetId] = useState(null)

  const { data, isLoading } = useSelector(state => state.career)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang
  }

  useEffect(() => {
    dispatch(getCareer(params))
  }, [lang]);

  const handleClick = (value) => {
    if (id === value) {
      setIsOpen(!isOpen)
    } else {
      setIsOpen(true)
      SetId(value)
    }
  }

  return (
    <div className="bg-white">
      <HeroContent
        title={"career"}
        to={"career"}
      />
      {/* <LandingSection className={"bg-[#f5f7f9] h-full"}> */}
      <LandingSection>
        <div className="w-content flex flex-col space-x-0 space-y-16 md:space-y-0 md:flex-row md:space-x-16 py-10 text-left">
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            // animate={{ opacity: 1, x: 0 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 1 }}
            className="flex w-full md:w-2/3 min-h-full items-center"
          >
            <Img src={baseUrl(`/assets/img/bg/itochu.jpg`)} className="w-full" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ ease: "easeOut", duration: 1, }}
            className="space-y-4 w-full"
          >
            <p className="uppercase font-semibold text-2xl">{t("quality requirments")}</p>
            <p className="text-justify">
            {t("career.description-line-1")}
            </p>
            <p className="text-justify">
            {t("career.description-line-2")}
            </p>
            {/* <Button buttonStyle="py-3 px-6 border-2 border-blue-600 text-blue-600 uppercase rounded">know more</Button> */}
          </motion.div>
        </div>
      </LandingSection>
      <LandingSection>
        <div className="p-10 space-y-10 w-content">
          <motion.div
            initial={{ opacity: 0, }}
            whileInView={{ opacity: 1 }}
            transition={{ ease: "easeOut", duration: 2, }}
            viewport={{ once: true }}
            className="uppercase"
          >
            <label>{t("description")}</label>
            <div className="text-2xl font-semibold">{t("job requirements")}</div>
          </motion.div>
          <div className="flex flex-col space-y-4">
            {
              _.map(data, (val, key) => (
                <div key={key} className="shadow-md">
                  <motion.div
                    initial={{ opacity: 0, }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 1, delay: (1/2 * key) }}
                    viewport={{ once: true }}
                    onClick={() => handleClick(val.id)}
                    className="w-full bg-slate-50 border-2 rounded-sm text-left py-2 px-5 cursor-pointer"
                  >
                    <label className="capitalize text-xl font-semibold">{val?.title}</label>
                  </motion.div>
                  {isOpen && id === val.id &&
                    <>
                      <div className="whitespace-pre-line px-5 py-2 text-left border-2 border-t-0">
                        {val.description}
                        <p className="mt-5 ">
                          <a
                            href={val.url}
                            className="capitalize text-semibold text-blue-800"
                            target="_blank"
                            rel="noreferrer"
                          >
                            visit link
                          </a>
                        </p>
                      </div>
                    </>}
                </div>
              ))
            }
          </div>
        </div>
      </LandingSection>
    </div>
  );
};

export default LandingCareer;
