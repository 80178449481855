import _ from "lodash";
import React, { useEffect } from "react";
import moment from 'moment'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, NavLink } from "react-router-dom";
import Button from "../../../components/atoms/Button";
import Img from "../../../components/atoms/Img";
import { formatDate } from "../../../helpers/convertDate";
import { getNews } from "../../../redux/admin/news/action";
import { apiUrl } from "../../../helpers/baseUrl";
import { useMemo } from "react";
import { remappingFilter } from "../../../helpers/filterMapping";
import { useState } from "react";
import Loading from "../../../components/atoms/Loading";
import { setCategory } from "../../../redux/global/search/action";
import { paginationNews } from "../../../helpers/pagination";
import { motion } from "framer-motion"

const LandingNewsList = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [fetched, setFetched] = useState(false)
  const [filter, setFilter] = useState([])

  const { data, isLoading } = useSelector(state => state.news)
  const { lang } = useSelector(state => state.other)
  const { text, category } = useSelector(state => state.search)

  const [pagination, setPagination] = useState({
    page: 1, limit: 4
  })

  const params = {
    lang,
    pagination: pagination,
    filter: filter.join(",")
  }

  useEffect(() => {
    dispatch(getNews(params))
  }, [lang]);

  useEffect(() => {
    if (fetched) {
      dispatch(getNews(params))
      setFetched(false)
    }
  }, [fetched]);

  useEffect(() => {
    remappingFilter(setFilter, filter, text, 'title', "like")
    setPagination((prevState) => ({
      ...prevState,
      page: 1,
    }))
    setFetched(true)
  }, [text]);

  useEffect(() => {
    remappingFilter(setFilter, filter, category?.id, 'uniqIdCategory', "eq")
    setPagination((prevState) => ({
      ...prevState,
      page: 1,
    }))
    setFetched(true)
  }, [category?.id]);

  const handleClick = () => {
    dispatch(setCategory())
  }

  const handlePageChange = (page) => {
    setPagination({
      ...pagination,
      page: page
    })
    setFetched(true);
  };

  return isLoading ? <Loading className={"my-5"} /> : (
    <div className="space-y-10 w-full">
      {(category?.id || text) &&
        <div className="space-y-2">
          {category?.id &&
            <div className="flex">
              <div className="flex items-center space-x-2">
                <label>Category :</label>
                <div className="bg-blue-900 py-2 px-4 text-white rounded-3xl flex space-x-3">
                  <label>{category?.name}</label>
                  <p className="cursor-pointer" onClick={() => handleClick()}>X</p>
                </div>
              </div>
            </div>
          }
          {text && <div>Search : {text}</div>}
        </div>}
      {data?.data?.length ?
        _.map(data?.data, (val, key) => (
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ ease: "easeOut", duration: 1, delay: (1 / 2 * key) }}
            className="space-y-4"
            key={key}
          >
            <div className="bg-slate-900 flex justify-center h-[15rem] w-full">
              <Img src={apiUrl(`public/assets/img/news/${val?.image}`)} className="object-cover" />
            </div>
            <div className="space-y-1">
              <p className="uppercase font-semibold text-2xl border-b-2 py-2">{val?.title}</p>
              <div className="flex gap-8">
                <p className="capitalize text-slate-400">{formatDate(val?.createdAt, lang)}</p>
                <p className="capitalize text-slate-400">{val?.category?.title}</p>
                <p className="capitalize text-slate-400">update by Admin</p>
              </div>
            </div>
            <p
              className="text-slate-600 overflow-hidden max-h-12"
              dangerouslySetInnerHTML={{ __html: val?.description }}
            >
              {/* Established in the United States of America, Start.ly has operations all over the world. Its regional headquarter is located in Dubai which serves as the hub of Western Asia. We have in place a wide network of our own international offices and affiliates dedicated around the world. */}
            </p>
            <NavLink to={"/news/" + moment(val?.createdAt).format("YYYY-MM-DD/") + val?.slug} className=" inline-block py-3 px-6 border-2 border-blue-600 text-blue-600 uppercase rounded">{t("read more")}</NavLink>
          </motion.div>
          // )) : <div> Result for <b>{text} </b>is not found</div>
        )) : <div> Not found</div>
      }
      {data?.data?.length ? paginationNews(
        data?.pagination?.totalPages,
        data?.pagination?.currentPage,
        (value) => handlePageChange(value)
      ) : null}
    </div>
  )
}

export default LandingNewsList