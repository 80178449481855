import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import Img from '../../components/atoms/Img'
import Input from '../../components/atoms/Input'
import Label from '../../components/atoms/Label'
import Loading from '../../components/atoms/Loading'
import Textarea from '../../components/atoms/Textarea'
import CategoryNews from '../../components/organisms/options/CategoryNews'
// import { useToast } from '../../components/organisms/ToastProvider'
import { apiUrl } from '../../helpers/baseUrl'
import { getDetailNews } from '../../redux/admin/news/action'
// import { getDetailService } from '../../redux/admin/service/action'
// import { getDetailHero } from '../../redux/admin/hero/action'
import { submit } from '../../redux/admin/news/submit/action'
import { getLang } from '../../redux/global/translation/action'
import TextEditor from '../../components/molecules/TextEditor'
import Editor from '../../components/molecules/text-editor/Editor'

const FormNews = ({
  row,
  onClose,
  onSubmit
}) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [img, setImg] = useState(null)

  // const { lang } = useSelector(state => state.other)
  const { dataDetail, isLoadingDetail, isSuccessDetail } = useSelector(state => state.news)
  const dataToast = useSelector(state => state.toast);
  const { alert, message, isLoading } = useSelector(state => state.newsSubmit)

  // const { addToast } = useToast()
  const [activeTab, setActiveTab] = useState('en')

  let [formData, setFormData] = useState(new FormData())
  const [inputs, setInputs] = useState({
    image: row?.image ?? null,
    uniqIdCategory: row?.uniqIdCategory ?? null,
    en: {
      title: row?.title ?? '',
      description: row?.description ?? "<p><br></p>"
    },
    jp: {
      title: row?.title ?? '',
      description: row?.description ?? "<p><br></p>"
    },
    id: {
      title: row?.title ?? '',
      description: row?.description ?? "<p><br></p>"
    }
  })

  const params = {
    id: row?.uniqId,
    data: formData,
    type: row ? 'edit' : 'create'
  }

  useEffect(() => {
    i18n.changeLanguage(activeTab)
  }, [i18n, activeTab]);

  useEffect(() => {
    if (row?.id) {
      dispatch(getDetailNews(params))
    }
  }, [row?.id]);

  useEffect(() => {
    if (isSuccessDetail) {
      setInputs(dataDetail)
    }
  }, [isSuccessDetail]);

  const onChangeTab = (lang) => {
    setActiveTab(lang)
    dispatch(getLang(lang))
  }

  const handleChange = (event) => {
    if (!event.target.files) {
      if (event.target.name !== "uniqIdCategory") {
        setInputs({
          ...inputs,
          [activeTab]: {
            ...inputs[activeTab],
            [event.target.name]: event.target.value
          }
        })
      } else {
        setInputs({
          ...inputs,
          [event.target.name]: event.target.value
        })
      }
    }
    if (event.target.files) {
      setImg(URL.createObjectURL(event.target.files[0]))
      setInputs({
        ...inputs,
        [event.target.name]: event.target.files[0]
      })
    }
  }

  console.log(inputs.en.description)
  const handleSubmit = () => {
    setFormData(new FormData())
    for (var key in inputs) {
      if (key === 'en' || key === 'jp' || key === 'id') {
        for (let previewKey in inputs[key]) {
          formData.append(`${previewKey}[${key}]`, inputs[key][previewKey]);
        }
      } else {
        formData.append(key, inputs[key]);
      }
    }
    // console.log(inputs)
    dispatch(submit(params))
    onSubmit()
  }

  const handleClose = () => {
    i18n.changeLanguage('en')
    onClose()
  }

  return !isSuccessDetail && row ? <Loading /> : (
    <form className="flex flex-col px-8">
      <div className="flex flex-col space-y-6 md:w-[55rem]">
        <div className="form-group">
          <Label className="md:w-1/3 md:text-left">Image</Label>
          <div className="flex items-center w-full md:w-2/3 space-x-4 bg-slate-300 border-2 rounded overflow-hidden">
            <label
              htmlFor="file-upload"
              className="relative w-full h-40 cursor-pointer bg-transparent font-medium text-indigo-600"
            >
              <span className="flex justify-center w-full h-40 overflow-hidden bg-gray-100 absolute">
                {
                  img || dataDetail.image ? <Img src={img ?? apiUrl("public/assets/img/service/" + dataDetail?.image)} className="h-40 text-center" />
                    :
                    <svg className="h-full w-full text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                }
              </span>
              <input id="file-upload" onChange={(event) => handleChange(event)} name="image" type="file" className="sr-only" />
            </label>
          </div>
        </div>
        <div className="form-group">
          <Label className="md:w-1/3 md:text-left">category</Label>
          <CategoryNews
            className={"w-full md:w-2/3 form-box"}
            onSelect={(event) => handleChange(event)}
            name={"uniqIdCategory"}
            value={inputs.uniqIdCategory}
          />
        </div>
        <div className="form-group border-b-2 pl-4">
          <div className="flex space-x-4 text-slate-400 font-semibold -mb-[0.12rem]">
            <div onClick={() => onChangeTab('en')} className={activeTab === "en" ? "active-tab" : "tab"}>English</div>
            <div onClick={() => onChangeTab('jp')} className={activeTab === "jp" ? "active-tab" : "tab"}>Japan</div>
            <div onClick={() => onChangeTab('id')} className={activeTab === "id" ? "active-tab" : "tab"}>Indonesia</div>
          </div>
        </div>
        <div className="form-group">
          <Label className="md:w-1/3 md:text-left">title</Label>
          <Input
            className="w-full md:w-2/3 form-box"
            type="text"
            name={"title"}
            placeholder={t("title")}
            onChange={(event) => handleChange(event)}
            value={inputs[activeTab].title}
          />
        </div>
        <div className="form-group">
          <Label className="md:w-1/3 md:text-left">description</Label>
          {/* <TextEditor
            onChange={(event) => handleChange(event)}
            rows={5}
            value={inputs[activeTab].description}
            placeholder={t("description")}
          /> */}
          <Editor
            onChange={(event) => handleChange(event)}
            value={inputs[activeTab].description}
            placeholder={t("description")}
          />
          {/* <Textarea
            name={"description"}
            className="w-full md:w-2/3 form-box"
            placeholder={t("description")}
            onChange={(event) => handleChange(event)}
            rows={5}
            value={inputs[activeTab].description}
          >
          </Textarea> */}
        </div>
      </div>
      {isLoading && <Loading className={"mt-4"} />}
      <div className='text-right pt-5'>
        <Button type={"button"}
          buttonStyle={"btn-primary text-sm p-1 mr-4"}
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          Submit
        </Button>
        <Button type={"button"}
          buttonStyle={"btn-secondary text-sm p-1"}
          onClick={() => handleClose()}
          disabled={isLoading}
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}

export default FormNews