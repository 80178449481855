import React from 'react'
import Img from '../../../components/atoms/Img';
import LandingSection from '../../../components/templates/landing/Section'
import { baseUrl } from '../../../helpers/baseUrl';
import About from '../section/About';
import { useTranslation } from 'react-i18next';
import Hero from '../section/Hero';
import Services from '../section/Services';
import Assets from '../section/Assets';
import OurGroups from '../section/OurGroups';
import WhatNews from '../section/WhatNews';
import LatestProject from '../section/LatestProject';
import HeadServices from '../section/HeadServices';


const LandingHome = () => {
  const {t} = useTranslation();
  return (
    <div className="bg-white">
      <Hero/>
      <HeadServices />
      <Services />
      <OurGroups />     
      <Assets />
      <About />   
      <WhatNews />
      <LatestProject className={"bg-slate-100 "} />      
    </div>
  )
}

export default LandingHome