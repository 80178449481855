import { GALLERY } from "../../../../services";
import { toastRequest, toastReset } from "../../../global/toast/action";
import { GALLERY_SUBMIT_STATE } from "../../../globalActionType";

export const submit = (params) => {
  function request() { return { type: GALLERY_SUBMIT_STATE.GALLERY_SUBMIT } }
  function success(data) { return { type: GALLERY_SUBMIT_STATE.GALLERY_SUBMIT_SUCCESS, data } }
  function failure(data) { return { type: GALLERY_SUBMIT_STATE.GALLERY_SUBMIT_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    dispatch(toastRequest())
    try {
      // console.log(params?.type)    
      const response = params?.type === 'edit' ?
        await GALLERY.update(params) : await GALLERY.create(params)
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data))
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
    dispatch(toastReset())
  }
};

export const deleteList = (params) => {
  function request() { return { type: GALLERY_SUBMIT_STATE.GALLERY_SUBMIT } }
  function success(data) { return { type: GALLERY_SUBMIT_STATE.GALLERY_SUBMIT_SUCCESS, data } }
  function failure(data) { return { type: GALLERY_SUBMIT_STATE.GALLERY_SUBMIT_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    dispatch(toastRequest())
    try {
      const response = await GALLERY.deleteList(params)
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data))
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
    dispatch(toastReset())
  }
};

export const resetSubmit = () => (dispatch) => {
  dispatch({
    type: GALLERY_SUBMIT_STATE.GALLERY_SUBMIT_RESET
  })
}