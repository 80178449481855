import React from 'react'
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from 'react-router-dom';
import Img from '../../../components/atoms/Img'
import Loading from '../../../components/atoms/Loading';
import { apiUrl } from '../../../helpers/baseUrl';
import { formatDate } from '../../../helpers/convertDate'
import { getDetailNewsCms } from '../../../redux/admin/news/action';

const LandingNewsDetail = () => {
  // let [searchParams, setSearchParams] = useSearchParams();
  const baseParams = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // const slug = window.location.href.split("/").pop();
  const slug = baseParams['*'].split("/").pop();

  const { dataDetail, isLoadingDetail } = useSelector(state => state.news)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang,
    slug
  }
  // console.log(slug)
  useEffect(() => {
    dispatch(getDetailNewsCms(params))
  }, [lang, slug]);

  return isLoadingDetail ? <Loading className={"my-5"} /> : (
    <div className="w-full space-y-10">
      <div className="space-y-4">
        <div className="bg-blue-300 flex justify-center w-full">
        <Img src={apiUrl(`public/assets/img/news/${dataDetail?.image}`)} className="object-cover" />
        </div>
        <div className="space-y-1">
          <p className="uppercase font-semibold text-2xl border-b-2 py-2">{dataDetail?.title}</p>
          <div className="flex gap-8">
            <p className="capitalize text-slate-400">{formatDate(dataDetail?.createdAt, lang)}</p>
            <p className="capitalize text-slate-400">{dataDetail?.category?.title}</p>
            <p className="capitalize text-slate-400">update by Admin</p>
          </div>
        </div>
        <div
          className="text-slate-600"
          dangerouslySetInnerHTML={{ __html: dataDetail?.description }}
        >
        </div>
      </div>
    </div>
  )
}

export default LandingNewsDetail