export const LOGIN_STATE = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  PROFILE_UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",
  RESET: "RESET",
};

export const USERS_DATA_STATE = {
  USERS_DATA: "USERS_DATA",
  USERS_DATA_SUCCESS: "USERS_DATA_SUCCESS",
  USERS_DATA_FAILURE: "USERS_DATA_FAILURE",
};

export const USERS_SUBMIT_STATE = {
  USERS_SUBMIT_DATA: 'USERS_SUBMIT_DATA',
  USERS_SUBMIT_DATA_SUCCESS: 'USERS_SUBMIT_DATA_SUCCESS',
  USERS_SUBMIT_DATA_FAILURE: 'USERS_SUBMIT_DATA_FAILURE',
  USERS_SUBMIT_DATA_RESET: 'USERS_SUBMIT_DATA_RESET'
}

export const REGISTER_STATE = {
  REGISTER: "REGISTER",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",

  SEND_MAIL: "SEND_MAIL",
  SEND_MAIL_SUCCESS: "SEND_MAIL_SUCCESS",
  SEND_MAIL_FAILURE: "SEND_MAIL_FAILURE",

  CONFIRM: "CONFIRM",
  CONFIRM_SUCCESS: "CONFIRM_SUCCESS",
  CONFIRM_FAILURE: "CONFIRM_FAILURE",

  VERIFICATION: "VERIFICATION",
  VERIFICATION_SUCCESS: "VERIFICATION_SUCCESS",
  VERIFICATION_FAILURE: "VERIFICATION_FAILURE",
};

export const BASEMENU_STATE = {
  BASEMENU_DATA: "BASEMENU_DATA",
  BASEMENU_DATA_SUCCESS: "BASEMENU_DATA_SUCCESS",
  BASEMENU_DATA_FAILURE: "BASEMENU_DATA_FAILURE",
};

export const ROLE_STATE = {
  ROLE_DATA: "ROLE_DATA",
  ROLE_DATA_SUCCESS: "ROLE_DATA_SUCCESS",
  ROLE_DATA_FAILURE: "ROLE_DATA_FAILURE",
  ROLE_DATA_RESET: "ROLE_DATA_RESET",
};

export const ROLE_SUBMIT_STATE = {
  ROLE_SUBMIT_DATA: "ROLE_SUBMIT_DATA",
  ROLE_SUBMIT_DATA_SUCCESS: "ROLE_SUBMIT_DATA_SUCCESS",
  ROLE_SUBMIT_DATA_FAILURE: "ROLE_SUBMIT_DATA_FAILURE",
  ROLE_SUBMIT_DATA_RESET: "ROLE_SUBMIT_DATA_RESET",
};

export const PRIVILEGE_DATA_STATE = {
  PRIVILEGE_DATA: "PRIVILEGE_DATA",
  PRIVILEGE_DATA_SUCCESS: "PRIVILEGE_DATA_SUCCESS",
  PRIVILEGE_DATA_FAILURE: "PRIVILEGE_DATA_FAILURE",
  PRIVILEGE_DATA_RESET: "PRIVILEGE_DATA_RESET",
};

export const NEWS_DATA_STATE = {
  NEWS_DATA: "NEWS_DATA",
  NEWS_DATA_SUCCESS: "NEWS_DATA_SUCCESS",
  NEWS_DATA_FAILURE: "NEWS_DATA_FAILURE",
  NEWS_DATA_RESET: "NEWS_DATA_RESET",

  RECENT_NEWS_DATA: "RECENT_NEWS_DATA",
  RECENT_NEWS_DATA_SUCCESS: "RECENT_NEWS_DATA_SUCCESS",
  RECENT_NEWS_DATA_FAILURE: "RECENT_NEWS_DATA_FAILURE",
  RECENT_NEWS_DATA_RESET: "RECENT_NEWS_DATA_RESET",

  NEWS_DETAIL_DATA: "NEWS_DETAIL_DATA",
  NEWS_DETAIL_DATA_SUCCESS: "NEWS_DETAIL_DATA_SUCCESS",
  NEWS_DETAIL_DATA_FAILURE: "NEWS_DETAIL_DATA_FAILURE",
  NEWS_DETAIL_DATA_RESET: "NEWS_DETAIL_DATA_RESET",
};

export const CAREER_DATA_STATE = {
  CAREER_DATA: "CAREER_DATA",
  CAREER_DATA_SUCCESS: "CAREER_DATA_SUCCESS",
  CAREER_DATA_FAILURE: "CAREER_DATA_FAILURE",
  CAREER_DATA_RESET: "CAREER_DATA_RESET",

  CAREER_DETAIL_DATA: "CAREER_DETAIL_DATA",
  CAREER_DETAIL_DATA_SUCCESS: "CAREER_DETAIL_DATA_SUCCESS",
  CAREER_DETAIL_DATA_FAILURE: "CAREER_DETAIL_DATA_FAILURE",
  CAREER_DETAIL_DATA_RESET: "CAREER_DETAIL_DATA_RESET",
};

export const CATEGORY_DATA_STATE = {
  CATEGORY_DATA: "CATEGORY_DATA",
  CATEGORY_DATA_SUCCESS: "CATEGORY_DATA_SUCCESS",
  CATEGORY_DATA_FAILURE: "CATEGORY_DATA_FAILURE",
  CATEGORY_DATA_RESET: "CATEGORY_DATA_RESET",

  CATEGORY_DETAIL_DATA: "CATEGORY_DETAIL_DATA",
  CATEGORY_DETAIL_DATA_SUCCESS: "CATEGORY_DETAIL_DATA_SUCCESS",
  CATEGORY_DETAIL_DATA_FAILURE: "CATEGORY_DETAIL_DATA_FAILURE",
  CATEGORY_DETAIL_DATA_RESET: "CATEGORY_DETAIL_DATA_RESET",
};

export const CONTACT_DATA_STATE = {
  CONTACT_DATA: "CONTACT_DATA",
  CONTACT_DATA_SUCCESS: "CONTACT_DATA_SUCCESS",
  CONTACT_DATA_FAILURE: "CONTACT_DATA_FAILURE",
  CONTACT_DATA_RESET: "CONTACT_DATA_RESET",

  CONTACT_DETAIL_DATA: "CONTACT_DETAIL_DATA",
  CONTACT_DETAIL_DATA_SUCCESS: "CONTACT_DETAIL_DATA_SUCCESS",
  CONTACT_DETAIL_DATA_FAILURE: "CONTACT_DETAIL_DATA_FAILURE",
  CONTACT_DETAIL_DATA_RESET: "CONTACT_DETAIL_DATA_RESET",
};

export const SERVICE_DATA_STATE = {
  SERVICE_DATA: "SERVICE_DATA",
  SERVICE_DATA_SUCCESS: "SERVICE_DATA_SUCCESS",
  SERVICE_DATA_FAILURE: "SERVICE_DATA_FAILURE",
  SERVICE_DATA_RESET: "SERVICE_DATA_RESET",

  OTHER_SERVICE_DATA: "OTHER_SERVICE_DATA",
  OTHER_SERVICE_DATA_SUCCESS: "OTHER_SERVICE_DATA_SUCCESS",
  OTHER_SERVICE_DATA_FAILURE: "OTHER_SERVICE_DATA_FAILURE",
  OTHER_SERVICE_DATA_RESET: "OTHER_SERVICE_DATA_RESET",

  ASSETS_SERVICE_DATA: "ASSETS_SERVICE_DATA",
  ASSETS_SERVICE_DATA_SUCCESS: "ASSETS_SERVICE_DATA_SUCCESS",
  ASSETS_SERVICE_DATA_FAILURE: "ASSETS_SERVICE_DATA_FAILURE",
  ASSETS_SERVICE_DATA_RESET: "ASSETS_SERVICE_DATA_RESET",

  FOOTER_SERVICE_DATA: "FOOTER_SERVICE_DATA",
  FOOTER_SERVICE_DATA_SUCCESS: "FOOTER_SERVICE_DATA_SUCCESS",
  FOOTER_SERVICE_DATA_FAILURE: "FOOTER_SERVICE_DATA_FAILURE",
  FOOTER_SERVICE_DATA_RESET: "FOOTER_SERVICE_DATA_RESET",

  SERVICE_DETAIL_DATA: "SERVICE_DETAIL_DATA",
  SERVICE_DETAIL_DATA_SUCCESS: "SERVICE_DETAIL_DATA_SUCCESS",
  SERVICE_DETAIL_DATA_FAILURE: "SERVICE_DETAIL_DATA_FAILURE",
  SERVICE_DETAIL_DATA_RESET: "SERVICE_DETAIL_DATA_RESET",
};

//CMS
export const HERO_DATA_STATE = {
  HERO_DATA: "HERO_DATA",
  HERO_DATA_SUCCESS: "HERO_DATA_SUCCESS",
  HERO_DATA_FAILURE: "HERO_DATA_FAILURE",
  HERO_DATA_RESET: "HERO_DATA_RESET",

  HERO_DETAIL_DATA: "HERO_DETAIL_DATA",
  HERO_DETAIL_DATA_SUCCESS: "HERO_DETAIL_DATA_SUCCESS",
  HERO_DETAIL_DATA_FAILURE: "HERO_DETAIL_DATA_FAILURE",
  HERO_DETAIL_DATA_RESET: "HERO_DETAIL_DATA_RESET",
};

export const LOCATION_DATA_STATE = {
  LOCATION_DATA: "LOCATION_DATA",
  LOCATION_DATA_SUCCESS: "LOCATION_DATA_SUCCESS",
  LOCATION_DATA_FAILURE: "LOCATION_DATA_FAILURE",
  LOCATION_DATA_RESET: "LOCATION_DATA_RESET",

  LOCATION_DETAIL_DATA: "LOCATION_DETAIL_DATA",
  LOCATION_DETAIL_DATA_SUCCESS: "LOCATION_DETAIL_DATA_SUCCESS",
  LOCATION_DETAIL_DATA_FAILURE: "LOCATION_DETAIL_DATA_FAILURE",
  LOCATION_DETAIL_DATA_RESET: "LOCATION_DETAIL_DATA_RESET",
};

export const ABOUT_DATA_STATE = {
  ABOUT_DATA: "ABOUT_DATA",
  ABOUT_DATA_SUCCESS: "ABOUT_DATA_SUCCESS",
  ABOUT_DATA_FAILURE: "ABOUT_DATA_FAILURE",
  ABOUT_DATA_RESET: "ABOUT_DATA_RESET",

  ABOUT_DETAIL_DATA: "ABOUT_DETAIL_DATA",
  ABOUT_DETAIL_DATA_SUCCESS: "ABOUT_DETAIL_DATA_SUCCESS",
  ABOUT_DETAIL_DATA_FAILURE: "ABOUT_DETAIL_DATA_FAILURE",
  ABOUT_DETAIL_DATA_RESET: "ABOUT_DETAIL_DATA_RESET",
};

export const GALLERY_DATA_STATE = {
  GALLERY_DATA: "GALLERY_DATA",
  GALLERY_DATA_SUCCESS: "GALLERY_DATA_SUCCESS",
  GALLERY_DATA_FAILURE: "GALLERY_DATA_FAILURE",
  GALLERY_DATA_RESET: "GALLERY_DATA_RESET",
};

export const OUR_GROUP_DATA_STATE = {
  OUR_GROUP_DATA: "OUR_GROUP_DATA",
  OUR_GROUP_DATA_SUCCESS: "OUR_GROUP_DATA_SUCCESS",
  OUR_GROUP_DATA_FAILURE: "OUR_GROUP_DATA_FAILURE",
  OUR_GROUP_DATA_RESET: "OUR_GROUP_DATA_RESET",
};

export const DATA_TABLE_STATE = {
  DATA_TABLE: "DATA_TABLE",
  DATA_TABLE_SUCCESS: "DATA_TABLE_SUCCESS",
  DATA_TABLE_FAILURE: "DATA_TABLE_FAILURE",
};

export const DATA_SUBMIT_STATE = {
  DATA_SUBMIT: "DATA_SUBMIT",
  DATA_SUBMIT_SUCCESS: "DATA_SUBMIT_SUCCESS",
  DATA_SUBMIT_FAILURE: "DATA_SUBMIT_FAILURE",
  DATA_SUBMIT_RESET: "DATA_SUBMIT_RESET",
};

//INDIVIDUAL SUBMIT STATE
export const PROFILE_SUBMIT_STATE = {
  PROFILE_SUBMIT: "PROFILE_SUBMIT",
  PROFILE_SUBMIT_SUCCESS: "PROFILE_SUBMIT_SUCCESS",
  PROFILE_SUBMIT_FAILURE: "PROFILE_SUBMIT_FAILURE",
  PROFILE_SUBMIT_RESET: "PROFILE_SUBMIT_RESET",
};

export const HERO_SUBMIT_STATE = {
  HERO_SUBMIT: "HERO_SUBMIT",
  HERO_SUBMIT_SUCCESS: "HERO_SUBMIT_SUCCESS",
  HERO_SUBMIT_FAILURE: "HERO_SUBMIT_FAILURE",
  HERO_SUBMIT_RESET: "HERO_SUBMIT_RESET",
};

export const SERVICE_SUBMIT_STATE = {
  SERVICE_SUBMIT: "SERVICE_SUBMIT",
  SERVICE_SUBMIT_SUCCESS: "SERVICE_SUBMIT_SUCCESS",
  SERVICE_SUBMIT_FAILURE: "SERVICE_SUBMIT_FAILURE",
  SERVICE_SUBMIT_RESET: "SERVICE_SUBMIT_RESET",
};

export const CAREER_SUBMIT_STATE = {
  CAREER_SUBMIT: "CAREER_SUBMIT",
  CAREER_SUBMIT_SUCCESS: "CAREER_SUBMIT_SUCCESS",
  CAREER_SUBMIT_FAILURE: "CAREER_SUBMIT_FAILURE",
  CAREER_SUBMIT_RESET: "CAREER_SUBMIT_RESET",
};

export const CATEGORY_SUBMIT_STATE = {
  CATEGORY_SUBMIT: "CATEGORY_SUBMIT",
  CATEGORY_SUBMIT_SUCCESS: "CATEGORY_SUBMIT_SUCCESS",
  CATEGORY_SUBMIT_FAILURE: "CATEGORY_SUBMIT_FAILURE",
  CATEGORY_SUBMIT_RESET: "CATEGORY_SUBMIT_RESET",
};

export const LOCATION_SUBMIT_STATE = {
  LOCATION_SUBMIT: "LOCATION_SUBMIT",
  LOCATION_SUBMIT_SUCCESS: "LOCATION_SUBMIT_SUCCESS",
  LOCATION_SUBMIT_FAILURE: "LOCATION_SUBMIT_FAILURE",
  LOCATION_SUBMIT_RESET: "LOCATION_SUBMIT_RESET",
};

export const ABOUT_SUBMIT_STATE = {
  ABOUT_SUBMIT: "ABOUT_SUBMIT",
  ABOUT_SUBMIT_SUCCESS: "ABOUT_SUBMIT_SUCCESS",
  ABOUT_SUBMIT_FAILURE: "ABOUT_SUBMIT_FAILURE",
  ABOUT_SUBMIT_RESET: "ABOUT_SUBMIT_RESET",
};

export const OUR_GROUP_SUBMIT_STATE = {
  OUR_GROUP_SUBMIT: "OUR_GROUP_SUBMIT",
  OUR_GROUP_SUBMIT_SUCCESS: "OUR_GROUP_SUBMIT_SUCCESS",
  OUR_GROUP_SUBMIT_FAILURE: "OUR_GROUP_SUBMIT_FAILURE",
  OUR_GROUP_SUBMIT_RESET: "OUR_GROUP_SUBMIT_RESET",
};

export const GALLERY_SUBMIT_STATE = {
  GALLERY_SUBMIT: "GALLERY_SUBMIT",
  GALLERY_SUBMIT_SUCCESS: "GALLERY_SUBMIT_SUCCESS",
  GALLERY_SUBMIT_FAILURE: "GALLERY_SUBMIT_FAILURE",
  GALLERY_SUBMIT_RESET: "GALLERY_SUBMIT_RESET",
};

export const CONTACT_SUBMIT_STATE = {
  CONTACT_SUBMIT: "CONTACT_SUBMIT",
  CONTACT_SUBMIT_SUCCESS: "CONTACT_SUBMIT_SUCCESS",
  CONTACT_SUBMIT_FAILURE: "CONTACT_SUBMIT_FAILURE",
  CONTACT_SUBMIT_RESET: "CONTACT_SUBMIT_RESET",
};

export const NEWS_SUBMIT_STATE = {
  NEWS_SUBMIT: "NEWS_SUBMIT",
  NEWS_SUBMIT_SUCCESS: "NEWS_SUBMIT_SUCCESS",
  NEWS_SUBMIT_FAILURE: "NEWS_SUBMIT_FAILURE",
  NEWS_SUBMIT_RESET: "NEWS_SUBMIT_RESET",
};

export const PRIVILEGE_SUBMIT_STATE = {
  PRIVILEGE_SUBMIT: "PRIVILEGE_SUBMIT",
  PRIVILEGE_SUBMIT_SUCCESS: "PRIVILEGE_SUBMIT_SUCCESS",
  PRIVILEGE_SUBMIT_FAILURE: "PRIVILEGE_SUBMIT_FAILURE",
  PRIVILEGE_SUBMIT_RESET: "PRIVILEGE_SUBMIT_RESET",
};

export const LANG_STATE = {
  CHANGE_LANG_DATA: "CHANGE_LANG_DATA",
};

export const SEARCH_DATA_STATE = {
  SEARCH_DATA: "SEARCH_DATA",
  SEARCH_CATEGORY_DATA: "SEARCH_CATEGORY_DATA",
  SET_FECHED: "SET_FECHED",
  // SEARCH_DATA_SUCCESS: "SEARCH_DATA_SUCCESS",
  // SEARCH_DATA_FAILURE: "SEARCH_DATA_FAILURE",
};

export const TOAST_STATE = {
  TOAST_OPEN: "TOAST_OPEN",
  TOAST_CLOSE: "TOAST_CLOSE",
  TOAST_FAILURE: "TOAST_FAILURE",
};

export const OTHERS_STATE = {
  GET_MOUSE_POSITION: "GET_MOUSE_POSITION"
};
