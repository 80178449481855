import { OTHERS_STATE } from "../../globalActionType";

const initialState = {
  coordinate:''
};

const others = (state = initialState, action) => {
  switch (action.type) {
    case OTHERS_STATE.GET_MOUSE_POSITION:
      return {
        ...state,
        coordinate: action.data,
      };
    default:
      return state;
  }
};

export default others;
