import _ from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import LandingSection from '../../../components/templates/landing/Section'
import { formatDate } from '../../../helpers/convertDate'
import { getNews } from '../../../redux/admin/news/action'
import { motion } from 'framer-motion'

const WhatNews = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data, isLoading } = useSelector(state => state.news)
  const { lang } = useSelector(state => state.other)

  const params = {
    lang,
    // type: 'main'
    pagination: {
      limit: 5
    }
  }

  useEffect(() => {
    dispatch(getNews(params))
  }, [lang]);

  const handleOnClick = (data) => {
    // console.log(data)
    navigate("/news/" + data, { replace: true })
  }
  return (
    <LandingSection className={"bg-white flex-row justify-center"}>
      
      <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ ease: "easeOut", duration: 1.5, }}
          className="w-content text-2xl font-semibold uppercase text-left"
        >{t("news release")}</motion.div>
      <div className="overflow-x-scroll w-full">
        <div className="w-[150%] md:w-[75rem] w-content space-y-10 px-10 pb-10">
          <div className="flex flex-row md:w-full justify-between items-center text-left">
            {/* <div className="flex flex-row space-x-3 text-white">
              <div className="text-blue-800">years</div>
              <div className="bg-blue-800 px-2 rounded-sm">2019</div>
              <div className="bg-blue-800 px-2 rounded-sm">2020</div>
              <div className="bg-blue-800 px-2 rounded-sm">2021</div>
              <div className="bg-blue-800 px-2 rounded-sm">2022</div>
            </div> */}
          </div>
          <div className="min-h-[5rem] text-left p-2">
            <table className="table-auto custom-news w-full">
              {/* <thead>
                <tr>
                  <th>date</th>
                  <th>tags</th>
                  <th>title</th>
                </tr>
              </thead> */}
              <tbody>
                {_.map(data?.data, (val, key) => (
                  <motion.tr
                    initial={{ opacity: 0, x: 200 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ type: "spring", duration: 2, delay: (1 / 8 * key) }}
                    key={key} onClick={() => handleOnClick(moment(val?.createdAt).format("YYYY-MM-DD/") + val?.slug)} className="cursor-pointer hover:text-blue-900 hover:bg-slate-100">
                    {/* <NavLink className="w-full" to={"/news/"+moment(Date.now()).format("YYYY-MM-DD")+"/detail-news-title-"+(val+1)}> */}
                    <td className="md:w-1/6">{formatDate(val?.createdAt, lang)}</td>
                    <td className="md:w-1/6">{val?.category?.title && <div className="tags w-fit text-center">{val?.category?.title}</div>}</td>
                    <td className="capitalize md:w-full">{val?.title}</td>
                    {/* </NavLink> */}
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </LandingSection >
  )
}

export default WhatNews