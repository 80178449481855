import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getMousePosition } from '../../redux/global/others/action'

const ActionRows = ({
  children
}) => {
  const dispatch = useDispatch()
  const [coord, setCoord] = useState({ x: 0, y: 0 })
  // const [coords, setCoords] = useState({ x: 0, y: 0 })

  const onMouseMove = (event) => {
    setCoord({ x: event.pageX, y: event.pageY })
  }
  const handleClick = () => {
    // console.log("click")
    dispatch(getMousePosition(coord))
  }


  return (
    <div 
    onMouseMove={(event) => onMouseMove(event)} 
    onClick={() => handleClick()}>
      {children}
    </div>
  )
}

export default ActionRows