import { AUTH } from '../../../services';
import { LOGIN_STATE } from '../../globalActionType';

export const login = (params) => {
  function request(data) { return { type: LOGIN_STATE.LOGIN,data } }
  function success(data, token) { return { type: LOGIN_STATE.LOGIN_SUCCESS, data, token } }
  function failure(data) { return { type: LOGIN_STATE.LOGIN_FAILURE, data } }
  // function fails() {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(function () {
  //       reject(new Error());
  //     }, 6000);
  //   });
  // }


  return async (dispatch, getState) => {
    dispatch(request(params));
    try {
      const response = await AUTH.login(params)
      // await fails()
      const data = response?.data ?? {}
      const token = response?.token ?? {}
      if (data.meta.success) {
        dispatch(success(data, token))
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
  }
}

export const logout = () => {
  function request() { return { type: "LOGOUT_SUCCESS" } }
  return dispatch => {
    dispatch(request())
  };
}

export const reset = () => {
  function request() { return { type: LOGIN_STATE.RESET } }
  return dispatch => {
    dispatch(request())
  };
}

export const getRefreshToken = (token) => (dispatch) => {
  dispatch({
    type: LOGIN_STATE.REFRESH_TOKEN,
    payload: token
  })
}