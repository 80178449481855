import { PRIVILEGE_DATA_STATE } from "../../globalActionType";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: [],
  index: [],
  message: '',
}

const privilege = (state = initialState, action) => {
	switch (action.type) {
    case PRIVILEGE_DATA_STATE.PRIVILEGE_DATA:
      return {
          ...state,
          isLoading: true,
          isSuccess: false,
          isError: false,
      };
    case PRIVILEGE_DATA_STATE.PRIVILEGE_DATA_FAILURE:
      return {
          ...state,
          isLoading: false,
          isSuccess: false,
          isError: true,
          message: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case PRIVILEGE_DATA_STATE.PRIVILEGE_DATA_SUCCESS:
      return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: action.data.data,
          index: action.data.index
      };
    case PRIVILEGE_DATA_STATE.PRIVILEGE_DATA_RESET:
      return {
          state,
      };
    default:
      return state;
	}
}

export default privilege;