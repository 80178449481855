import React, { useEffect, useRef, useState } from 'react'
import Img from './Img';
import { motion } from 'framer-motion'

const Preview = ({
  isOpen,
  onClose,
  children,
  title,
  actionsButton,
  src,
  alt
}) => {

  // const [isOpen , setisOpen] = useState(false)
  const ref = useRef();

  useEffect(() => {
    if (onClose) {
      const checkIfClickedOutside = e => {
        // If the menu is isOpen and the clicked target is not within the menu,
        // then onClose the menu
        if (isOpen && ref.current && !ref.current.contains(e.target)) {
          // setisOpen(false)
          onClose()
        }
      }
      document.addEventListener("mousedown", checkIfClickedOutside)
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside)
      }
    }
  }, [isOpen])   //eslint-disable-line

  const handleClose = () => {
    onClose()
  }

  return isOpen && (
    <motion.div initial={{ opacity: 0 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ type:"tween", duration: 0.3, }}
      className="fixed z-50 inset-0 overflow-y-auto bg-slate-800 bg-opacity-90 overflow-scroll"
      style={{ marginTop: '0' }}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0">
        <div className="flex flex-col min-h-screen items-center justify-center space-y-4">
          <div ref={ref} >
            <p onClick={() => handleClose()} className="fixed top-0 right-10 text-red-700 text-3xl cursor-pointer">x</p>
            <Img src={src ?? '/'} alt={alt ?? null} className="max-w-full max-h-full" />
            {/* <p className='text-white'>{title}</p> */}
          </div>
        </div>
      </div>
    </motion.div>

  )
}

export default Preview
