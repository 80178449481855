import { REGISTER_STATE } from "../../globalActionType";

const initialState = {
  isLoading: false,
  alert: '',
  isError: false,
  isSuccess: false,
  data: '',
  dataConfirm: '',
  trigger: '',
  message: '',
};

const register = (state = initialState, action) => {
  switch (action.type) {
    case 'RESET':
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        logout: false,
        message: "",
        from: undefined
      }
    case REGISTER_STATE.REGISTER:
      return {
        state,
        // trigger: action.type,
        isLoading: true,
        user: action.email
      };
    case REGISTER_STATE.REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        alert: 'success',
        data: action.data.data,
        message: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case REGISTER_STATE.REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        alert: "error",
        message: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case REGISTER_STATE.SEND_MAIL:
      return {
        ...state,
        isLoadingSendMail: true
      };
    case REGISTER_STATE.SEND_MAIL_SUCCESS:
      return {
        ...state,
        isLoadingSendMail: false,
        isErrorSendMail: false,
        isSuccessSendMail: true,
        alertSendMail: 'success',
        isSuccess: false,
        messageSendMail: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case REGISTER_STATE.SEND_MAIL_FAILURE:
      return {
        ...state,
        isLoadingSendMail: false,
        isErrorSendMail: true,
        alertSendMail: "error",
        messageSendMail: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case REGISTER_STATE.CONFIRM:
      return {
        ...state,
        dataConfirm: '',
        isLoadingConfirm: true
      };
    case REGISTER_STATE.CONFIRM_SUCCESS:
      return {
        ...state,
        isLoadingConfirm: false,
        isErrorConfirm: false,
        isSuccessConfirm: true,
        dataConfirm: action.data.data,
        alertConfirm: 'success',
        messageConfirm: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    case REGISTER_STATE.CONFIRM_FAILURE:
      return {
        ...state,
        isLoadingConfirm: false,
        isErrorConfirm: true,
        alertConfirm: "error",
        messageConfirm: action.data?.meta?.message ?? action.data?.message ?? action.error?.message ?? 'Something went wrong.',
      };
    default:
      return state;
  }
};

export default register