import { LANG_STATE } from "../../globalActionType";

const initialState = {
  lang:'en'
}

const other = (state = initialState, action) => {
  switch (action.type) {
    case LANG_STATE.CHANGE_LANG_DATA:
      return {
        ...state,
        lang: action.data
      };
    default:
      return state;
  }
}

export default other;