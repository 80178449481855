import { ABOUT } from "../../../services";
import { ABOUT_DATA_STATE } from "../../globalActionType";

export const getAbout = (params) => {
  function request() { return { type: ABOUT_DATA_STATE.ABOUT_DATA } }
  function success(data) { return { type: ABOUT_DATA_STATE.ABOUT_DATA_SUCCESS, data } }
  function failure(data) { return { type: ABOUT_DATA_STATE.ABOUT_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await ABOUT.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getDetailAbout = (params) => {
  function request() { return { type: ABOUT_DATA_STATE.ABOUT_DETAIL_DATA } }
  function success(data) { return { type: ABOUT_DATA_STATE.ABOUT_DETAIL_DATA_SUCCESS, data } }
  function failure(data) { return { type: ABOUT_DATA_STATE.ABOUT_DETAIL_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await ABOUT.getDetail(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const resetAbout = () => (dispatch) => {
  dispatch({
    type: ABOUT_DATA_STATE.ABOUT_DATA_RESET
  })
}

export const resetAboutDetail = () => (dispatch) => {
  dispatch({
    type: ABOUT_DATA_STATE.ABOUT_DETAIL_DATA_RESET
  })
}