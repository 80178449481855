import React, { useEffect, useMemo, useState } from 'react'
import Hamburger from '../../atoms/Hamburger'
import _ from 'lodash'
import Input from '../../atoms/Input'
import { baseUrl } from '../../../helpers/baseUrl'
import Select from '../../atoms/Select'
import { useDispatch, useSelector } from 'react-redux'
import { getLang } from '../../../redux/global/translation/action'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { getNews } from '../../../redux/admin/news/action'
import { remappingFilter } from '../../../helpers/filterMapping'
import moment from 'moment'
import { useRef } from 'react'

const menu = [
  { url: "home", name: "home" },
  { url: "about-us", name: "about us" },
  { url: "services", name: "services" },
  { url: "location", name: "location" },
  { url: "news", name: "news" },
  { url: "career", name: "career" },
  { url: "contact-us", name: "contact us" },
]

const langOption = [
  { label: "English", value: "en" },
  { label: "Indonesia", value: "id" },
  { label: "日本語", value: "jp" }
]

const LandingHeader = ({
  data,
  isLoading
}) => {
  const dispatch = useDispatch()
  const ref = useRef()
  const { t, i18n } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [filter, setFilter] = useState([])
  const [type, setType] = useState(null)
  const [pagination, setPagination] = useState({
    page: 1, limit: 5
  })
  const [sorting, setSorting] = useState({
    sortBy: "updatedAt", sortType: "desc" //sortby name, sorttype asc or desc
  })

  const { lang } = useSelector(state => state.other)
  const news = useSelector(state => state.news)

  const params = {
    lang,
    pagination,
    sorting,
    filter: filter.join(",")
  }

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [i18n, lang]);

  // useEffect(() => {
  //   dispatch(getNews(params))
  // }, [lang]);

  useEffect(() => {
    if (fetched) {
      dispatch(getNews(params))
      setFetched(false)
    }
  }, [fetched]);

  useEffect(() => {
    if (setType) {
      const checkIfClickedOutside = e => {
        if (type && ref.current && !ref.current.contains(e.target)) {
          setType(null)
        }
      }
      document.addEventListener("mousedown", checkIfClickedOutside)
      return () => {
        document.removeEventListener("mousedown", checkIfClickedOutside)
      }
    }
  }, [type])

  const handleChange = (event) => {
    dispatch(getLang(event.target.value))
  }

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleSearchItem = () => {
    setType(null)
  }

  const onSearch = (event) => {
    setType(event.target.value)
    eventSearch(event.target.value, event.target.name, filter)
  }

  const eventSearch = useMemo(
    () => _.debounce((value, name, filter) => {
      remappingFilter(setFilter, filter, value, name, "like")
      setFetched(true)
    }, 400),
    []
  )
  return (
    <div className="fixed bg-white w-full md:relative z-50 top-0">
      <div className="flex flex-col w-content space-y-2 md:space-y-0 md:flex-row justify-between md:items-center py-2 min-h-[4rem] md:mt-4 md:py-4">
        <div className="flex flex-row space-x-4 items-center md:py-0">
          <img src={baseUrl("/assets/svg/logo.svg")} alt="ilc" className="h-8 md:h-16" />
          <div className="text-md md:text-2xl text-[#007BC4]">PT. ILC LOGISTICS INDONESIA</div>
        </div>
        <div className="flex justify-between w-full md:w-auto md:space-x-8">
          <div className="flex text-[0.7rem] md:text-sm md:flex-row space-x-8">
            <div className="flex flex-col">
              <label className="text-gray-400 capitalize">{t("call us")}</label>
              <label>{data?.phone}</label>
            </div>
            <div className="flex flex-col">
              <label className="text-gray-400 capitalize">{t("email us")}</label>
              <label>{data?.email}</label>
            </div>
          </div>
          <Select
            onChange={(event) => handleChange(event)}
            name={"language"}
            value={lang}
            option={langOption}
          />
        </div>
      </div>
      <div className="bg-[#052c7d]">
        <div className="w-content text-gray-300 flex flex-row justify-between items-center min-h-[2.5rem]">
          <div className="md:flex h-16 hidden invisible md:visible md:flex-row md:space-x-6 text-md items-center">
            {
              _.map(menu, (val, key) => (
                // <div className="flex hover:bg-blue-900 px-3  md:items-center  h-full">({ isActive }) => (isActive ? 'active-menu' : 'menu')
                <NavLink key={key} to={`/${val.url === "home" ? "" : val?.url}`} className={({ isActive }) => (isActive ? 'active-header' : 'header')}>{t(val?.name)}</NavLink>
                // </div>
              ))
            }
          </div>
          <Hamburger isOpen={isOpen} onClick={() => handleClick()} />
          <div ref={ref} className="max-w-[212px]">
            <Input
              type={"text"}
              name={"title"}
              onChange={(event) => onSearch(event)}
              className="py-1 px-2 bg-[#092767] border-0 min-w-[212px]"
              placeholder={t("search here")}
              value={type ?? ''}
            />
            {type ?
              <div className="absolute my-1 text-sm bg-white min-w-[212px] max-w-[212px] overflow-hidden text-slate-600">
                {
                  news?.data?.data?.length ?
                    _.map(news?.data?.data, (val, key) => (
                      <NavLink
                        to={"/news/" + moment(val?.createdAt).format("YYYY-MM-DD/") + val?.slug} key={key}
                        onClick={() => handleSearchItem()}
                      >
                        <p className="cursor-pointer p-2 hover:bg-blue-100 hover:text-black">{val?.title}</p>
                      </NavLink>
                    ))
                    : <p className="text-slate-500 p-2">Not found</p>
                }
              </div> : null
            }
          </div>

        </div>
      </div>
      {
        isOpen ?
          <div className="fixed md:hidden flex flex-col space-y-4 text-md py-5 px-5 z-50 bg-[#092767] text-gray-300 min-w-full">
            {
              _.map(menu, (val, key) => (
                <NavLink onClick={() => handleClick()} key={key} to={`/${val.url === "home" ? "" : val?.url}`} className={({ isActive }) => (isActive ? 'active-header' : 'header')}>{t(val?.name)}</NavLink>
              ))
            }
          </div>
          : ""
      }
    </div>
  )
}

export default LandingHeader
