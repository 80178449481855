import api from '../config/api';
import apiLanding from '../config/apiLanding';

export const getContact = (params) => {
  return apiLanding
  .get(`/contact?lang=${params?.lang ?? ''}`)
}

export const getList = (params) => {
  return api
  .get(`/contact?page=${params?.pagination?.page ?? "1"}&size=${params?.pagination?.limit ?? "10"}${params?.sorting ? "&sortBy="+params?.sorting?.sortBy+"&sortType="+params?.sorting?.sortType:""}${params?.filter ? "&filters="+params?.filter:""}`)
}

export const getDetail = (params) => {
  return api
  .get(`/contact/${params?.id ?? ''}`)
}

export const create = (params) => {
  return api
  .post(`/contact`,params?.data)
}

export const update = (params) => {
  return api
  .put(`/contact/update`,params?.data)
}

export const deleteList = (id) => {
  return api
  .put(`/contact`,id)
}

export const submit = (params) => {
  return api
  .post(`/mail/contact-mail`,params?.data)
}