import { ROLE } from "../../../../services";
import { ROLE_SUBMIT_STATE } from "../../../globalActionType";

export const createRole = (params) => {
  function request() {
    return {
      type: ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA,
    };
  }

  function success(data) {
    return {
      type: ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA_SUCCESS,
      data,
    };
  }

  function failure(message) {
    return {
      type: ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA_FAILURE,
      message,
    };
  }

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const response = await ROLE.createRole(params);
      const data = response?.data ?? {};

      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error.response.data ?? error));
    }
  };
};

export const updateRole = (params) => {
  function request() {
    return {
      type: ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA,
    };
  }

  function success(data) {
    return {
      type: ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA_SUCCESS,
      data,
    };
  }

  function failure(message) {
    return {
      type: ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA_FAILURE,
      message,
    };
  }

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const {id, item} = params;
      const response = await ROLE.editRole(id, item);
      const res = response?.data ?? {};
      
      if (res?.meta?.success) {
        dispatch(success(res?.data));
      } else {
        dispatch(failure(res?.meta?.message));
      }
    } catch (error) {
      console.log(error);
      dispatch(failure(error.response.data ?? error));
    }
  };
};

export const deleteRole = (params) => {
  function request() {
    return { type: ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA };
  }

  function success(data) {
    return {
      type: ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA_SUCCESS,
      data,
    };
  }

  function failure(message) {
    return {
      type: ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA_FAILURE,
      message,
    };
  }

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const response = await ROLE.deleteRole(params);
      const data = response?.data ?? {};

      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error.response.data ?? error));
    }
  };
};

export const resetSubmit = () => (dispatch) => {
  dispatch({
    type: ROLE_SUBMIT_STATE.ROLE_SUBMIT_DATA_RESET
  })
}