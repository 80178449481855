import { GALLERY } from "../../../services";
import { GALLERY_DATA_STATE } from "../../globalActionType";

export const getGallery= (params) => {
  function request() { return { type: GALLERY_DATA_STATE.GALLERY_DATA } }
  function success(data) { return { type: GALLERY_DATA_STATE.GALLERY_DATA_SUCCESS, data } }
  function failure(data) { return { type: GALLERY_DATA_STATE.GALLERY_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await GALLERY.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getListGallery= (params) => {
  function request() { return { type: GALLERY_DATA_STATE.GALLERY_DATA } }
  function success(data) { return { type: GALLERY_DATA_STATE.GALLERY_DATA_SUCCESS, data } }
  function failure(data) { return { type: GALLERY_DATA_STATE.GALLERY_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await GALLERY.getGallery(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const resetGallery= () => (dispatch) => {
  dispatch({
    type: GALLERY_DATA_STATE.GALLERY_DATA_RESET
  })
}