import { PRIVILEGE } from "../../../../services";
import { PRIVILEGE_SUBMIT_STATE } from "../../../globalActionType";

export const submit = (params) => {
  function request() { return { type: PRIVILEGE_SUBMIT_STATE.PRIVILEGE_SUBMIT } }
  function success(data) { return { type: PRIVILEGE_SUBMIT_STATE.PRIVILEGE_SUBMIT_SUCCESS, data } }
  function failure(data) { return { type: PRIVILEGE_SUBMIT_STATE.PRIVILEGE_SUBMIT_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {      
      const response = await PRIVILEGE.update(params)
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data))          
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
  }
};

export const resetSubmit = () => (dispatch) => {
  dispatch({
    type: PRIVILEGE_SUBMIT_STATE.PRIVILEGE_SUBMIT_RESET
  })
}