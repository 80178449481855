import api from '../config/api';

export const getUsers = (params) => {
  return api
  .get(`/users?page=${params?.pagination?.page ?? "1"}&size=${params?.pagination?.limit ?? "10"}${params?.sorting ? "&sortBy="+params?.sorting?.sortBy+"&sortType="+params?.sorting?.sortType:""}${params?.filter ? "&filters="+params?.filter:""}`)
}

export const createUsers = (data) => {
  return api
  .post(`/users`,data)
}

export const updateUsers = (params) => {
  return api
  .put(`/users/${params?.id}`,params?.data)
}

export const deleteUsers = (id) => {
  return api
  .put(`/users`,id)
}