import { SEARCH_DATA_STATE } from "../../globalActionType";

export const setSearch = (params) => {
  function fetch(data) { return { type: SEARCH_DATA_STATE.SEARCH_DATA, data } }

  return async (dispatch, getState) => {
    const data = params
    dispatch(fetch(data));
  }
};

export const setCategory = (params) => {
  function fetch(data) { return { type: SEARCH_DATA_STATE.SEARCH_CATEGORY_DATA, data } }

  return async (dispatch, getState) => {
    const data = params
    dispatch(fetch(data));
  }
};

export const setFetched = (params) => {
  function fetch(data) { return { type: SEARCH_DATA_STATE.SET_FECHED, data } }

  return async (dispatch, getState) => {
    const data = params
    dispatch(fetch(data));
  }
};