import React from 'react'
import { baseUrl } from '../../helpers/baseUrl'

const Logo = ({
  brandName
}) => {
  return (
    <div className="flex items-center p-4 invisible">
      {/* <div className="inline-flex"> */}
      <div href="#" className="flex space-x-4 items-center">
        {/* <svg className="w-8 h-8 text-red-400" fill="currentColor" viewBox="0 0 25 22">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
          </svg> */}
        <img src={baseUrl("/assets/svg/logo.svg")} alt="ilc" className="h-8 md:h-10" />

        <div className="text-xl font-bold text-[#007BC4] uppercase">{brandName}</div>
      </div>
      {/* </div> */}
    </div>
  )
}

export default Logo
