import React from 'react'
import Select from '../../atoms/Select'

const TypeService = ({
  onSelect,
  className,
  value
}) => {
  return (
    <Select
      placeholder="Filter"
      type="text"
      name="type"
      className={className}
      onChange={(event) => onSelect(event)}
      value={value ?? "head"}
      option={[
        {
          label: "Head",
          value: "head",
        },
        {
          label: "Main",
          value: "main",
        },
        {
          label: "Assets",
          value: "assets",
        },
        {
          label: "Content",
          value: "content",
        },
        {
          label: "Footer",
          value: "footer",
        }
      ]}
    />
  )
}

export default TypeService