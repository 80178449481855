import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import Input from '../../components/atoms/Input'
import Label from '../../components/atoms/Label'
import Loading from '../../components/atoms/Loading'
import Img from '../../components/atoms/Img'
import { resetSubmit, submit } from '../../redux/admin/profile/action'
import { useToast } from '../../components/organisms/ToastProvider'
import { toastRequest, toastReset } from '../../redux/global/toast/action'
import { apiUrl } from '../../helpers/baseUrl'

const Profile = () => {
  const dispatch = useDispatch()
  const { data, username, email } = useSelector(state => state.dataStore)
  const dataToast = useSelector(state => state.toast);
  const { alert, message, isLoading } = useSelector(state => state.profileSubmit)

  const { addToast } = useToast()
  const [img, setImg] = useState(null)

  const [inputs, setInputs] = useState({
    username: username ?? '',
    email: email ?? '',
    password: '',
    newPassword: '',
    name: data?.name ?? "",
    image: data?.image ?? ""
  })

  // console.log(data)

  const params = {
    data: inputs,
    id: data?.idUsers
  }

  useEffect(() => {
    if (alert) {
      dispatch(toastRequest())
    }
  }, [alert]);  //eslint-disable-line

  useEffect(() => { // global toast 
    if (dataToast.toast && alert) {
      addToast({
        type: alert,
        message: message,
        // time:5000
      })
      dispatch(toastReset())
      dispatch(resetSubmit())
    }
  }, [dataToast.toast, alert]); //eslint-disable-line

  const handleChange = (event) => {
    if (!event.target.files) {
      setInputs({
        ...inputs,
        [event.target.name]: event.target.value
      })
    }
    if (event.target.files) {
      setImg(URL.createObjectURL(event.target.files[0]))
      setInputs({
        ...inputs,
        [event.target.name]: event.target.files[0]
      })
    }
  }

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("file", inputs.image);
    for ( var key in inputs ) {
      formData.append(key, inputs[key]);
    }
    const paramss = {data: formData,
      id: data?.idUsers}
      
    dispatch(submit(paramss))
  }

  return (
    <div className='bg-white p-5 shadow-md mb-5 w-fit'>
      <label className="text-xl">Change Profile</label>
      <hr className="my-5" />
      <form className="flex flex-col px-8">
        <div className="flex flex-col-reverse space-y-8 space-y-reverse md:flex-row md:space-x-10 md:space-y-0">
          <div className="flex flex-col space-y-6">
            <div className="form-group">
              <Label className="md:w-1/2 md:text-left">Name</Label>
              <Input
                className="w-full md:w-2/3 form-box"
                type="text"
                name="name"
                onChange={(event) => handleChange(event)}
                value={inputs.name}
              />
            </div>
            <div className="form-group">
              <Label className="md:w-1/2 md:text-left">Username</Label>
              <Input
                className="w-full md:w-2/3 form-box"
                type="text"
                name="username"
                disabled={true}
                onChange={(event) => handleChange(event)}
                value={inputs.username}
              />
            </div>
            <div className="form-group">
              <Label className="md:w-1/2 md:text-left">Email</Label>
              <Input
                className="w-full md:w-2/3 form-box"
                type="email"
                name="email"
                disabled={true}
                onChange={(event) => handleChange(event)}
                value={inputs.email}
              />
            </div>
            <div className="form-group">
              <Label className="md:w-1/2 md:text-left">Old Password</Label>
              <Input
                className="w-full md:w-2/3 form-box"
                type="password"
                name="password"
                onChange={(event) => handleChange(event)}
                value={inputs.password}
              />
            </div>
            <div className="form-group">
              <Label className="md:w-1/2 md:text-left">New Password</Label>
              <Input
                className="w-full md:w-2/3 form-box"
                type="password"
                name="newPassword"
                disabled={inputs.password ? false : true}
                onChange={(event) => handleChange(event)}
                value={inputs.newPassword}
              />
            </div>
          </div>
          <div className="space-y-2">
            <Label className="md:text-left">Photo</Label>
            <div className="flex items-center space-x-4">
              <label
                htmlFor="file-upload"
                className="relative h-40 w-40 cursor-pointer bg-transparent rounded-full font-medium text-indigo-600"
              >
                <span className="inline-block h-40 w-40 rounded-full overflow-hidden bg-gray-100 absolute">
                  {
                    img || data.image ? <Img src={img ?? apiUrl("public/assets/img/upload/"+data.image) } />
                      :
                      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                  }
                </span>{console.log("public/assets/img/upload/"+data.image)}
                <input id="file-upload" onChange={(event) => handleChange(event)} name="image" type="file" className="sr-only" />
              </label>
            </div>
          </div>
        </div>
        {isLoading && <Loading className={"mt-4"} />}
        <div className='text-right pt-5'>
          <Button type={"button"}
            buttonStyle={"btn-primary text-sm p-1"}
            onClick={() => handleSubmit()}
            disabled={isLoading}
          >
            Submit
          </Button>
          {/* <Button type={"button"}
            buttonStyle={"btn-secondary text-sm p-1"}
            disabled={isLoading}
          >
            Cancel
          </Button> */}
        </div>
      </form>
    </div>
  )
}

export default Profile