import React, { useEffect, useRef } from 'react'
import LandingSection from '../../components/templates/landing/Section'
import { apiUrl, baseUrl } from '../../helpers/baseUrl'
import _ from 'lodash';
import Img from '../../components/atoms/Img';
import { useDispatch, useSelector } from 'react-redux';
import { getHero, getListHero } from '../../redux/admin/hero/action';
import LoadingSr from '../../components/atoms/LoadingSr';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/css';
// import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";

const Preview = ({
  data, isLoading
}) => {
  // const dispatch = useDispatch()
  const { t } = useTranslation()
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  // const { data, isLoading } = useSelector(state => state.hero)
  // const { lang } = useSelector(state => state.other)

  // const params = {
  //   lang
  // }

  // useEffect(() => {
  //   dispatch(getListHero(params))
  // }, [lang]);

  return (
    // <LandingSection className={"text-white h-[47.5rem] min-w-full flex items-center"}>
    <LandingSection className={"text-white h-[18.5rem] min-w-full flex items-center"}>
      {isLoading ?
        <div className=" bg-slate-600 animate-pulse h-full w-full left-0 z-0 overflow-hidden">
          <div className="h-full">
            <div className="flex flex-col text-left justify-center bg-transparant z-20 left-0 h-full">
              <div className="w-content w-full space-y-6">
                <LoadingSr className="md:w-20 w-20 h-5" />
                <LoadingSr className="md:w-52 w-52 h-5" />
                <LoadingSr className="md:w-96 w-64 h-7" />
              </div>
            </div>
          </div>
        </div> :
        <Swiper
          onSwiper={(swiper) => {
            // Delay execution for the refs to be defined
            setTimeout(() => {
              // Override prevEl & nextEl now that refs are defined
              swiper.params.navigation.prevEl = prevRef.current
              swiper.params.navigation.nextEl = nextRef.current
    
              // Re-init navigation
              swiper.navigation.destroy()
              swiper.navigation.init()
              swiper.navigation.update()
            })
          }}
          navigation={true}
          effect={"fade"}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Autoplay, EffectFade]}
          className="h-full w-full"
        >
          {_.map(data, (val, key) => (
            <SwiperSlide key={key} className="bg-gray-900">
              <div className="fixed flex w-full h-full items-center text-left z-20">
                <div className="w-content w-full space-y-4">
                  <div className="flex space-x-2  font-mono">
                    <button ref={prevRef} className="bg-white py-1 px-2 text-xl font-bold text-gray-500">&lt;</button>
                    <button ref={nextRef} className="bg-white py-1 px-2 text-xl font-bold text-gray-500">&gt;</button>
                  </div>
                  <div className="font-roboto-condenced text-xl md:text-[24px] leading-[34px] font-light">{val?.title}</div>
                  <div className="font-roboto-condenced text-3xl md:text-[30px] leading-[35px] font-bold tracking-tight uppercase">{val?.description}</div>
                  <button className="text-left text-sm bg-[#2ecc71] w-fit py-2 px-4 rounded-md uppercase">{t("know more")}</button>
                </div>
              </div>
              <Img src={apiUrl(`public/assets/img/hero/${val?.image}`)} alt={val?.image} className="h-full opacity-30 object-cover md:w-full" />
            </SwiperSlide>
          ))}
        </Swiper>
      }
    </LandingSection>
  )
}

export default Preview