import React from 'react'
import HeadServices from './component/HeadService'
import OtherService from './component/OtherService'
import Service from './component/Service'

const PreviewService = ({
  type,
  data,
  isLoading
}) => {
  if (type === "head") {

  }
  return (
    <>
      {
        type === "head" &&
        <div className='bg-white p-5 shadow-md mb-5'>
          <HeadServices
            data={data}
            isLoading={isLoading}
          />
        </div>
      }
      {
        type === "main" &&
        <div className='bg-white p-5 shadow-md mb-5'>
          <Service
            data={data}
            isLoading={isLoading}
          />
        </div>
      }
      {
        type === "assets" &&
        <div className='bg-white p-5 shadow-md mb-5'>
          <OtherService
            data={data}
            isLoading={isLoading}
          />
        </div>
      }
    </>
  )
}

export default PreviewService