import { CATEGORY_DATA_STATE } from "../../globalActionType";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  data: [],
  message: '',

  isLoadingDetail: false,
  isErrorDetail: false,
  isSuccessDetail: false,
  dataDetail: [],
  messageDetail: '',
}

export const careerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_DATA_STATE.CATEGORY_DATA:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case CATEGORY_DATA_STATE.CATEGORY_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        message: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case CATEGORY_DATA_STATE.CATEGORY_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        isError: false,
        data: action.data.data
      };
    case CATEGORY_DATA_STATE.CATEGORY_DATA_RESET:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: [],
        message: '',
      };
    case CATEGORY_DATA_STATE.CATEGORY_DETAIL_DATA:
      return {
        ...state,
        isLoadingDetail: true,
        isSuccessDetail: false,
        isErrorDetail: false,
      };
    case CATEGORY_DATA_STATE.CATEGORY_DETAIL_DATA_FAILURE:
      return {
        ...state,
        isLoadingDetail: false,
        isSuccessDetail: false,
        isErrorDetail: true,
        messageDetail: action.data?.meta?.message ?? action.data?.message ?? 'Something went wrong.',
      };
    case CATEGORY_DATA_STATE.CATEGORY_DETAIL_DATA_SUCCESS:
      return {
        ...state,
        isLoadingDetail: false,
        isSuccessDetail: true,
        isErrorDetail: false,
        dataDetail: action.data.data
      };
    case CATEGORY_DATA_STATE.CATEGORY_DETAIL_DATA_RESET:
      return {
        ...state,
        isLoadingDetail: false,
        isErrorDetail: false,
        isSuccessDetail: false,
        dataDetail: [],
        messageDetail: '',
      };
    default:
      return state;
  }
}
export default careerReducer