import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/atoms/Button'
import Img from '../../components/atoms/Img'
import Input from '../../components/atoms/Input'
import Label from '../../components/atoms/Label'
import Loading from '../../components/atoms/Loading'
import Textarea from '../../components/atoms/Textarea'
import { useToast } from '../../components/organisms/ToastProvider'
import { apiUrl } from '../../helpers/baseUrl'
import { getDetailAbout, resetAboutDetail } from '../../redux/admin/about/action'
import { submit } from '../../redux/admin/about/submit/action'
import { toastRequest, toastReset } from '../../redux/global/toast/action'
import { getLang } from '../../redux/global/translation/action'

const About = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [img, setImg] = useState(null)
  const [imgChart, setImgChart] = useState(null)
  const { addToast } = useToast()
  const [fetched, setFetched] = useState()

  // const { lang } = useSelector(state => state.other)
  const { dataDetail, isLoadingDetail, isSuccessDetail } = useSelector(state => state.about)
  const dataToast = useSelector(state => state.toast);
  const { alert, message, isLoading } = useSelector(state => state.aboutSubmit)

  // const { addToast } = useToast()
  const [activeTab, setActiveTab] = useState('en')

  let [formData, setFormData] = useState(new FormData())
  const [inputs, setInputs] = useState({
    image: null,
    imagechart: null,
    en: {
      history: '',
      description: '',
      companyname: '',
      business: '',
      headoffice: '',
      president: '',
      establisment: '',
      operatingbase: '',
      isoGdp: '',
      numOfEmployee: '',
      shareholders: '',
    },
    jp: {
      history: '',
      description: '',
      companyname: '',
      business: '',
      headoffice: '',
      president: '',
      establisment: '',
      operatingbase: '',
      isoGdp: '',
      numOfEmployee: '',
      shareholders: '',
    },
    id: {
      history: '',
      description: '',
      companyname: '',
      business: '',
      headoffice: '',
      president: '',
      establisment: '',
      operatingbase: '',
      isoGdp: '',
      numOfEmployee: '',
      shareholders: '',
    }
  })

  const params = {
    // id: dataDetail.id,
    data: formData,
    type: dataDetail.id ? 'edit' : 'create'
  }

  useEffect(() => {
    i18n.changeLanguage(activeTab)
  }, [i18n, activeTab]);

  useEffect(() => {
    dispatch(getDetailAbout(params))
  }, []);

  useEffect(() => {
    if (fetched) {
      dispatch(getDetailAbout(params))
      setFetched(false)
    }
  }, [fetched]);

  useEffect(() => {
    if (isSuccessDetail) {
      setInputs(dataDetail)
    }
  }, [isSuccessDetail]);

  useEffect(() => { // global toast 
    if (dataToast.toast && alert) {
      addToast({
        type: alert,
        message: message,
        // time:5000
      })
      dispatch(toastReset())
      if (alert === 'success') {
        setFetched(true)
      }
    }

  }, [dataToast.toast, alert]); //eslint-disable-line

  const onChangeTab = (lang) => {
    setActiveTab(lang)
    dispatch(getLang(lang))
  }

  const handleChange = (event) => {
    if (!event.target.files) {
      // if (event.target.name === "description" || event.target.name === "history") {
      setInputs({
        ...inputs,
        [activeTab]: {
          ...inputs[activeTab],
          [event.target.name]: event.target.value
        }
      })
      // } else {
      //   setInputs({
      //     ...inputs,
      //     [event.target.name]: event.target.value
      //   })
      // }
    }
    if (event.target.files) {
      if (event.target.name === 'image') setImg(URL.createObjectURL(event.target.files[0]))
      if (event.target.name === 'imagechart') setImgChart(URL.createObjectURL(event.target.files[0]))
      setInputs({
        ...inputs,
        [event.target.name]: event.target.files[0]
      })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormData(new FormData())
    for (var key in inputs) {
      if (key === 'en' || key === 'jp' || key === 'id') {
        for (let previewKey in inputs[key]) {
          formData.append(`${previewKey}[${key}]`, inputs[key][previewKey]);
        }
      } else {
        formData.append(key, inputs[key]);
      }
    }
    dispatch(toastRequest())
    dispatch(submit(params))
  }
  console.log(dataDetail)
  return !isSuccessDetail ? <Loading /> : (
    <div className='bg-white p-5 shadow-md mb-5'>
      <label className="text-xl">Company Profile</label>
      <hr className="my-5" />
      <form className="flex flex-col px-4 max-w-full md:w-full space-y-6 ">
        <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 space-y-6 md:space-y-0">
          <div className="w-full md:w-1/2">
            <div className="form-group">
              <Label className="md:w-1/3 md:text-left">Image</Label>
              <div className="flex items-center w-full md:w-2/3 space-x-4 bg-slate-300 border-2 rounded overflow-hidden">
                <label
                  htmlFor="file-upload"
                  className="relative w-full h-40 cursor-pointer bg-transparent font-medium text-indigo-600"
                >
                  <span className="flex justify-center w-full h-40 overflow-hidden bg-gray-100 absolute">
                    {
                      img || dataDetail.image ? <Img src={img ?? apiUrl("public/assets/img/about/" + dataDetail?.image)} className="h-40 text-center" />
                        :
                        <svg className="h-full w-full text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                    }
                  </span>
                  <input id="file-upload" onChange={(event) => handleChange(event)} name="image" type="file" className="sr-only" />
                </label>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="form-group">
              <Label className="md:w-1/3 md:text-left">Organization Chart Image</Label>
              <div className="flex items-center w-full md:w-2/3 space-x-4 bg-slate-300 border-2 rounded overflow-hidden">
                <label
                  htmlFor="file-upload-chart"
                  className="relative w-full h-40 cursor-pointer bg-transparent font-medium text-indigo-600"
                >
                  <span className="flex justify-center w-full h-40 overflow-hidden bg-gray-100 absolute">
                    {
                      imgChart || dataDetail.imageChart ? <Img src={imgChart ?? apiUrl("public/assets/img/about/" + dataDetail?.imageChart)} className="h-40 text-center" />
                        :
                        <svg className="h-full w-full text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                    }
                  </span>
                  <input id="file-upload-chart" onChange={(event) => handleChange(event)} name="imagechart" type="file" className="sr-only" />
                </label>
              </div>
              {/* <input type="file" name="imagechart" onChange={(event) => handleChange(event)} /> */}
            </div>
          </div>
        </div>
        <div className="form-group border-b-2 pl-4">
          <div className="flex space-x-4 text-slate-400 font-semibold -mb-[0.12rem]">
            <div onClick={() => onChangeTab('en')} className={activeTab === "en" ? "active-tab" : "tab"}>English</div>
            <div onClick={() => onChangeTab('jp')} className={activeTab === "jp" ? "active-tab" : "tab"}>Japan</div>
            <div onClick={() => onChangeTab('id')} className={activeTab === "id" ? "active-tab" : "tab"}>Indonesia</div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 space-y-6 md:space-y-0">
          <div className="flex flex-col md:flex-row space-y-6 space-x-0 md:space-x-8 w-full">
            <div className="flex flex-col space-y-6 md:w-1/2">
              <div className="form-group">
                <Label className="md:w-1/3 md:text-left">description</Label>
                <Textarea
                  name={"description"}
                  className="w-full md:w-2/3 form-box"
                  placeholder={t("description")}
                  onChange={(event) => handleChange(event)}
                  rows={10}
                  value={inputs[activeTab].description ?? ''}
                >
                </Textarea>
              </div>
              <div className="form-group">
                <Label className="md:w-1/3 md:text-left">History</Label>
                <Textarea
                  name={"history"}
                  className="w-full md:w-2/3 form-box"
                  placeholder={t("history")}
                  onChange={(event) => handleChange(event)}
                  rows={10}
                  value={inputs[activeTab].history ?? ''}
                >
                </Textarea>
              </div>
            </div>
            <div className="flex flex-col space-y-6 md:w-1/2">
              <div className="form-group">
                <Label className="md:w-1/3 md:text-left">Company Name</Label>
                <Input
                  className="w-full md:w-2/3 form-box"
                  type="text"
                  name={"companyname"}
                  placeholder={"companyname"}
                  onChange={(event) => handleChange(event)}
                  value={inputs[activeTab].companyname}
                />
              </div>
              <div className="form-group">
                <Label className="md:w-1/3 md:text-left">Business</Label>
                <Input
                  className="w-full md:w-2/3 form-box"
                  type="text"
                  name={"business"}
                  placeholder={"business"}
                  onChange={(event) => handleChange(event)}
                  value={inputs[activeTab].business}
                />
              </div>
              <div className="form-group">
                <Label className="md:w-1/3 md:text-left">Head Office</Label>
                <Textarea
                  name={"headoffice"}
                  className="w-full md:w-2/3 form-box"
                  placeholder={"headoffice"}
                  onChange={(event) => handleChange(event)}
                  rows={3}
                  value={inputs[activeTab].headoffice}
                >
                </Textarea>
              </div>
              <div className="form-group">
                <Label className="md:w-1/3 md:text-left">President</Label>
                <Input
                  className="w-full md:w-2/3 form-box"
                  type="text"
                  name={"president"}
                  placeholder={"president"}
                  onChange={(event) => handleChange(event)}
                  value={inputs[activeTab].president}
                />
              </div>
              <div className="form-group">
                <Label className="md:w-1/3 md:text-left">Establishment</Label>
                <Input
                  className="w-full md:w-2/3 form-box"
                  type="text"
                  name={"establisment"}
                  placeholder={"establisment"}
                  onChange={(event) => handleChange(event)}
                  value={inputs[activeTab].establisment}
                />
              </div>
              <div className="form-group">
                <Label className="md:w-1/3 md:text-left">Operating Base</Label>
                <Textarea
                  name={"operatingbase"}
                  className="w-full md:w-2/3 form-box"
                  placeholder={"operatingbase"}
                  onChange={(event) => handleChange(event)}
                  rows={3}
                  value={inputs[activeTab].operatingbase}
                >
                </Textarea>
              </div>
              <div className="form-group">
                <Label className="md:w-1/3 md:text-left">ISO/GDP</Label>
                <Input
                  className="w-full md:w-2/3 form-box"
                  type="text"
                  name={"isoGdp"}
                  placeholder={"ISO/GDP"}
                  onChange={(event) => handleChange(event)}
                  value={inputs[activeTab].isoGdp}
                />
              </div>
              <div className="form-group">
                <Label className="md:w-1/3 md:text-left">Shareholders</Label>
                <Input
                  className="w-full md:w-2/3 form-box"
                  type="text"
                  name={"shareholders"}
                  placeholder={"shareholders"}
                  onChange={(event) => handleChange(event)}
                  value={inputs[activeTab].shareholders}
                />
              </div>
              <div className="form-group">
                <Label className="md:w-1/3 md:text-left">Number of Employee</Label>
                <Input
                  className="w-full md:w-2/3 form-box"
                  type="text"
                  name={"numOfEmployee"}
                  placeholder={"Number of Employee"}
                  onChange={(event) => handleChange(event)}
                  value={inputs[activeTab].numOfEmployee}
                />
              </div>
            </div>
          </div>
        </div>
        {isLoading && <Loading className={"mt-4"} />}
        <div className='text-right pt-5'>
          <Button type={"button"}
            buttonStyle={"btn-primary text-sm p-1"}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  )
}

export default About