import React from 'react'
import { useTranslation } from 'react-i18next'
import Img from '../../../components/atoms/Img'
import LandingSection from '../../../components/templates/landing/Section'
import { baseUrl } from '../../../helpers/baseUrl'

const HeroContent = ({
  title,
  to
}) => {
  const { t } = useTranslation();
  return (
    <LandingSection >
      <div className="bg-gray-900 w-full h-[15rem] relative mt-[8rem] md:mt-0">
        <Img src={baseUrl("/assets/img/slider/03.jpg")} className="opacity-40 h-full object-cover w-full" />
        <div className="absolute w-full h-full z-40 top-0">
          <div className="flex justify-around w-content h-full items-center md:justify-between text-white bg-transparent">
            <div className="text-lg md:text-4xl font-semibold capitalize">{t(title)}</div>
            <div className="text-md md:text-2xl font-semibold capitalize">{t("home")} &gt; {t(title)}</div>
          </div>
        </div>
      </div>
    </LandingSection>
  )
}

export default HeroContent