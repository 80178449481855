import React from 'react'

const Checkbox = ({
  className,
  checked,
  defaultChecked,
  onChange,
  children,
  value,
  name
}) => {
  return (
    <label className="space-x-1">
      <input
        className={className}
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        value={value}
        name={name}
      />
      <label>{children}</label>
    </label>
  )
}

export default Checkbox