import { TOAST_STATE } from "../../globalActionType";


export const toastRequest = () => {
  function request() { return { type: TOAST_STATE.TOAST_OPEN } }
  return dispatch => {
      dispatch(request());
  };
}
export const toastReset = () => {
  function request() { return { type: TOAST_STATE.TOAST_CLOSE } }
  return dispatch => {
      dispatch(request());
  };
}