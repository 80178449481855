import React from 'react'

const Textarea = ({
  className,
  children,
  placeholder,
  defaultValue,
  name,
  onChange,
  disabled,
  rows,
  value
}) => {
  return (
    <textarea className={`${disabled ? "bg-slate-200 ":"bg-gray-100"} border-2 border-gray-200 rounded focus:bg-white focus:border-purple-500 focus:outline-none ${className}`}
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      defaultValue={defaultValue}
      rows={rows}
    > 
    {children}                
    </textarea>
  )
}

export default Textarea