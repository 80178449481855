import React from 'react'

const Loading = ({className}) => {
  return (
    <div className={`relative w-full ${className}`}>
      <div className="w-full h-1 shim-blue rounded-md shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center "></div>
    </div>
  )
}

export default Loading
