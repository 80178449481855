import { HERO } from "../../../services";
import { HERO_DATA_STATE } from "../../globalActionType";

export const getListHero = (params) => {
  function request() { return { type: HERO_DATA_STATE.HERO_DATA } }
  function success(data) { return { type: HERO_DATA_STATE.HERO_DATA_SUCCESS, data } }
  function failure(data) { return { type: HERO_DATA_STATE.HERO_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await HERO.getHero(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getHero = (params) => {
  function request() { return { type: HERO_DATA_STATE.HERO_DATA } }
  function success(data) { return { type: HERO_DATA_STATE.HERO_DATA_SUCCESS, data } }
  function failure(data) { return { type: HERO_DATA_STATE.HERO_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await HERO.getList(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const getDetailHero = (params) => {
  function request() { return { type: HERO_DATA_STATE.HERO_DETAIL_DATA } }
  function success(data) { return { type: HERO_DATA_STATE.HERO_DETAIL_DATA_SUCCESS, data } }
  function failure(data) { return { type: HERO_DATA_STATE.HERO_DETAIL_DATA_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await HERO.getDetail(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
};

export const resetHero = () => (dispatch) => {
  dispatch({
    type: HERO_DATA_STATE.HERO_DATA_RESET
  })
}
export const resetHeroDetail = () => (dispatch) => {
  dispatch({
    type: HERO_DATA_STATE.HERO_DETAIL_DATA_RESET
  })
}