import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { register, sendMailToken } from '../../redux/admin/register/action';
import Button from '../../components/atoms/Button';
import Loading from '../../components/atoms/Loading';
import { useToast } from '../../components/organisms/ToastProvider';
import { toastRequest, toastReset } from '../../redux/global/toast/action';
import Label from '../../components/atoms/Label';
import Input from '../../components/atoms/Input';

const Register = () => {

  const dispatch = useDispatch()

  const [isRegister, setIsRegister] = useState(false)
  const [inputs, setInputs] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const { addToast } = useToast()

  const {
    isLoading,
    isError,
    isSuccess,
    data,
    alert,
    message,
    isLoadingSendMail,
    isSuccessSendMail
  } = useSelector(state => state.register);

  const dataToast = useSelector(state => state.toast);

  const params = {
    url: window.location.origin + "/validate/confirm-registration/",
    email: data ?? inputs.email
  }

  let handleChange = (event) => {
    const { name, value } = event.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  let handleSubmit = () => {
    if (inputs) {
      const req = {
        username: inputs?.username,
        email: inputs?.email,
        password: inputs?.password,
        confirmPassword: inputs?.confirmPassword
      };
      dispatch(register(req));
      dispatch(toastRequest());
    }
  }

  const handleResend = () => {
    dispatch(sendMailToken(params))
  }

  useEffect(() => {
    if (isError && dataToast.toast) {
      addToast({
        type: alert,
        message: message,
        // time:5000
      })
      dispatch(toastReset())//reset
    }
    if (isSuccess) {
      dispatch(sendMailToken(params))
    }
    if (isSuccessSendMail) {
      setIsRegister(true)
    }

  }, [isError, isSuccess, isSuccessSendMail]); //eslint-disable-line

  return (
    <>
      {isRegister ?
        <div className='min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-300'>
          <div className='max-w-md w-full  bg-white p-5 rounded-xl'>
            {isLoadingSendMail ? <Loading /> : null}
            <div className='text-xl font-semibold text-gray-900 text-center pt-3'>Welcome, please verify your email address before accessing our site</div>
            <div className='text-md mt-2 text-gray-900 text-center'>didnt receive link?
              <span
                className="hover:text-blue-700 text-blue-400 cursor-pointer"
                onClick={() => handleResend()}
              > resend</span>
            </div>
          </div>
        </div> :
        <div className='min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-300'>
          <div className='max-w-md w-full  bg-white p-2 rounded-xl'>
            {isLoading || isLoadingSendMail ? <Loading /> : null}
            <div className='p-8'>
              {/* <p className='text-center'>[LOGO]</p> */}
              <div className='text-center mb-10'>
                <h2 className='text-3xl font-extrabold text-gray-900'>Sign up new account</h2>
                <p>already have account? <NavLink to={"/auth/login"} className={'navlink'}>sign in</NavLink></p>
              </div>
              <form className='w-full max-w-sm mx-auto space-y-6'>
                <div className="form-group">
                  <Label className="md:w-1/3">Username</Label>
                  <Input
                    className="md:w-2/3 form-box"
                    type="text"
                    name="username"
                    onChange={(event) => handleChange(event)}
                    value={inputs.username}
                  />
                </div>
                <div className="form-group">
                  <Label className="md:w-1/3">Email</Label>
                  <Input
                    className="md:w-2/3 form-box"
                    type="email"
                    name="email"
                    onChange={(event) => handleChange(event)}
                    value={inputs.email}
                  />
                </div>
                <div className="form-group">
                  <Label className="md:w-1/3">Password</Label>
                  <Input
                    className="md:w-2/3 form-box"
                    type="password"
                    name="password"
                    onChange={(event) => handleChange(event)}
                    value={inputs.password}
                  />
                </div>
                <div className="form-group">
                  <Label className="md:w-1/3">Confirm Password</Label>
                  <Input
                    className="md:w-2/3 form-box"
                    type="password"
                    name="confirmPassword"
                    onChange={(event) => handleChange(event)}
                    value={inputs.confirmPassword}
                  />
                </div>
                <div className='md:flex md:items-center'>
                  <div className='md:w-1/3'></div>
                  <div className='md:w-2/3'>
                    <Button type={'button'} onClick={handleSubmit} buttonStyle={'btn-primary mr-5 m-1'} disabled={isLoading || isLoadingSendMail}>Confirm</Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Register