import { AUTH, PROFILE } from '../../../services';
import { REGISTER_STATE, PROFILE_SUBMIT_STATE } from '../../globalActionType';

export const register = (params) => {
  function request() { return { type: REGISTER_STATE.REGISTER } }
  function success(data, token) { return { type: REGISTER_STATE.REGISTER_SUCCESS, data, token } }
  function failure(data) { return { type: REGISTER_STATE.REGISTER_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await AUTH.register(params);
      const data = response?.data ?? {};
      // const token = response?.token ?? {};
      if (data.meta.success) {
        // dispatch(success(data,token));
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  }
}

export const sendMailToken = (params) => {
  function request() { return { type: REGISTER_STATE.SEND_MAIL } }
  function success(data) { return { type: REGISTER_STATE.SEND_MAIL_SUCCESS, data } }
  function failure(data) { return { type: REGISTER_STATE.SEND_MAIL_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await AUTH.sendMail(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  };
}

export const confirmRegistrationToken = (params) => {
  function request() { return { type: REGISTER_STATE.CONFIRM } }
  function success(data) { return { type: REGISTER_STATE.CONFIRM_SUCCESS, data } }
  function failure(data) { return { type: REGISTER_STATE.CONFIRM_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await AUTH.confirmRegistration(params);
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data));
      } else {
        dispatch(failure(data));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error));
    }
  };
}

export const submitProfile = (params) => {
  function request() { return { type: PROFILE_SUBMIT_STATE.PROFILE_SUBMIT } }
  function success(data) { return { type: PROFILE_SUBMIT_STATE.PROFILE_SUBMIT_SUCCESS, data } }
  function failure(data) { return { type: PROFILE_SUBMIT_STATE.PROFILE_SUBMIT_FAILURE, data } }

  return async (dispatch, getState) => {
    dispatch(request());
    try {
      const response = await PROFILE.register(params)
      const data = response?.data ?? {};
      if (data.meta.success) {
        dispatch(success(data))
      } else {
        dispatch(failure(data))
      }
    } catch (error) {
      dispatch(failure(error?.response?.data ?? error))
    }
  }
};

export const resetSubmit = () => (dispatch) => {
  dispatch({
    type: PROFILE_SUBMIT_STATE.PROFILE_SUBMIT_RESET
  })
}

export const reset = () => {
  function request() { return { type: REGISTER_STATE.RESET } }
  return dispatch => {
    dispatch(request());
  };
}